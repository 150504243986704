/**
 * @author      PoulyCroc <poulycroc.studio@gmail.com>
 * @src         https://github.com/Poulycroc/vue-props-mapper/blob/master/main.js
 */

/**
 * @param {Array} keys
 * @param {Object} options
 * @return {Object}
 */
export default (keys, options = {}) => {
	let resProps = {};
	keys.forEach((k) => {
		resProps = {
			...resProps,
			[k]: options
		};
	});
	return resProps;
};
