const state = () => ({
	currentJobRequest: {}
});

const getters = {
	currentJobRequest: (state) => state.currentJobRequest
};

const mutations = {
	SET_CURRENT_JOB_REQUEST(state, value) {
		state.currentJobRequest = value;
	}
};

const actions = {
	setCurrentJobRequest({ commit }, value) {
		commit('SET_CURRENT_JOB_REQUEST', value);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
