<template>
	<div>
		<div class="data-card-details" v-if="useNewVersion">
			<div class="cols-12" v-if="cloneItemsMap.length > 0 || hasInitialData">
				<b-input-group>
					<b-form-input type="text" v-model="filter" :placeholder="FormMSG(2, 'Type to search ...')" @keyup="search($event)" />
					<b-input-group-append>
						<b-input-group-text>
							<search color="#06263E" :size="16" class="icon" v-if="filter.length === 0" />
							<x color="#06263E" :size="16" class="icon" @click="reInitializeItems" v-else />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</div>
			<div class="cols-12 mt-3" v-if="cloneItemsMap.length > 0 || hasInitialData">
				<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom" v-if="!hideFilterOptions && $has(toggleFilterOptions)">
					<legend class="card-inside">{{ FormMSG(3, 'Advanced search') }}</legend>
					<b-form-group class="advanced-search" v-slot="{ ariaDescribedby }">
						<b-form-radio-group
							size="lg"
							:aria-describedby="ariaDescribedby"
							:options="toggleFilterOptions.choices"
							name="advanced-search"
							v-model="currToggleFilterAction"
						/>
					</b-form-group>
				</fieldset>
			</div>
			<div class="mt-3" v-if="cloneItemsMap.length > 0 || hasInitialData">
				<div v-for="(item, i) in cloneItemsMap" :key="i">
					<card-content
						:fields="fields"
						:omitted-fields="omittedFields"
						:shown-fields="headerFields"
						:horizontal-mode="horizontalMode"
						:key-for-quantity="keyForQuantity"
						:show-quantity-on-title="showQuantityOnTitle"
						:item="item"
						:hide-status="hideStatus"
						:has-badge="hasBadge"
						:badge-value="badgeValue"
						:is-badge-use-props="isBadgeUseProps"
						:badge-class="badgeClass"
						:key-for-title="keyForTitle"
						:toggle-mode="toggleMode"
					>
						<template v-for="field in fields">
							<template :slot="field.key">
								<!--
									Now, can add a validator for a different 
									input when slot is required
									@TODO: but need to fix again double event for some type of
									element as Treeselect (input event)
								-->
								<slot
									:name="field.key"
									:item="item"
									:index="i"
									:error="$v.cloneItemsMap.$each[i][field.key].$error"
									:isLineValid="!$v.cloneItemsMap.$each[i].$invalid"
									v-if="field.isSlot && field.required"
								/>
								<slot :name="field.key" :item="item" :index="i" v-if="field.isSlot && !field.required" />
							</template>
						</template>
						<template slot="children-actions">
							<slot name="actions" :item="item" :index="i" />
						</template>
					</card-content>
				</div>
			</div>
			<span class="empty-data" v-else>{{ FormMSG(4, 'No data found') }}</span>
		</div>
		<div :class="customClass" class="container-mobile form" v-else>
			<div v-if="!hideFilterOptions">
				<b-row v-if="$has(toggleFilterOptions)">
					<b-col class="toggle-filter-container">
						<b-button
							v-for="(toggle, i) in toggleFilterOptions.choices"
							:key="i"
							size="sm"
							:variant="`${!isToggleFilterActive(toggle.value) ? 'outline-' : ''}primary`"
							@click="handleToggleFilterAction(toggle.value)"
						>
							{{ toggle.text }}
						</b-button>
					</b-col>
				</b-row>
			</div>
			<b-row v-if="showFilter">
				<b-col sm="12" class="my-2">
					<b-input-group>
						<b-form-input
							type="text"
							v-model="filter"
							:placeholder="FormMSG(2, 'Type to search ...')"
							@keyup="search($event)"
							@keyup.delete="deleteSearch($event)"
							autocomplete="off"
						/>
						<b-input-group-append>
							<b-input-group-text>
								<search color="#06263E" :size="16" class="icon" v-if="filter.length === 0" />
								<x color="#06263E" class="icon cursor-pointer" :size="16" @click="reInitializeItems" v-else />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
			<div v-if="cloneItemsMap.length > 0">
				<div
					v-for="(item, i) in cloneItemsMap"
					ref="cardListLocal"
					:key="i"
					class="card mobile-card-list-item w-100 p-0"
					@click="handleCardClicked(item)"
					style="background-color: #f5f7f9"
				>
					<div v-if="item" class="card-body" :class="{ 'p-0': $screen.width <= 992 }">
						<div class="head" v-if="!hideStatus">
							<div class="left">
								<span v-if="item.validatedClass && item.validatedStatus" :class="[`badge badge-${item.validatedClass}`]">
									{{ item.validatedStatus }}
								</span>
								<slot name="badges" :item="item" :index="i" />
							</div>
							<div v-show="$hasSlot('info')" class="info">
								<slot name="info" :item="item" :index="i" />
							</div>
						</div>
						<div class="content py-0">
							<b-table v-if="styleMode === 'table'" borderless size="sm" :fields="fields" :items="[item]" class="py-0" />
							<ul v-else :class="`list style-mode--${styleMode}`">
								<li v-for="(cell, j) in rendFieldsMap(item)" :key="j">
									<div v-html="cell" class="d-flex align-items-center" v-if="!isObject(cell)" />
									<div class="d-flex align-items-center" style="line-height: 2.25rem" v-else>
										<label class="customLabel">{{ cell.label }}</label>
										<div class="customText"><slot :name="cell.key" :item="item" /></div>
									</div>
								</li>
							</ul>
							<slot name="body" :item="item" />
						</div>
						<div class="content-collapse" :class="{ 'is-openned': isCollapseOpen }">
							<ul v-if="currCollapseDetails.item === item" class="list style-mode--list">
								<li v-for="(cell, z) in currCollapseDetails.list" :key="z" v-html="cell" />
							</ul>
						</div>
						<div v-show="$hasSlot('actions')" class="actions pr-2 py-2">
							<slot name="actions" :item="item" :index="i" :showCollapseDetails="showCollapseDetails" :isCollapseOpen="isCollapseOpen" />
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<span>
					{{ FormMSG(987795, 'No items for this list') }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import * as _ from 'lodash';
import mapProps from '@/shared/vuePropsMapper';
import { isNil, convertStringToPropGetter, noTilde, generateSecureId, isObj } from '~utils';
import languageMessages from '@/mixins/languageMessages';
import { Search, X } from 'lucide-vue';
import CardContent from '@/components/CardListBuilder/CardContent';
import { validationMixin } from 'vuelidate';

const authorizedStylesMode = ['list', 'line', 'table'];

export default {
	name: 'CardListBuilderComponent',
	mixins: [languageMessages, validationMixin],
	components: {
		Search,
		X,
		CardContent
	},
	props: {
		...mapProps(['omittedFields', 'collapsedFields', 'headerFields'], {
			type: Array,
			required: false,
			default: () => []
		}),
		...mapProps(['hideStatus', 'hideFilterOptions'], {
			type: Boolean,
			required: false,
			default: false
		}),
		showQuantityOnTitle: {
			type: Boolean,
			required: false,
			default: false
		},
		keyForQuantity: {
			type: String,
			required: false,
			default: ''
		},
		horizontalMode: {
			type: Boolean,
			required: false,
			default: false
		},
		items: {
			type: Array,
			required: true,
			default: () => []
		},
		fields: {
			type: Array,
			required: false,
			default: () => []
		},
		originalFields: {
			type: Array,
			required: false,
			default: () => []
		},
		toggleFilterOptions: {
			type: Object,
			required: false,
			default: null
		},
		customClass: {
			type: String,
			required: false,
			default: ''
		},
		styleMode: {
			type: String,
			required: false,
			default: 'list',
			validator: (v) => authorizedStylesMode.includes(v)
		},
		showFilter: {
			type: Boolean,
			required: false,
			default: true
		},
		useNewVersion: {
			type: Boolean,
			required: false,
			default: false
		},
		hasBadge: {
			type: Boolean,
			required: false,
			default: false
		},
		/**
		 * This field is mandatory and take true value
		 * when the value of badgeClass or badgeValue
		 * is a key of object
		 */
		isBadgeUseProps: {
			type: Boolean,
			required: false,
			default: false
		},
		badgeClass: {
			type: String,
			required: false,
			default: 'info'
		},
		badgeValue: {
			type: String,
			required: false,
			default: ''
		},
		hasInitialData: {
			type: Boolean,
			required: false,
			default: true
		},
		keyForTitle: {
			type: String,
			required: false,
			default: ''
		},
		toggleMode: {
			type: Boolean,
			required: false,
			default: true
		},
		validators: {
			type: Object,
			required: false,
			default: null
		}
	},
	data() {
		return {
			selected: '',
			filter: '',
			cloneItems: [],
			currToggleFilterAction: 'all',
			labelStyle:
				'color: rgba(6, 38, 62, 0.85) !important; display: table-cell; padding: .585rem 0 0 .7rem; clear: both; width: 35%; font-weight: 600; font-size: .875rem !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;',
			textStyle:
				'padding: 0 .7rem 0 0; margin-top: 1px;font-size: .875rem; color: rgba(6, 38, 62, 0.75) !important; font-weight: 500; width: 100%; text-align: right;',
			isCollapseOpen: false,
			currCollapseDetails: { item: null, list: [] }
		};
	},
	computed: {
		/**
		 * @return {Array}
		 */
		cloneItemsMap() {
			const tfo = this.toggleFilterOptions;
			if (isNil(tfo) || isNil(tfo.key)) return this.cloneItems;

			return this.cloneItems.filter((item) => {
				return this.currToggleFilterAction === 'all' || item[tfo.key] === this.currToggleFilterAction;
			});
		}
	},
	watch: {
		items: {
			/**
			 * @params {Array} items
			 */
			handler(items) {
				if (!isNil(items)) {
					this.fillItems(items);
				}
			},
			// deep: true,
			immediate: true
		}
	},
	created() {
		this.fillItems(this.items);
	},
	methods: {
		/**
		 * @param {Array} items
		 */
		fillItems(items) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					this.cloneItems = _.cloneDeep(items);
					resolve(!_.isNil(this.validators) ? this.$v.$touch() : true);
					reject(false);
				}, 250);
			});
		},

		/**
		 * @param {Object}
		 */
		showCollapseDetails(item) {
			this.currCollapseDetails = {
				item,
				list: this.rendFieldsMap(item, this.collapsedFields)
			};
			this.toggleCollapse();
		},

		toggleCollapse() {
			this.isCollapseOpen = !this.isCollapseOpen;
		},

		/**
		 * @param {String} value
		 */
		handleToggleFilterAction(value) {
			this.currToggleFilterAction = value;
		},
		/**
		 * @param {String} value
		 * @return  {Boolean}
		 */
		isToggleFilterActive(value) {
			return this.currToggleFilterAction === value;
		},

		/**
		 * @param {Object} item
		 * @return {String}
		 */
		rendFieldsMap(item, fields = null) {
			const __fields = isNil(fields) ? this.fields : fields;

			let res = [];
			__fields.forEach((f) => {
				if (isNil(f)) return;
				if (this.omittedFields.includes(f.key)) return;

				res.push(this.rendField(item, f));
			});

			return res;
		},

		/**
		 * @param {Object} item
		 * @param {Array} field
		 * @return {Html}
		 */
		rendField(item, field) {
			if (isNil(item) || isNil(field)) return '';

			const key = convertStringToPropGetter(item, field.key);

			// create 'formatter' prototypeKey for items options access
			// ex -> formatter: (value, key, item) => {}
			const value = isNil(field.formatter) ? key : field.formatter(key, field.key, item);

			const labelStyle = this.labelStyle;
			const textStyle = this.textStyle;

			/**
			 * 26/01/2023
			 * New (!IMPORTANT):
			 * Can create slot independently with field name now, like <b-table> do
			 * e.g: <template slot="totPerDiem" slot-scope="data">...</template> (in template)
			 * e.g: { key: 'totPerDiem', label: this.FormMSG(368, 'Per Diem'), isSlot: true } (in computed)
			 */

			return field.isSlot
				? { key: field.key, label: field.label }
				: `
					<label style="${labelStyle}">${field.label}</label>${this.styleMode === 'line' ? ': ' : ''}
					<div style="${textStyle}">${value}</div>
				`;
		},

		reInitializeItems() {
			this.filter = '';
			this.cloneItems = _.cloneDeep(this.items);
		},

		/**
		 * @param {Object}
		 */
		handleCardClicked(item) {
			this.$emit('row-clicked', item);
		},

		/**
		 * @param {Object} e
		 */
		search(e) {
			this.cloneItems = _.cloneDeep(this.items);
			const pattern = noTilde(e.target.value);
			let fields = this.fields;

			if (this.headerFields.length > 0) {
				this.headerFields.forEach((field) => {
					if (fields.filter((item) => item.key === field.key).length === 0) {
						fields.push(field);
					}
				});
			}

			const results = this.cloneItems.filter((item) => {
				let bool = false;
				fields.forEach((field) => {
					if (this.omittedFields.includes(field.key)) return;

					const key = convertStringToPropGetter(item, field.key);
					if (isNil(key)) return;

					let value = null;
					if (isNil(field.formatter)) {
						value = key;
					} else {
						if (field.formatter.length === 3) {
							value = field.formatter(value, '', item);
						} else {
							value = isNil(field.formatter) ? key : field.formatter(key);
						}
					}

					if (noTilde(value.toString()).match(new RegExp(pattern, 'gi'))) {
						bool = true;
						return;
					}
				});

				return bool;
			});

			if (this.headerFields.length > 0) {
				this.headerFields.forEach((field) => {
					this.fields.splice(
						this.fields.findIndex((f) => f.key === field.key),
						1
					);
				});
			}

			this.cloneItems = _.cloneDeep(results);
		},

		/**
		 * @param {Object} e
		 */
		deleteSearch(e) {
			const pattern = e.target.value;
			if (pattern !== '') {
				this.search(e);
			} else {
				this.cloneItems = _.cloneDeep(this.items);
			}
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		isObject(value) {
			return isObj(value);
		}
	},
	validations() {
		if (!_.isNil(this.validators) && !_.isArray(this.validators) && !_.isString(this.validators) && !_.isInteger(this.validators)) {
			// console.log({
			// 	cloneItemsMap: {
			// 		$each: {
			// 			...this.validators
			// 		}
			// 	}
			// });
			return {
				cloneItemsMap: {
					$each: {
						...this.validators
					}
				}
			};
		}

		return null;
	}
};
</script>
<style lang="scss" scoped>
.card {
	border: 0.005rem solid rgba(6, 38, 62, 0.065);
}
.head {
	width: 100%;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.badge:not(:last-child) {
		margin-right: 10px;
	}
}
.content {
	width: 100%;
	display: block;
}

.content-collapse {
	width: 100%;
	overflow: hidden;
	height: 0;

	&.is-openned {
		height: auto;
	}
}

.actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	button {
		margin-left: 20px;
	}
}

.list {
	padding: 0;
	margin: 0;
	list-style: none;
	display: table;
	min-width: 100%;
	&::after {
		clear: both;
		content: '';
		display: table;
	}
}
.list {
	& > li {
		height: 2.5rem;
		box-sizing: border-box;
		// margin-bottom: 0.07rem;
		// position: relative;
		// display: flex;
		// align-items: center;
		// div {
		//   border: 1px solid red;
		// }
		&:last-child {
			margin-bottom: 0;
		}
	}
	// & > li > div {
	//   background-color: white;
	//   color: #706061;
	//   display: block;
	//   width: 100%;
	//   padding: 0.65rem;
	// }
	// & > label {
	//   color: #606992;
	//   display: table-cell;
	//   padding: 0 0.4rem 0 0.4rem;
	//   text-transform: capitalize;
	//   clear: both;
	//   width: auto;
	//   margin-bottom: 0;
	// }
	span {
		padding: 0.5rem;
	}

	&.style-mode-- {
		&list li {
			justify-content: space-between;
			label {
				margin-bottom: 0 !important;
			}
		}
		&line {
			display: inline-flex;
			flex-direction: row;
			flex-wrap: wrap;
			& > li {
				display: inline;
			}
		}
	}
	& > li:nth-child(odd) {
		background-color: #f5f7f9;
	}
	& > li:nth-child(even) {
		background-color: #fff;
	}
}

.toggle-filter-container {
	& > *:not(:last-child) {
		margin-right: 10px;
	}
}

.customLabel {
	color: rgba(6, 38, 62, 0.85) !important;
	display: table-cell;
	padding: 0 0 0 0.7rem;
	clear: both;
	width: 35%;
	font-weight: 600;
	font-size: 0.875rem !important;
}

.customText {
	padding: 0 0.7rem 0 0;
	margin-top: 1px;
	font-size: 0.875rem;
	color: rgba(6, 38, 62, 0.75) !important;
	font-weight: 500;
	width: 100%;
	text-align: right;
}
</style>
