import { makeID, formatFileName } from '@/shared/utils';
import languageMessages from './languageMessages';
import { store } from '@/store';
import { Capacitor } from '@capacitor/core';
import { rendCurrency, getFileExtension } from '~helpers';
import * as icons from 'lucide-vue';
import { ICONS as useIconsLucide } from '@/utils/icons';
import moment from 'moment';
import { EXPORTNAME as useExportName } from '@/utils/script-export-name';
import _ from 'lodash';
import { TRANSPORTATION_MEAN_MENU, SPE_VEHICLE_GENERATOR_TRUCK_TYPE, SPE_VEHICLE_PRODUCTION_TRUCK_TYPE, SPE_VEHICLE_TRAILER_TYPE } from '@/shared/constants';

export default {
	name: 'GlobalMixin',
	ICONS: {},
	mixins: [languageMessages],
	EXPORTNAME: {},
	data() {
		return {
			country12HList: ['en-US', 'en-CA', 'es-MX', 'en-AU', 'ar-EG', 'en-IN', 'en-IE', 'en-NZ', 'en-PK', 'ar-SA']
		};
	},
	created() {
		this.moment = moment;
		this.initializeIconsLucide();
		this.initializeExportName();
	},
	computed: {
		lang() {
			const langStore = store.appLanguage();
			switch (langStore) {
				case 0:
					return 'en';
				case 1:
					return 'fr';
				case 2:
					return 'de';
				case 3:
					return 'it';
				case 4:
					return 'nl';
				case 5:
					return 'en';
				case 6:
					return 'pt';
				case 7:
					return 'sk';
			}
		},
		randomizeColor() {
			const letters = '0123456789ABCDEF'.split('');
			let color = '#';

			for (let i = 0; i < 6; i++) {
				color += letters[Math.round(Math.random() * 13)];
			}

			return color;
		},
		formatDate() {
			const appLanguage = store.appLanguage();
			switch (appLanguage) {
				case 0:
					return 'dddd, MMMM Do YYYY';
				case 1:
					return 'dddd Do MMMM YYYY';
				case 2:
					return 'dddd, MMMM Do YYYY';
				case 3:
					return 'dddd, MMMM Do YYYY';
				case 4:
					return 'dddd, MMMM Do YYYY';
				case 5:
					return 'dddd, MMMM Do YYYY';
				case 6:
					return 'dddd, MMMM Do YYYY';
				case 7:
					return 'dddd, MMMM Do YYYY';
			}
		}
	},
	methods: {
		/**
		 * Gerating unic string key for element
		 * @param {String} name
		 * @return {String}
		 */
		getLucideIcon(name) {
			return icons[name];
		},
		initializeIconsLucide() {
			return (this.ICONS = useIconsLucide);
		},
		initializeExportName() {
			return (this.EXPORTNAME = useExportName);
		},
		makeItemRef(name = '') {
			return ['_', name, makeID(10)].join('_');
		},
		base16ToHexa(color) {
			return new Number(color).toString(16).padStart(6, '0');
		},
		uniqueArrayOfObject(arr, field) {
			return [...new Map(arr.map((item) => [item[field], item])).values()];
		},
		getInitial(word) {
			try {
				const wordSplited = /[\s]+/g.test(word) ? word.split(' ') : [word];
				let init = '';
				wordSplited.forEach((w, index) => {
					if (index < 2) {
						init += w.substr(0, 1);
					}
				});

				return init.toUpperCase();
			} catch (e) {}
		},
		pathPicture(picture) {
			return `${process.env.VUE_APP_GQL}/images/${picture}`;
		},
		confirmModal(callback, msg, okVariant = 'success', resetCallback = null) {
			this.$bvModal
				.msgBoxConfirm(msg, {
					title: this.FormMSG(365, 'Please confirm'),
					size: 'md',
					buttonSize: 'sm',
					okVariant,
					cancelVariant: 'light',
					okTitle: this.FormMSG(366, 'YES'),
					cancelTitle: this.FormMSG(367, 'NO'),
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) {
						if (_.isFunction(resetCallback)) {
							resetCallback();
						} else {
							return;
						}
					}
					await callback();
				})
				.catch((err) => {
					this.createToastForMobile(this.FormMSG(368, 'Error'), err.message, '', 'danger');
				});
		},
		currencyDisplay(value) {
			const { currencySymbol } = store.state.myProfile;

			return `${currencySymbol} ${this.number_format(value, 0, '.', ',')}`;
		},
		/**
		 * @param {number} number
		 * @param {number} decimals
		 * @param {string} dec_point
		 * @param {string} thousands_sep
		 */
		number_format(number, decimals, dec_point, thousands_sep) {
			// Strip all characters but numerical ones.
			number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
			var n = !isFinite(+number) ? 0 : +number,
				prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
				sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
				dec = typeof dec_point === 'undefined' ? '.' : dec_point,
				s = '',
				toFixedFix = function (n, prec) {
					var k = Math.pow(10, prec);
					return '' + Math.round(n * k) / k;
				};
			// Fix for IE parseFloat(0.55).toFixed(0) = 0;
			s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
			if (s[0].length > 3) {
				s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
			}
			if ((s[1] || '').length < prec) {
				s[1] = s[1] || '';
				s[1] += new Array(prec - s[1].length + 1).join('0');
			}
			return s.join(dec);
		},
		async displayMap(address) {
			let filled = false;
			const name = 'Location';
			let urlStr = '';
			if (
				((typeof address.lat === 'string' && address.lat.length != 0) || address.lat != 0) &&
				((typeof address.lng === 'string' && address.lng.length != 0) || address.lng != 0)
			) {
				urlStr = 'http://www.google.com/maps/place/' + address.lat + ',' + address.lng;
				// console.log('urlStr:', urlStr)
				window.open(urlStr, '_blank'); // in a new window
			} else if (name.length > 0 || address.street.length > 0) {
				// build address string
				urlStr = 'https://www.google.com/maps/search/?api=1&query=';
				if (name.length > 0) {
					urlStr += name;
					filled = true;
				}
				if (address.street.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += address.street;
				}
				if (address.number.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += address.number;
				}
				if (address.zip.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += address.zip;
				}
				if (address.city.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += address.city;
				}
				if (address.country.length > 0) {
					if (filled) {
						urlStr += '+';
					}
					urlStr += address.country;
				}
				//urlStr = escape(urlStr);
				// console.log('urlStr:', urlStr)
				window.open(urlStr, '_blank'); // in a new window
			}
		},
		padZero(str, len) {
			len = len || 2;
			var zeros = new Array(len).join('0');
			return (zeros + str).slice(-len);
		},
		invertColor(hex) {
			if (hex.indexOf('#') === 0) {
				hex = hex.slice(1);
			}
			// convert 3-digit hex to 6-digits.
			if (hex.length === 3) {
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			}
			if (hex.length !== 6) {
				throw new Error('Invalid HEX color.');
			}
			// invert color components
			var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
				g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
				b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
			// pad each with zeros and return
			return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
		},
		createToastForMobile(title, message1 = '', message2 = '', variant = 'success', solid = false, position = 'center') {
			// if (this.$screen.width <= 567) {
			const createElement = this.$createElement;
			position = ['center', 'left', 'right'].includes(position) ? position : 'center';
			let tVariant = 'b-icon-check2-circle';
			if (variant === 'danger') {
				tVariant = 'b-icon-x-circle';
			} else if (variant === 'warning') {
				tVariant = 'b-icon-exclamation-circle';
			} else if (variant === 'info') {
				tVariant = 'b-icon-info-circle';
			}
			const vNodesMsg = createElement('b-row', {}, [
				createElement(
					'b-col',
					{
						class: 'col-2 pr-2 d-flex justify-content-center align-items-center'
					},
					[
						createElement(tVariant, {
							style: 'width: 40px; height: 40px'
						})
					]
				),
				createElement('b-col', { class: 'col-10' }, [
					createElement('p', { class: 'fw-500 font-weight-bold', style: 'font-size: 16px; margin-top: 8px;' }, title),
					createElement('p', { style: 'padding-right: 1px; padding-bottom: 4px; margin-top: -8px; margin-bottom: 4px; font-size: 0.95rem;' }, [
						createElement('span', { class: 'fw-400' }, message1 + ' '),
						createElement('span', { class: 'fw-400 font-weight-bold' }, message2)
					])
				])
			]);
			this.$bvToast.toast([vNodesMsg], {
				toaster: `b-toaster-top-${position}`,
				solid,
				appendToast: false,
				autoHideDelay: `${variant === 'success' ? 3000 : 4000}`,
				variant: variant,
				noCloseButton: true,
				toastClass: `toast-mobile${this.isPwa() ? ' is-pwa' : ''}`
			});
			// } else {
			// 	this.$toast({
			// 		message: message1,
			// 		title,
			// 		variant: variant,
			// 		autoHideDelay: `${variant === 'success' ? 3000 : 4000}`
			// 	});
			// }
		},
		isPwa() {
			// const types = ['fullscreen', 'standalone', 'minimal-ui']
			// return types.some(dm => window.matchMedia(`(display-mode: ${dm})`).matches)
			const res = Capacitor.getPlatform() !== 'web';
			// console.log({ res })
			return res;
		},
		getCurrentContract() {
			const contracts = store.state.myContracts;
			if (contracts.length === 0) {
				return null;
			}

			const now = new Date();
			const hackDateError = '1970-01-01T00:00:01Z';
			let currentContract = null;

			for (let i = 0; i < contracts.length; i++) {
				const start = new Date(contracts[i].startDate).getTime() === 0 ? new Date(hackDateError) : new Date(contracts[i].startDate);
				const end = new Date(contracts[i].endDate).getTime() === 0 ? new Date(hackDateError) : new Date(contracts[i].endDate);

				if (now.getTime() >= start.getTime() && now.getTime() <= end.getTime()) {
					currentContract = contracts[i];

					break;
				}
			}

			return currentContract;
		},
		getFieldValueByShowGridDetails(showGridDetails, value, salaryBase) {
			let separator = this.$screen.width < 992 ? ' | ' : '<br>';

			if (!showGridDetails && value === 0) return 'F';
			if (!showGridDetails && value === 1) return 'C';
			if (!showGridDetails && value === 2) return 'T';
			if (!showGridDetails && value === 3) return 'H';
			if (showGridDetails && value === 0) return 'F' + separator + rendCurrency(salaryBase);
			if (showGridDetails && value === 1) return 'C' + separator + rendCurrency(salaryBase);
			if (showGridDetails && value === 2) return 'T' + separator + rendCurrency(salaryBase);
			if (showGridDetails && value === 3) return 'H' + separator + rendCurrency(salaryBase);
		},
		manageStatusInAdvance(actualStatus) {
			const statusesToTest = [0, 1, 2, 7];
			if (statusesToTest.includes(parseInt(actualStatus, 10))) {
				return 3;
			}

			return actualStatus;
		},
		dateToStringFormat(value, format = 'DD/MM/YYYY') {
			return new moment(value).format(format);
		},
		getExtensionScript(defaultFormat, customFormat, sourceExtension) {
			if (defaultFormat === 'xls') {
				return sourceExtension;
			} else if (defaultFormat === 'custom') {
				return customFormat;
			}

			return defaultFormat;
		},
		getFileNameScript(defaultFileName, scriptId, fileName) {
			let newFileName = formatFileName(defaultFileName) + '-' + moment(Date.now()).format('YYYYMMDDHHmmss');

			if (+scriptId > 0 && !_.isNil(fileName) && !_.isUndefined(fileName) && !_.isEmpty(fileName)) {
				// newFileName = formatFileName(fileName) + '-' + moment(Date.now()).format('YYYYMMDDHHmmss');
				newFileName = formatFileName(fileName);
			}

			return newFileName;
		},
		getRightXidDocument(xid, signedXid) {
			return !_.isEmpty(signedXid) && !_.isNil(signedXid) ? signedXid : xid;
		},
		replaceToRightFileNameExt(fileName, signedXid) {
			return !_.isEmpty(signedXid) && !_.isNil(signedXid) ? this.getFileName(fileName) + '.' + getFileExtension(signedXid) : fileName;
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		isDocAlreadySigned(signedXid) {
			return !_.isEmpty(signedXid) && !_.isNil(signedXid);
		},
		checkLengthLabelForSelect(value) {
			let maxNumberLetter = 0;

			if (this.$screen.width <= 576) {
				maxNumberLetter = 15;
			} else if (this.$screen.width > 576 && this.$screen.width <= 912) {
				maxNumberLetter = 15;
			} else if (this.$screen.width > 912) {
				maxNumberLetter = 15;
			}

			if (value.length > maxNumberLetter) {
				return value.substr(0, maxNumberLetter) + '...';
			}

			return value;
		},
		/**
		 *
		 * @param action: IT'S THE FUNCTION TO EXECUTE WHEN LOADING
		 * @param refContainer: IF THE LOADER MUST EXECUTE IN CONTAINER SPECIFIC, DECLARE THE REF HERE. IF IS NULL THE LOADER EXECUTE EN FULL SCREEN
		 * @param canCancel: Boolean value to activate cancel action or not when the loading is running.
		 * @param params: THE PARAMETERS OF THE FUNCTION IF EXISTS.
		 */
		async showLoader(action, refContainer = null, canCancel = true, params = null) {
			let loader = this.$loading.show({
				container: refContainer === null ? null : this.$refs[refContainer],
				canCancel: canCancel,
				color: '#00A09C',
				loader: 'bars',
				width: 64,
				height: 64,
				opacity: 0.25
			});

			if (params) {
				await action(params);
			} else {
				await action();
			}

			loader.hide();
		},
		/**
		 * MAPPED TRANSPORT MEAN TO VALUE CORRESPOND IN CARBON CLAP
		 * @param type
		 * @param subType
		 * @param additionalSubType
		 */
		mappedTransportTypeToCarbonClapValue(type, subType, additionalSubType) {
			let result = 0;
			if ([1, 2, 3].includes(type)) {
				result = 18;
			} else if ([4, 5].includes(type)) {
				result = 14;
			} else if (type === 17) {
				result = 19;
			} else if (type === 18) {
				result = 10;
			} else if (type === 19) {
				result = 10;
			} else if (type === 20 || type === 25) {
				result = 11;
			} else if (type === 21) {
				result = 12;
			} else if (type === 9) {
				result = 20;
			} else if (type === 10) {
				result = 13;
			} else if (type === 11) {
				result = 6;
			} else if (type === 14) {
				result = 2;
			} else if ([6, 7, 8].includes(type) && subType === 1 && [7, 8].includes(additionalSubType)) {
				result = 3;
			} else if ([6, 7, 8].includes(type) && subType === 4 && [7, 8].includes(additionalSubType)) {
				result = 4;
			} else if ([6, 7, 8].includes(type) && subType === 2 && [7, 8].includes(additionalSubType)) {
				result = 1;
			} else if ([6, 7, 8].includes(type) && subType === 3 && [7, 8].includes(additionalSubType)) {
				result = 15;
			} else if (type === 12 && subType === 5 && [16, 17].includes(additionalSubType)) {
				result = 5;
			} else if (type === 12 && [6, 7, 8].includes(subType)) {
				result = 20;
			} else if (type === 13 && subType === 9) {
				result = 16;
			} else if (type === 13 && subType === 10) {
				result = 17;
			} else if (type === 15 && [11, 13].includes(subType)) {
				result = 8;
			} else if (type === 15 && subType === 12) {
				result = 8;
			} else if (type === 16 && [14, 15].includes(subType) && additionalSubType === 1) {
				result = 3;
			} else if (type === 16 && [14, 15].includes(subType) && additionalSubType === 4) {
				result = 4;
			} else if (type === 16 && [14, 15].includes(subType) && additionalSubType === 2) {
				result = 1;
			} else if (type === 16 && [14, 15].includes(subType) && additionalSubType === 3) {
				result = 15;
			} else if (type === SPE_VEHICLE_GENERATOR_TRUCK_TYPE || type === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE || type === SPE_VEHICLE_TRAILER_TYPE) {
				result = 0;
			} else {
				result = 0;
			}

			return result;
		},
		rendTransportationMean(transportType) {
			if ([1, 2, 3, 22, 23, 24].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.BOAT, transportType);
			}
			if ([4, 5].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.BUS, transportType);
			}
			if ([6, 7, 8].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.CAR, transportType);
			}
			if ([17, 18, 19, 20, 21, 25].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.FREIGHT, transportType);
			}
			if ([9, 10, 11, 12, 13, 14, 15, 16].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.OTHER, transportType);
			}
			if ([26, 27, 28].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.SPECIAL_VEHICLE, transportType);
			}
		},
		resolveGqlError(error, onlyMessage = false) {
			if (Object.keys(error).length > 0) {
				if (error.graphQLErrors.length > 0) {
					const status = error.graphQLErrors[0].status;
					const message = error.graphQLErrors[0].message;
					if (onlyMessage) {
						return message;
					}
					return status + ' : ' + message;
				} else if (error.networkError) {
					return 'network error';
				} else {
					//    console.log("in errors.vue error errorMessage unknown error:",this.error);
					return 'unknown error: ' + JSON.stringify(error);
				}
			} else {
				return '';
			}
		},
		convertHoursToMinutes(value) {
			if (value === '--:--') return 0;
			let valueSplited = value.split(':');
			let minutes = parseInt(valueSplited[0], 10) * 60 + parseInt(valueSplited[1], 10);

			return minutes;
		},
		convertMinutesToHours(value) {
			if (value <= 0) {
				return '00:00';
			} else {
				let min = value;
				let hours = min / 60;
				let rhours = Math.floor(hours);
				let minutes = (hours - rhours) * 60;
				let rminutes = Math.round(minutes);

				let result = `${rhours < 10 ? '0' + rhours : rhours}:${rminutes < 10 ? '0' + rminutes : rminutes}`;

				return result === '00:00' ? '00:00' : result;
			}
		},
		getStateValidationField(field) {
			const customCond = `this.$v.${field}.$error === false`;
			if (eval(customCond)) {
				return null;
			}

			return false;
		},
		navigatorLanguage() {
			// Modern browsers support navigator.languages
			if (navigator.languages && navigator.languages.length) {
				return navigator.languages[0];
			}
			// For IE and older versions of Microsoft Edge, use navigator.userLanguage
			if (navigator.userLanguage) {
				return navigator.userLanguage;
			}
			// If none of the above is available, use a default language code
			return 'en';
		},
		reinitializeAddressData(country, countryKey, countryList) {
			if (country) {
				const countryDescription = countryList.filter((x) => x.value === country.value)[0].text;

				return {
					country: countryDescription,
					countryCode: country.text,
					countryKey: countryKey,
					street: '',
					city: '',
					number: '',
					state: '',
					zip: '',
					lat: 0,
					lng: 0
				};
			}
			return {};
		},
		onCustomFieldValueTemplateChanged(payload, userId = null) {}
	}
};
