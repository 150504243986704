import Vue from 'vue';
import moment from 'moment';

import { isNil } from '~utils';

const filters = {
	// /**
	//  * Pluralize
	//  * @param {String} str
	//  * @param {Number} count
	//  * @return {string}
	//  */
	// pluralize: (str, count) => {
	//   return count > 1 ? `${str}s` : str;
	// },

	/**
	 * @param {String} date
	 * @return {String}
	 */
	forHuman: (date) => {
		if (isNil(date)) return;
		return moment(new Date(date)).format('ddd, hA');
	},

	/**
	 * @param {String} s
	 * @return {String}
	 */
	capitalize: (s) => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	},

	/**
	 * @param {String} str
	 * @param {Number} limit - numberToTruncate
	 * @return {String}
	 */
	truncate: (str, limit = 100) => {
		if (isNil(str)) return '...';
		return str.length > limit ? str.substring(0, limit - 3) + '...' : str;
	},
	/**
	 * Pluralize
	 * @param {String} singular
	 * @param {Number} count
	 * @param {String|null} plural
	 * @return {string}
	 */
	pluralize: (singular, count, plural = null) => {
		if (isNil(plural)) return count > 1 ? `${singular}s` : singular;
		return count > 1 || count === 0 ? plural : singular;
	},

	/**
	 * @param {Number} millis
	 * @return {String}
	 */
	millisToMinutesAndSeconds: (millis) => {
		const date = new Date(millis);
		const seconds = date.getSeconds();
		return `${date.getMinutes()}:${seconds < 10 ? '0' : ''}${seconds}`;
	}
};

/**
 * Injecting all filters inside the propject
 */
Object.keys(filters).forEach((key) => {
	Vue.filter(key, filters[key]);
});
