const LocationsIndex = () => import('@/views/locations/index');
const PremisesIndex = () => import('./views/green/premiseList.vue');

const mainSpecialEquipments = () => import('./views/special-equipments/MainSpecialEquipments.vue');

export const carbonClapRoutes = [
	{
		path: 'green',
		meta: { label: 'Green' },
		name: 'green-parent',
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: 'list',
				meta: { label: 'Table' },
				component: () => import('@/modules/carbonclap/views/green/index.vue'),
				name: 'carbonClapGreen'
			},
			{
				path: 'carbon-clap',
				meta: { label: 'Reporting' },
				component: () => import('@/modules/carbonclap/views/reporting/index.vue'),
				name: 'carbonClapReporting'
			},
			{
				path: 'carbon-template',
				meta: { label: 'Carbon template' },
				component: () => import('@/modules/carbonclap/views/carbon-template/index.vue'),
				name: 'carbonTemplate'
			},
			{
				path: 'locations',
				meta: { label: 'Green locations' },
				component: LocationsIndex,
				name: 'greenLocations'
			},
			{
				path: 'premises',
				meta: { label: 'Green premises' },
				component: PremisesIndex,
				name: 'greenPremises'
			},
			{
				path: 'special-equipments',
				meta: { label: 'Special equipments' },
				component: mainSpecialEquipments,
				name: 'greenSpecialEquipments'
			}
		]
	},
	{
		path: 'special-equipments',
		meta: { label: 'Special equipments' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: '/',
				meta: { label: 'Table' },
				component: mainSpecialEquipments,
				name: 'mainSpecialEquipments'
			}
		]
	}
];
