<template>
	<div class="separator">
		<i />
		<small>{{ label }}</small>
	</div>
</template>

<script>
export default {
	name: 'SeparatorLabel',
	props: {
		label: {
			type: String,
			require: false,
			default: 'or'
		}
	}
};
</script>

<style lang="scss" scoped>
.separator {
	width: 100%;
	height: 30px;
	position: relative;
	i,
	small {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: auto;
		transform: translate(-50%, -50%);
	}
	i {
		width: 80px;
		display: block;
		height: 1px;
		background-color: #333;
		opacity: 0.5;
	}
	small {
		background-color: #fff;
		padding: 0 15px;
	}
}
</style>
