const state = {
	formsMap: '{}'
};

const toObject = (map) => {
	const mapped = Object.fromEntries(map);
	for (let key in mapped) {
		if (mapped[key]['messageMap'] instanceof Map) {
			mapped[key]['messageMap'] = Object.fromEntries(mapped[key]['messageMap']);
		}
	}
	return mapped;
};

const toMap = (obj) => {
	const myObj = Object.entries(JSON.parse(obj));
	myObj.map(([key, value]) => {
		if (value['messageMap'] instanceof Object) {
			value['messageMap'] = new Map(Object.entries(value['messageMap']));
		}
		return [key, value];
	});
	return new Map(myObj);
};

const getters = {
	getFormsMap: (state) => toMap(state.formsMap)
};

const mutations = {
	newFormsMap(state, payload) {
		state.formsMap = JSON.stringify(toObject(payload));
	}
};

const actions = {
	setFormsMap({ commit }, formsMap) {
		commit('newFormsMap', formsMap);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
