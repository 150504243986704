/**
 * Suppliers Store
 */
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '@/shared/utils';

const state = () => ({
	suppliers: [],
	focusSupplier: null,
	currentSupplier: null,
	newSupplierAdded: null
});

const getters = {
	suppliers: (state) => state.suppliers,
	focusSupplier: (state) => state.focusSupplier,
	currentSupplier: (state) => state.currentSupplier,
	newSupplierAdded: (state) => state.newSupplierAdded
};

const mutations = {
	SET_SUPPLIERS(state, suppliers) {
		state.suppliers = suppliers;
	},
	SET_FOCUS_SUPPLIER(state, supplier) {
		state.focusSupplier = supplier;
	},
	SET_NEW_SUPPLIER_ADDED(state, supplier) {
		state.newSupplierAdded = supplier;
	},
	SET_CURRENT_SUPPLIER(state, supplier) {
		state.currentSupplier = supplier;
	}
};

const actions = {
	async getSuppliers({ commit }, { name, type }) {
		const query = gql`
			query ($SupplierName: String, $Type: Int) {
				GetSuppliers(SupplierName: $SupplierName, Type: $Type) {
					id
					name
					type
					addressId
					address {
						id
						state
						street
						number
						box
						lat
						lng
						city
						country
						countryCode
						countryKey
						zip
					}
				}
			}
		`;
		const { data } = await apollo.query({
			query,
			variables: {
				SupplierName: name || null,
				Type: !isNil(type) ? parseInt(type) : null
			},
			fetchPolicy: 'network-only'
		});
		commit('SET_SUPPLIERS', data.GetSuppliers);
	},
	async getOneSupplier({ commit }, supplierID) {
		if (isNil(supplierID)) return;
		try {
			const query = gql`
				query ($SupplierId: ID!) {
					GetSupplier(SupplierId: $SupplierId) {
						licenseId
						iban
						bic
						info
						contactName
						contactFunction
						nationalRegistryNumber
						vat
						phone
						name
						type
						addressId
						address {
							id
							state
							street
							number
							box
							lat
							lng
							city
							country
							countryCode
							countryKey
							zip
						}
					}
				}
			`;
			const {
				data: { GetSupplier }
			} = await apollo.query({
				query,
				variables: {
					SupplierId: parseInt(supplierID)
				},
				fetchPolicy: "no-cache'"
			});
			commit('SET_FOCUS_SUPPLIER', {
				id: parseInt(supplierID),
				...GetSupplier
			});
		} catch (e) {
			console.log({ e });
		}
	},

	/**
	 * @param {String} supplier
	 */
	async addSupplier({ commit, dispatch }, supplier) {
		const mutation = gql`
			mutation ($SupplierName: String!, $Type: Int) {
				AddSupplier(SupplierName: $SupplierName, Type: $Type) {
					id
					name
					type
				}
			}
		`;
		try {
			const { data } = await apollo.mutate({
				mutation,
				variables: {
					SupplierName: supplier.name,
					Type: parseInt(supplier.type)
				}
			});
			await commit('SET_CURRENT_SUPPLIER', data.AddSupplier);
			dispatch('getSuppliers', { type: parseInt(supplier.type) });
		} catch (e) {
			throw new Error(e);
		}
	},

	async addAddressSupplier({ commit, dispatch }, { supplier, address }) {
		const mutation = gql`
			mutation ($UpdatedSupplier: SupplierInput!, $UpdatedSupplierAddress: AddressInput!, $SupplierId: ID!) {
				UpdSupplier(UpdatedSupplier: $UpdatedSupplier, UpdatedSupplierAddress: $UpdatedSupplierAddress, SupplierId: $SupplierId) {
					id
					name
					address {
						id
					}
				}
			}
		`;
		try {
			const { data } = await apollo.mutate({
				mutation,
				variables: {
					UpdatedSupplier: {
						name: supplier.name
					},
					UpdatedSupplierAddress: address,
					SupplierId: parseInt(supplier.id, 10)
				}
			});
			commit('SET_NEW_SUPPLIER_ADDED', data.UpdSupplier);
			dispatch('getSuppliers', supplier);
		} catch (e) {
			throw new Error(e);
		}
	},

	async delSupplier({ dispatch }, id) {
		const query = gql`
      mutation($SupplierId: ID!) {
        DelSupplier(SupplierId: $SupplierId) {}
      }
    `;
		await apollo.query({
			query,
			variables: id
		});
		dispatch('getSuppliers');
	},

	async updateSupplier({ commit }, { id, supplier, address }) {
		try {
			const mutation = gql`
				mutation ($SupplierId: ID!, $UpdatedSupplier: SupplierInput!, $UpdatedSupplierAddress: AddressInput!) {
					UpdSupplier(SupplierId: $SupplierId, UpdatedSupplier: $UpdatedSupplier, UpdatedSupplierAddress: $UpdatedSupplierAddress) {
						licenseId
						iban
						bic
						info
						contactName
						contactFunction
						nationalRegistryNumber
						vat
						phone
						name
						type
						addressId
						address {
							id
							state
							street
							number
							box
							lat
							lng
							city
							country
							countryCode
							countryKey
							zip
						}
					}
				}
			`;
			const {
				data: { UpdSupplier }
			} = await apollo.mutate({
				mutation,
				variables: {
					SupplierId: parseInt(id),
					UpdatedSupplier: supplier,
					UpdatedSupplierAddress: address
				}
			});
			commit('SET_FOCUS_SUPPLIER', {
				id: parseInt(id),
				...UpdSupplier
			});
		} catch (e) {
			console.log({ e });
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
