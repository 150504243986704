import { store } from '@/store';
import { isNil } from '@/shared/utils';
import { mapGetters, mapActions } from 'vuex';
// import store from '@/store/store';

import { getAllFormsData, addForm, addLabelToFormContentInDB, getAllMenus, getMenu, setGlobalMenuMap } from '@/cruds/language.crud';

export default {
	name: 'languageMessages',
	data() {
		return {
			// formsMap: new Map(),
			// messageMap: new Map(),
			// menuMap: new Map(),
			formsCache: {},
			menuCache: {}
		};
	},
	beforeCreate() {
		this.$nextTick()
			.then(async () => {
				// LOAD ALL MENU MESSAFGE AND FORMS MESSAGE TEXT.
				// await this.provideFormsMap(); // Load all forms content.
				await this.provideMessageMap(); // load all messages content.
				await this.provideMenuMap(); // load all menu content.
			})
			.catch((err) => {
				console.log(err);
			});
	},
	computed: {
		...mapGetters({
			getFormsMap: 'screen-forms/getFormsMap',
			getMenuMap: 'menu/getMenuMap'
		})
	},

	///////////////////////////////////////////////////////////////
	// old code commented out by Lanja.
	// REASON: The code has been removed inside the onLoad() store action fpr better initialization.
	///////////////////////////////////////////////////////////////
	// let formsMap = store.getScreenFormsMap();
	// async beforeCreate() {
	// let formObj;
	// //console.log("in created for form:", this.$options.name, formsMap);
	// if (isNil(formsMap)) {
	// 	//  return;
	// 	//console.log("in created for form: FORMSMAP UNDEFINED", this.$options.name);
	// 	//return;
	// 	//console.log("in created for form: GETTING DATA", this.$options.name);
	// 	// forms content never loaded, load all form content and put in the store (form Array
	// 	// loading forms from db
	// 	//console.log("in created, load all data by calling getAllformcontent");
	// 	const data = await getAllFormsData();
	// 	formsMap = this.parseFormScreensContent(data);

	// 	//console.log("getFormScreenContent data:", data);
	// 	store.setScreenFormsMap(formsMap);
	// 	//console.log("in created, formsMap loaded:",formsMap);
	// }
	// check if form content for current screen exists otherwise create it.
	// formObj = formsMap.get(this.$options.name); // getting formContent
	// if (isNil(formObj)) {
	// 	//console.log("in created form not found in formsMap, form name:",this.$options.name);
	// 	// adding protection against calling serveral times addform for the same form...
	// 	let tmpFormObj = {};
	// 	tmpFormObj.messageMap = new Map();
	// 	tmpFormObj.formNumber = 0;
	// 	formsMap.set(this.$options.name, tmpFormObj);
	// 	// console.log("temporaryformcreated:", tmpFormObj);

	// 	// add form to database
	// 	formObj = await addForm(this.$options.name);
	// 	console.log('in created form added:', formObj);
	// 	// add created form to formsMap
	// 	formObj.messageMap = new Map();
	// 	formsMap.set(this.$options.name, formObj);
	// }

	// this.messageMap = formObj.messageMap;
	//console.log("in created messageMap:",  this.messageMap);

	// managing menus
	// let menuMap = store.getAllMenuMap();

	// if (isNil(menuMap)) {
	// 	// menu content never loaded, load all menu content and put in the store (menu Array
	// 	// loading menus from db
	// 	//console.log("in created, load all menu by calling getAllMenus");
	// 	const data = await getAllMenus();
	// 	//console.log("getAllMenus data:", data);
	// 	menuMap = this.parseAllMenuContent(data);

	// 	//console.log("getAllMenus menuMap:", this.$options.name, menuMap);
	// 	store.setAllMenuMap(menuMap);
	// 	//console.log("in created, menuMap loaded:",menuMap);
	// 	// set global menu to direct js access in language.cruds.js
	// 	//console.log("call setGlobalMenuMap");
	// 	setGlobalMenuMap(menuMap);
	// }
	// this.menuMap = menuMap;
	// console.log('in created menuMap:', this.menuMap);
	// },
	methods: {
		...mapActions({
			setFormsMap: 'screen-forms/setFormsMap',
			setMenuMap: 'menu/setMenuMap'
		}),
		async provideFormsMap() {
			if (this.getFormsMap.size === 0) {
				const data = await getAllFormsData();
				const newFormsMap = this.parseFormScreensContent(data);
				await this.setFormsMap(newFormsMap);
			}
			// await store.dispatch('screen-forms/setFormsMap', newFormsMap);

			// get stored form maps
			// const storedFormsMap = this.getFormsMap;
			// if (!storedFormsMap[0]) {
			// 	// forms map never loaded, load all form map and put in the store
			// 	const data = await getAllFormsData();
			// 	const newFormsMap = this.parseFormScreensContent(data);
			// 	await store.dispatch('screen-forms/setFormsMap', newFormsMap);
			// 	this.formsMap = newFormsMap;
			// } else {
			// 	// else assign the data.
			// 	this.formsMap = storedFormsMap;
			// }
		},
		async provideMessageMap() {
			// preparing form objects.
			let formObj;
			if (this.getFormsMap.size === 0) {
				await this.provideFormsMap(); // load all forms content.
			}

			formObj = this.getFormsMap.get(this.$options.name); // getting formContent
			// check if form content for current screen exists otherwise create it.
			if (isNil(formObj)) {
				// adding protection against calling serveral times addform for the same form...
				// let tmpFormObj = {};
				// tmpFormObj.messageMap = new Map();
				// tmpFormObj.formNumber = 0;
				// this.formsMap.set(this.$options.name, tmpFormObj);
				// console.log("temporaryformcreated:", tmpFormObj);

				// add form to database
				formObj = await addForm(this.$options.name);
				console.log('in created form added:', formObj);
				// add created form to formsMap
				// formObj.messageMap = new Map();
				// this.formsMap.set(this.$options.name, formObj);

				// reload new Forms map.
				await this.setFormsMap(new Map());
				await this.provideFormsMap(); // reload all forms content.
				// await this.setFormsMap(newForms);
				// this.messageMap = newForms.get(this.$options.name).messageMap;
			}

			// this.messageMap = formObj.messageMap;
		},
		async provideMenuMap() {
			if (this.getMenuMap.size === 0) {
				const data = await getAllMenus();
				const newMenuMap = this.parseAllMenuContent(data);
				await this.setMenuMap(newMenuMap);
			}
			// set global menu to direct js access in language.cruds.js
			setGlobalMenuMap(this.getMenuMap);

			// store.setAllMenuMap(newMenuMap);
			// await store.dispatch('menu/setMenuMap', newMenuMap);
			// get stored menu maps
			// const storedMenusMap = this.getMenuMap;

			// if (!storedMenusMap[0]) {
			// 	// loading menus from db
			// 	const data = await getAllMenus();
			// 	const newMenuMap = this.parseAllMenuContent(data);
			// 	// store.setAllMenuMap(newMenuMap);
			// 	await store.dispatch('menu/setMenuMap', newMenuMap);
			// 	// this.setMenuMap(newMenuMap);
			// 	console.log('menu >>>>>>>>>>>>:', this.getMenuMap);
			// 	// set global menu to direct js access in language.cruds.js
			// 	setGlobalMenuMap(newMenuMap);
			// 	this.menuMap = newMenuMap;
			// } else {
			// 	this.menuMap = storedMenusMap;
			// }
		},
		parseFormScreensContent(data) {
			/* formMap object has the following structure:
			formMap {
				formName: string (key), objForm { formNumber: int, messageMap: map { labelID (int key), labelText} }
				}
			*/
			var formMap = new Map();
			var curFormObj = {};
			for (var i = 0; i < data.length; i++) {
				curFormObj = formMap.get(data[i].formName);
				if (isNil(curFormObj)) {
					// create the object for the form
					curFormObj = {};
					curFormObj.formNumber = parseInt(data[i].formNumber);
					curFormObj.messageMap = new Map();
					formMap.set(data[i].formName, curFormObj);
				}
				if (parseInt(data[i].value) != -1) {
					// if value == -1 message undefined, no message yet for this form
					curFormObj.messageMap.set(parseInt(data[i].value), data[i].message);
				}
			}
			//console.log("Loaded form data from db, formMap:", formMap);
			return formMap;
		},
		parseAllMenuContent(data) {
			/* parse database menuitems and fills a  map with menunumber and  an array menuitems :
				we create a map of menuNumber =>
				[{
					value: 0,
					text: 'Not defined'
				}, {};;;
				]
			*/
			var menuMap = new Map();
			var curMenuObj = {};
			for (var i = 0; i < data.length; i++) {
				curMenuObj = menuMap.get(parseInt(data[i].number));
				if (isNil(curMenuObj)) {
					// create the object for the menu
					//console.log("in parseMenu, create curMenuObj for number:",parseInt(data[i].number));
					curMenuObj = {};
					curMenuObj.number = parseInt(data[i].number);
					curMenuObj.menuArray = [];
					menuMap.set(curMenuObj.number, curMenuObj.menuArray);
				}
				// adding item in curMenuObj
				var curMenuItem = {};
				curMenuItem.value = parseInt(data[i].value);
				curMenuItem.text = data[i].message;
				curMenuItem.factor = data[i].paramFloat;
				//console.log("in parseMenu, create curMenuItem for value:",curMenuItem.value);
				var ar = menuMap.get(parseInt(data[i].number));
				ar.push(curMenuItem);
			}
			//console.log("Loaded menu data from db, menuMap:", menuMap);
			return menuMap;
		},
		FormMenuSorted(menuId) {
			var foundMenuArray = this.FormMenu(menuId);
			foundMenuArray.sort((a, b) => (a.text < b.text ? -1 : Number(a.text > b.text)));
			return foundMenuArray;
		},
		FormMenu(menuId) {
			// get stored menu maps
			// const storedMenusMap = store.getAllMenuMap();
			// if (isNil(storedMenusMap)) {
			// 	this.provideMenuMap();
			// }

			// const getMessageMap = async () => {
			// 	// get stored menu maps
			// 	const storedMenusMap = store.getAllMenuMap();

			// 	if (isNil(storedMenusMap)) {
			// 		// loading menus from db
			// 		const data = await getAllMenus();
			// 		const newMenuMap = this.parseAllMenuContent(data);
			// 		store.setAllMenuMap(newMenuMap);
			// 		// set global menu to direct js access in language.cruds.js
			// 		setGlobalMenuMap(newMenuMap);
			// 		return newMenuMap;
			// 	} else {
			// 		return storedMenusMap;
			// 	}
			// },
			const keyCache = JSON.stringify(menuId);
			if (this.menuCache[keyCache]) {
				return this.menuCache[keyCache];
			} else {
				// console.log('MenuMap, menuId', this.getMenuMap, menuId);
				let foundMenuArray = this.getMenuMap.get('' + menuId);

				// console.log('FormMenu, foundMenuArray:', foundMenuArray);
				if (isNil(foundMenuArray)) {
					//console.log("in FormMenu menu not found:", menuId);
					return [{ value: 0, text: 'not found' }];
				}

				const addTranslatorInfo = store.isTranslator() && this.$cookies.get('showTradInfo') == 'true';

				if (addTranslatorInfo) {
					// add information for translator form number and label number
					let newMenuArray = [];
					for (let i = 0; i < foundMenuArray.length; i++) {
						// adding item in curMenuObj
						let curMenuItem = {};
						curMenuItem.value = parseInt(foundMenuArray[i].value);
						curMenuItem.text = foundMenuArray[i].text + '(' + menuId + ',' + foundMenuArray[i].value + ')';
						curMenuItem.factor = parseInt(foundMenuArray[i].factor);
						newMenuArray.push(curMenuItem);
					}

					this.menuCache[keyCache] = newMenuArray;
					return newMenuArray;
				}

				this.menuCache[keyCache] = foundMenuArray;
				return foundMenuArray;
			}
		},
		GetTextFromMenuNumberAndMenuValue(mnuNumber, mnuValue) {
			//console.log("in GetTextFromMenuNumberAndMenuValue, mnuNumber mnuValue",mnuNumber, mnuValue);
			const mnu = this.FormMenu(parseInt(mnuNumber));
			const mnuVal = parseInt(mnuValue);
			for (let i = 0; i < mnu.length; i++) {
				if (mnu[i].value === mnuVal) {
					return mnu[i].text;
				}
			}

			return 'Not found';
		},
		FormMSG(id, label) {
			const keyCache = JSON.stringify(id + '-' + label);
			if (this.formsCache[keyCache]) {
				return this.formsCache[keyCache];
			} else {
				const formsMap = this.getFormsMap;
				if (formsMap === undefined) {
					//console.log("in FormMSG formsMap not loaded id:",id);
					//console.log("in FormMSG formsMap not loaded label:",label);
					return label + '_'; //"_fmnl";  // formmap not loaded
				} else {
					const formObj = formsMap.get(this.$options.name); // getting formContent
					// console.log('formsMap', formsMap);
					// console.log('in FormMSG formsMap name:', this.$options.name);
					// console.log('in FormMSG formsMap formObj:', formObj.messageMap);
					// console.log("in FormMSG formsMap id:",id);
					if (formObj === undefined) {
						return label + '_FNE'; // Form does Not Exist in map (should never happen as it is created in "create" above)
					} else if (formObj.formNumber > 0) {
						// avoid ading label to forms not yet created in database (formNumber==0)
						//console.log("in FormMSG formObj:", formObj);
						let foundLabel = formObj.messageMap.get('' + id);
						// console.log('found label ===== ', foundLabel);
						if (foundLabel === undefined) {
							// adding label to local map to avoid calling serveral times the db to add it as vue updates multiple times the labels
							formObj.messageMap.set(parseInt(id), label); //+ "_AToDB");
							// add label to db , will be loaded next time
							console.log('in FormMSG adding label for form:', this.$options.name, formObj.messageMap);
							console.log('in FormMSG adding label :', formObj.formNumber, id, label);
							// new Promise((resolve, reject) => {
							// 	setTimeout(async () => {
							// 		resolve(await addLabelToFormContentInDB(formObj.formNumber, id, label, 0, true));
							// 		reject(false);
							// 	}, 500);
							// });

							addLabelToFormContentInDB(formObj.formNumber, id, label, 0, true).then(
								this.setFormsMap(new Map()).then(
									() => this.provideFormsMap() // reload all forms content.
								)
							);

							this.formsCache[keyCache] = label;
							return label; // + "_laddedToDb";  // label does not exist, create it
						} else {
							// if (foundLabel != undefined) {
							//console.log("in FormMSG foundLabel in messageMap:", foundLabel);
							var addTranslatorInfo = store.isTranslator() && this.$cookies.get('showTradInfo') == 'true';
							if (addTranslatorInfo) {
								// add information for translator form number and label number
								foundLabel = foundLabel + '(' + formObj.formNumber + ',' + id + ')';
							}
							// should never come here as messageMap would find the label above
							//console.log("in FormMSG foundLabel from formContent:", foundLabel);

							this.formsCache[keyCache] = foundLabel;
							return foundLabel;
						}
					}
				}
				return label + '_NF';
			}
			//console.log("in formMsg label = ",label);
			// var foundLabel = this.messageMap.get('' + id);
			// console.log('found label = ', foundLabel);
			// if (foundLabel != undefined) {
			// 	//console.log("in FormMSG foundLabel in messageMap:", foundLabel);
			// 	var addTranslatorInfo = store.isTranslator() && this.$cookies.get('showTradInfo') == 'true';
			// 	if (addTranslatorInfo) {
			// 		// add information for translator form number and label number
			// 		var formMap = this.getFormsMap;
			// 		var formSc = formMap.get(this.$options.name);
			// 		foundLabel = foundLabel + '(' + formSc.formNumber + ',' + id + ')';
			// 	}
			// 	return foundLabel; // + "_T";
			// }
			// label not found check if needs to add it to database
		}
	}
};
