// import canAccessToAppMiddleware from '../../middleware/canAccessToAppMiddleware';

const MainJobRequest = () => import('./views/index.vue');
const JobRequestList = () => import('./views/list.vue');
const JobRequestDetails = () => import('./views/_id.vue');

const ContainerWithoutMenu = () => import('@/containers/ContainerWithoutMenu');
const DefaultContainer = () => import('@/containers/DefaultContainer');

import canAccessJobRequestMiddleware from './middleware/canAccessJobRequestMiddleware';
import { ROUTE_NAME } from '@/shared/constants';

export const jobRequestRoutes = [
	{
		path: '/job-request',
		meta: { label: 'Job Request' },
		component: ContainerWithoutMenu,
		children: [
			{
				path: '/',
				name: 'jobRequest',
				component: MainJobRequest
			}
		]
	},
	{
		path: '/job-requests',
		redirect: '/pages/login',
		component: DefaultContainer,
		children: [
			{
				path: '/',
				name: 'jobRequestList',
				meta: { middleware: canAccessJobRequestMiddleware },
				component: JobRequestList
			},
			{
				path: ':id',
				name: ROUTE_NAME.jobRequestDetail,
				meta: { middleware: canAccessJobRequestMiddleware },
				component: JobRequestDetails
			}
		]
	}
];
