const state = () => ({
	currentTransportId: 0,
	currentTransport: {}
});

const getters = {
	currentTransportId: (state) => state.currentTransportId,
	currentTransport: (state) => state.currentTransport
};

const mutations = {
	SET_CURRENT_TRANSPORT_ID(state, currentId) {
		state.currentTransportId = currentId;
	},
	SET_CURRENT_TRANSPORT(state, transport) {
		state.currentTransport = transport;
	}
};

const actions = {
	setCurrentTransportId({ commit }, id) {
		commit('SET_CURRENT_TRANSPORT_ID', id);
	},
	setCurrentTransport({ commit }, transport) {
		commit('SET_CURRENT_TRANSPORT', transport);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
