/**
 * Expenses Store
 *
 * @doc     https://bootstrap-vue.js.org/docs/reference/color-variants
 */
import gql from 'graphql-tag';
import { isNil } from '@/shared/utils';
import { apolloClient as apollo } from '@/vue-apollo';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';

const state = () => ({
	purchaseOrders: [],
	purchaseOrder: null,
	currExpsneRefPO: {},
	unsentOrRefusedPurchaseOrder: [],
	purchaseOrdersNotConsumed: [],
	sentPurchaseOrder: [],
	currentPOItem: null
});

const getters = {
	purchaseOrders: (state) => state.purchaseOrders,
	purchaseOrder: (state) => state.purchaseOrder,
	currExpsneRefPO: (state) => state.currExpsneRefPO,
	purchaseOrdersNotConsumed: (state) => state.purchaseOrdersNotConsumed,
	unsentOrRefusedPurchaseOrder: (state) => state.unsentOrRefusedPurchaseOrder,
	sentPurchaseOrder: (state) => state.sentPurchaseOrder,
	currentPOItem: (state) => state.currentPOItem
};

const mutations = {
	SET_PURCHASE_ORDER(state, purchaces) {
		state.purchaseOrders = purchaces;
	},

	SET_SHOW_PURCHASE_ORDER(state, purchaseOrder) {
		state.purchaseOrder = purchaseOrder;
	},

	SET_CURRENT_EXPENSE_REF(state, purchaseOrder) {
		state.currExpsneRefPO = purchaseOrder;
	},

	SET_UNSENT_OR_REFUSED_PURCHASE_ORDER(state, pos) {
		state.unsentOrRefusedPurchaseOrder = cleanPOs(pos);
	},

	SET_SENT_PURCHASE_ORDER(state, pos) {
		state.sentPurchaseOrder = cleanPOs(pos);
	},

	SET_CURRENT_PO_ITEM(state, po) {
		state.currentPOItem = po;
	},

	SET_SENT_NOT_CONSUMED_PURCHASE_ORDER(state, po) {
		state.purchaseOrdersNotConsumed = po;
	}
};

const actions = {
	async getPurchaseOrders({ commit }) {
		const data = await getPOs(1);
		commit('SET_PURCHASE_ORDER', data.MyPurchaseOrders);
	},

	async getUnsentPurchaseOrders({ commit }) {
		const data = await getPOs(-1);
		commit('SET_UNSENT_OR_REFUSED_PURCHASE_ORDER', data.MyPurchaseOrders);
	},

	async getSentPurchaseOrders({ commit }) {
		const data = await getPOs(-2);
		commit('SET_SENT_PURCHASE_ORDER', data.MyPurchaseOrders);
	},

	async getPurchaseOrdersNotConsumed({ commit }) {
		const data = await getPOs(-3);
		commit('SET_SENT_NOT_CONSUMED_PURCHASE_ORDER', data.MyPurchaseOrders);
	},

	async newPurchaseOrder({ dispatch }) {
		try {
			const mutation = gql`
				mutation {
					NewPurchaseOrder {
						id
						userProjectId
						encoderId
						poReference
						description
						department
						supplierId
						consumed
						requestDate
						deliveryDate
						deliveryAddress
						invoicedToProduction
						validated
						laValidatedId
						laValidatedWhen
						lbValidatedId
						lbValidatedWhen
						amount
						amountVat
						amountTotal
						information
						comment
						notDetailed
						departmentName
						supplierName
						function
						functionName
						expenseTotal
						validatedMsg
						projectTitle
						projectIdEx
						isInContract
						images
						itemCategory
						user {
							id
							name
							firstName
						}
						encoder {
							id
							name
							firstName
						}
					}
				}
			`;
			const {
				data: { NewPurchaseOrder: po }
			} = await apollo.mutate({
				mutation
			});
			dispatch('setCurrentPOItem', po);
		} catch (e) {
			console.log({ e });
		}
	},

	async updatePurchaseOrder({ dispatch }, { id, po }) {
		try {
			const mutation = gql`
				mutation ($id: ID!, $po: PurchaseOrderInput!, $UserId: ID) {
					UpdPurchaseOrder(PurchaseOrderId: $id, UpdatedPO: $po, UserId: $UserId) {
						id
						userProjectId
						encoderId
						poReference
						description
						department
						supplierId
						consumed
						requestDate
						deliveryDate
						deliveryAddress
						invoicedToProduction
						validated
						laValidatedId
						laValidatedWhen
						lbValidatedId
						lbValidatedWhen
						amount
						amountVat
						amountTotal
						information
						comment
						notDetailed
						departmentName
						supplierName
						function
						functionName
						expenseTotal
						validatedMsg
						projectTitle
						projectIdEx
						isInContract
						images
						itemCategory
						user {
							id
							name
							firstName
						}
						encoder {
							id
							name
							firstName
						}
					}
				}
			`;
			const {
				data: { UpdPurchaseOrder: newPO }
			} = await apollo.mutate({
				mutation,
				variables: {
					id: parseInt(id, 10),
					UserId: parseInt(UserId, 10),
					po
				},

				fetchPolicy: 'no-cache'
			});
			dispatch('setCurrentPOItem', newPO);
			dispatch('getUnsentPurchaseOrders');
			dispatch('getSentPurchaseOrders');
		} catch (e) {
			console.error({ e });
		}
	},

	/**
	 * @param {Object} po
	 */
	async setCurrentPOItem({ commit }, po) {
		commit('SET_CURRENT_PO_ITEM', po);
	},

	async deletePurchaseOrder({ dispatch }, id) {
		if (isNil(id)) return;
		try {
			const mutation = gql`
				mutation ($PurchaseOrderId: ID!) {
					DelPurchaseOrder(PurchaseOrderId: $PurchaseOrderId)
				}
			`;
			await apollo.mutate({
				mutation,
				variables: { PurchaseOrderId: parseInt(id) }
			});
			dispatch('setCurrentPOItem', null);
			dispatch('getUnsentPurchaseOrders');
		} catch (e) {
			console.log({ e });
		}
	},

	/**
	 * @param {Number} poID - purchaseOrderID
	 */
	async sendToValidation({ dispatch }, id) {
		try {
			const mutation = gql`
				mutation ($PurchaseOrderId: ID!) {
					SendPurchaseOrder(PurchaseOrderId: $PurchaseOrderId) {
						id
					}
				}
			`;
			await apollo.mutate({
				mutation,
				variables: { PurchaseOrderId: parseInt(id) }
			});

			dispatch('getUnsentPurchaseOrders');
			dispatch('getSentPurchaseOrders');
		} catch (e) {
			console.log({ e });
		}
	},

	async sendAllPurchaseOrder({ dispatch }) {
		try {
			await apollo.mutate({
				mutation: gql`
					mutation {
						SendAllPurchaseOrder {
							id
						}
					}
				`
			});
			/*
      this.$app.$toast({
        title: "Send for validation",
        variant: "success",
        message: "Was successfull"
      });
*/
			dispatch('getUnsentPurchaseOrders');
			dispatch('getSentPurchaseOrders');
		} catch (e) {
			console.log(e);
		}
	},

	async createExpense({ commit }, { ExpenseType, PurchaseOrderId }) {
		try {
			const mutation = gql`
				mutation ($ExpenseType: ID!, $PurchaseOrderId: ID) {
					NewExpense(ExpenseType: $ExpenseType, PurchaseOrderId: $PurchaseOrderId) {
						id
					}
				}
			`;
			const { data } = await apollo.mutate({
				mutation,
				variables: {
					ExpenseType,
					PurchaseOrderId
				}
			});
			commit('SET_CURRENT_EXPENSE_REF', data.NewExpense);
		} catch (e) {
			throw new Error(e);
		}
	},

	/**
	 * @param {Number} id - PurchaseOrderId
	 */
	async getPurchaseOrder({ commit }, id) {
		const query = gql`
			query ($PurchaseOrderId: ID!) {
				GetPo(PurchaseOrderId: $PurchaseOrderId) {
					id
					validated
					description
					department
					supplierId
					supplierName
					amount
					amountVat
					amountTotal
				}
			}
		`;
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await apollo.query({
					query,
					variables: {
						PurchaseOrderId: parseInt(id)
					},
					fetchPolicy: 'no-cache'
				});
				if (isNil(data.GetPo)) {
					reject(null);
				} else {
					commit('SET_SHOW_PURCHASE_ORDER', data.GetPo);
					resolve(data.GetPo);
				}
			} catch (e) {
				console.log({ e });
			}
		});
	},

	destroySelectedPO({ commit }) {
		commit('SET_CURRENT_PO_ITEM', null);
		commit('SET_SHOW_PURCHASE_ORDER', null);
	}
};

// HELPERS

/**
 * @param {Number} status
 * @return {Object}
 */
const getPOs = async (status) => {
	const Validated = status || 1;
	// -1, returns all unsent Pos and ‘refused’ Pos
	//  {0, returns all unsent Pos
	//  1, Sent and validated Pos
	// -2, wait for validation
	// -3, send all validated level 2 POs and not consumed yet
	const query = gql`
		query ($Validated: Int) {
			MyPurchaseOrders(Validated: $Validated) {
				id
				department
				departmentName
				description
				invoicedToProduction
				poReference
				expenseTotal
				supplierId
				comment
				supplierName
				deliveryDate
				consumed
				amount
				amountVat
				amountTotal
				userProjectId
				requestDate
				validated
				address {
					id
					state
					street
					number
					box
					lat
					lng
					city
					country
					countryCode
					countryKey
					zip
				}
			}
		}
	`;
	const { data } = await apollo.query({
		query,
		variables: {
			Validated
		},
		fetchPolicy: 'no-cache'
	});

	return data;
};

const cleanPOs = (pos) => {
	Object.keys(pos).forEach((elem) => {
		pos[elem] = {
			...pos[elem],
			validatedStatus: validatedText(pos[elem].validated),
			_rowVariant: '', //validatedColor(pos[elem].validated),
			validatedClass: validatedColor(pos[elem].validated)
		};
	});
	return pos;
};

const validatedText = (validated) => {
	// returns the label text corresponding to the validated value from menu 1008
	return getTextFromMenuNumberAndMenuValue(1008, validated);
};

const validatedColor = (validated) => {
	let retval = 'grey';
	if (validated === 1) {
		retval = 'primary';
	} else if (validated === 2) {
		retval = 'warning';
	} else if (validated === 8) {
		retval = 'success';
	} else if (validated === 4 || validated === 16) {
		retval = 'danger';
	}
	return retval;
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
