import Vue from 'vue';

import Separator from '@/components/Separator';
import errorContainer from '@/components/errors';

import IconCheckCircle from '@/components/icons/check-circle';
import IconXCircle from '@/components/icons/x-circle';
import IconHandThumbsDown from '@/components/icons/hand-thumbs-down';
import IconHandThumbsUp from '@/components/icons/hand-thumbs-up';
import Modal from '@/components/Modals/BaseModal';

// CARDS
import CollapseCard from '@/components/CollapseCard';
import CardListBuilder from '@/components/CardListBuilder';

const globalComponents = {
	Separator,
	errorContainer,
	Modal,

	// Icons
	IconCheckCircle,
	IconXCircle,
	IconHandThumbsDown,
	IconHandThumbsUp,

	CollapseCard,
	CardListBuilder
};

/**
 * Injecting all components inside the propject
 */
Object.keys(globalComponents).forEach((key) => {
	Vue.component(key, globalComponents[key]);
});
