import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const getProjectFlagItems = async (Types, SortByBookMark) => {
	try {
		const response = await apollo.query({
			query: gql`
				query GetProjectFlagItems($Types: [ID!], $SortByBookMark: Boolean) {
					GetProjectFlagItems(Types: $Types, SortByBookMark: $SortByBookMark) {
						id
						favoriteProjectFlagItems {
							id
							name
							value
							short
							factor
							flagId
							amount
							payCode
							forScreen
							defaultValue
							jsFormula
							color
							bookmark
							category
							digitalSubmission
							disabled
							allowanceRate
							costCenter
						}
						projectFlagItems {
							id
							name
							value
							short
							factor
							flagId
							amount
							payCode
							forScreen
							defaultValue
							jsFormula
							color
							bookmark
							category
							digitalSubmission
							disabled
							allowanceRate
							costCenter
						}
					}
				}
			`,
			variables: {
				Types: Types,
				SortByBookMark: SortByBookMark
			},
			fetchPolicy: 'no-cache'
		});

		const { GetProjectFlagItems } = response.data;
		return GetProjectFlagItems;
	} catch (e) {
		console.log(e.message);
	}
};

export const getProjectLocationList = async (Date) => {
	const QUERY_GET_PROJECT_LOCATION_LIST = gql`
		query ($Date: String) {
			GetProjectLocationList(Date: $Date) {
				id
				date
				setName
			}
		}
	`;

	const {
		data: { GetProjectLocationList }
	} = await apollo.query({
		query: QUERY_GET_PROJECT_LOCATION_LIST,
		variables: {
			Date
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectLocationList;
};
