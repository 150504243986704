import { categories as peachCategories } from '@/modules/peach-pear-plum/peachCategories';
// import { categories as pearCategories } from '@/modules/peach-pear-plum/pearCategories';

const peachRoutes = peachCategories.map(({ name, title }) => {
	return {
		path: name,
		meta: { label: title },
		component: () => import('@/modules/peach-pear-plum/views/peachForm'),
		name: name
	};
});

export const peachPearPlumRoutes = [
	{
		path: 'peach-pear-plum',
		meta: { label: 'PEACH PEAR PLUM' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: '',
				meta: { label: 'PEACH PEAR PLUM' },
				component: () => import('@/modules/peach-pear-plum/views/dashboard.vue'),
				name: 'peach-pear-plum-page'
			},
			{
				path: 'peach',
				meta: { label: 'PEACH' },
				component: {
					render(c) {
						return c('router-view');
					}
				},
				children: [
					{
						path: '',
						meta: { label: 'PEACH Report' },
						component: () => import('@/modules/peach-pear-plum/views/peachReport.vue'),
						name: 'peach-report-page'
					},
					...peachRoutes
				]
			}
			// {
			// 	path: 'pear',
			// 	meta: { label: 'PEAR' },
			// 	component: {
			// 		render(c) {
			// 			return c('router-view');
			// 		}
			// 	},
			// 	children: [
			// 		{
			// 			path: '',
			// 			meta: { label: 'PEAR Report' },
			// 			component: () => import('@/modules/peach-pear-plum/views/pear.vue')
			// 		}
			// 	]
			// },
			// {
			// 	path: 'plum',
			// 	meta: { label: 'Plum' },
			// 	component: () => import('@/modules/peach-pear-plum/views/plum.vue'),
			// 	name: 'plum'
			// }
		]
	}
];
