const state = () => ({
	subscriptionViaLogin: false,
	subscriptionLicenseRequest: null,
	subscriptionProjectId: 0
});

const getters = {
	getSubscriptionViaLogin: (state) => state.subscriptionViaLogin,
	getSubscriptionLicenseRequest: (state) => state.subscriptionLicenseRequest,
	getSubscriptionProjectId: (state) => state.subscriptionProjectId
};

const mutations = {
	SET_SUBSCRIPTION_VIA_LOGIN(state, value) {
		state.subscriptionViaLogin = value;
	},
	SET_SUBSCRIPTION_LICENSE_REQUEST(state, value) {
		state.subscriptionLicenseRequest = value;
	},
	SET_SUBSCRIPTION_PROJECT_ID(state, value) {
		state.subscriptionProjectId = parseInt(value);
	},
	RESET_DATA(state) {
		state.subscriptionViaLogin = false;
		state.subscriptionLicenseRequest = null;
		state.subscriptionProjectId = 0;
	}
};

const actions = {
	updateSubscriptionViaLogin({ commit }, value) {
		commit('SET_SUBSCRIPTION_VIA_LOGIN', value);
	},
	updateSubscriptionLicenseRequest({ commit }, value) {
		commit('SET_SUBSCRIPTION_LICENSE_REQUEST', value);
	},
	updateSubscriptionProjectId({ commit }, value) {
		commit('SET_SUBSCRIPTION_PROJECT_ID', value);
	},
	resetSubscriptionData({ commit }) {
		commit('RESET_DATA');
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
