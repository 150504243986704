const state = {
	menuMap: ''
};

const getters = {
	getMenuMap: (state) => new Map(Object.entries(state.menuMap))
};

const mutations = {
	newMenuMap(state, payload) {
		state.menuMap = Object.fromEntries(payload);
	}
};

const actions = {
	setMenuMap({ commit }, menuMap) {
		commit('newMenuMap', menuMap);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
