import Vue from 'vue';
import { BToast } from 'bootstrap-vue';

const ToastHelper = {};

ToastHelper.install = function (Vue) {
	Vue.prototype.$toast = ({ message, title, variant, delay }) => {
		let bootStrapToaster = new BToast();

		bootStrapToaster.$bvToast.toast(message, {
			title,
			toaster: 'b-toaster-top-center',
			solid: true,
			appendToast: false,
			variant: variant || 'default',
			autoHideDelay: delay || 4000
		});
	};
};

Vue.use(ToastHelper);
