const questionsAccounting = [
	{
		formId: 1,
		question: 'Track the production’s environmental impact using an accepted methodology (i.e. PEAR or Albert).',
		availablePoints: 5
	},
	{
		formId: 2,
		question: 'Reduce printing by only printing when necessary and by printing double-sided. Set all printers to default to double-sided printing.',
		availablePoints: 0
	},
	{
		formId: 3,
		question: 'Did you use digital start paperwork? If yes, provide software name.',
		availablePoints: 2
	},
	{
		formId: 4,
		question: 'Did you use digital purchase orders or other digital accounting systems? If yes, provide program name.',
		availablePoints: 2
	},
	{ formId: 5, question: 'Recycle paper and toner cartridges.', availablePoints: 0 },
	{ formId: 6, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 7,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsArt = [
	{ formId: 8, question: 'Use existing sets or materials from another production.', availablePoints: 1 },
	{ formId: 9, question: 'Design to allow for reuse after strike, keeping end of life in mind.', availablePoints: 0 },
	{ formId: 10, question: 'Use standard sizes for doors and windows.', availablePoints: 0 },
	{
		formId: 11,
		question:
			'Choose construction and design materials that are made from recycled or environmentally preferred content and are recyclable (i.e. avoid foam, choose cardboard wall skins).',
		availablePoints: 1
	},
	{
		formId: 12,
		question: 'Follow green practices - reduce, reuse, recycle and shut down (e.g. reduce paper, refill water bottles, shut down equipment).',
		availablePoints: 0
	},
	{ formId: 13, question: 'Incorporate salvaged materials into design where possible.', availablePoints: 0 },
	{ formId: 14, question: 'Search for green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 15,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsAssistantDirectors = [
	{
		formId: 16,
		question: 'Were sustainability initiatives announced at the first safety meeting and communicated throughout production?',
		availablePoints: 1
	},
	{
		formId: 17,
		question:
			'Did you include green tips on call sheets and add reminders for people to bring reusable water bottles and other green on set tips? If yes, submit a sample.',
		availablePoints: 2
	},
	{
		formId: 18,
		question: 'Did you establish a paperless set (e.g. digital call sheets and slides)?',
		availablePoints: 1
	},
	{
		formId: 19,
		question: 'For online schedules, save ink by choosing white instead of black day break strips and banners. Avoid printing in color.',
		availablePoints: 0
	},
	{
		formId: 20,
		question: 'Were extras casting and day players told to bring refillable water bottles and to mind the production’s sustainability initiatives?',
		availablePoints: 1
	},
	{
		formId: 21,
		question: 'Ensure background holding has been provided water coolers and recycling bins.',
		availablePoints: 0
	},
	{
		formId: 22,
		question: 'Follow green practices - reduce, reuse, recycle and shut down (e.g., reduce paper, refill water bottles, shut down equipment).',
		availablePoints: 0
	},
	{ formId: 23, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 24,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsCamera = [
	{ formId: 25, question: 'Did you shoot digitally instead of on film?', availablePoints: 2 },
	{ formId: 26, question: 'If shooting on film, consider 3-perf over 4-perf?', availablePoints: 0 },
	{ formId: 27, question: 'Donate short ends and other expendables.', availablePoints: 0 },
	{ formId: 28, question: 'Stream dailies instead of burning DVDs.', availablePoints: 0 },
	{
		formId: 29,
		question: 'Was at least 30% of your lighting package energy efficient set lighting such as LEDs? If yes, confirm who provided the percentage.',
		availablePoints: 5
	},
	{ formId: 30, question: 'Use rechargeable batteries.', availablePoints: 0 },
	{ formId: 31, question: 'Did you properly recycle batteries, film waste, and/or media?', availablePoints: 1 },
	{ formId: 32, question: 'Send all bags, cores, and cans to lab with exposed film for reuse.', availablePoints: 0 },
	{
		formId: 33,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment).',
		availablePoints: 0
	},
	{ formId: 34, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 35,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsCatering = [
	{
		formId: 36,
		question: 'Were reusable dishes, cups, mugs, silverware & tablecloths used? Include the name of the catering vendor.',
		availablePoints: 1
	},
	{ formId: 37, question: 'Did you eliminate #6 polystyrene plastic products (aka Styrofoam)?', availablePoints: 1 },
	{
		formId: 38,
		question: 'Replace individual bottles of water with water jugs and refillable bottles.',
		availablePoints: 0
	},
	{
		formId: 39,
		question: 'Obtain as accurate headcount as possible so as not to overprepare food.',
		availablePoints: 0
	},
	{ formId: 40, question: 'Did you feature vegetarian or vegan entree options?', availablePoints: 2 },
	{
		formId: 41,
		question:
			'Did you implement a plan to reduce red meat and/or participate regularly in a complete vegetarian day (e.g. Meatless Mondays)? If yes, please explain.',
		availablePoints: 0
	},
	{ formId: 42, question: 'Serve local, seasonal, organic and/or sustainably grown food.', availablePoints: 2 },
	{
		formId: 43,
		question:
			'Do not purchase and “Red List” seafood. For guidance in North America, visit SeafoodWatch.org or in the United Kingdom visit www.GoodFishGuide.org.',
		availablePoints: 0
	},
	{
		formId: 44,
		question: 'Use coffee from environmentally preferable sources (fair trade/shade grown). Avoid “pod” single-use coffee and use reusable filters.',
		availablePoints: 0
	},
	{
		formId: 45,
		question: 'Was food that cannot be donated composted? If yes, provide the name of the waste vendor.',
		availablePoints: 0
	},
	{
		formId: 46,
		question: 'Provide recycling bins with lids and clear signs and coordinate with Production/Locations to ensure proper waste management and disposal.',
		availablePoints: 0
	},
	{ formId: 47, question: 'Flatten and recycle all cardboard.', availablePoints: 0 },
	{
		formId: 48,
		question: 'Use energy-efficient practices such as unplugging small appliances and choosing propane over charcoal/wood grills.',
		availablePoints: 0
	},
	{
		formId: 49,
		question: 'Prevent grease, oil, or other chemicals from entering drains and waterways.',
		availablePoints: 0
	},
	{
		formId: 50,
		question: 'Use environmentally friendly cleaning products (biodegradable, non-toxic, plant-based, Green Seal certified).',
		availablePoints: 0
	},
	{
		formId: 51,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment).',
		availablePoints: 0
	},
	{ formId: 52, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 53,
		question: 'Was leftover food donated to local food banks and/or charities? If yes, provide names of recipients.',
		availablePoints: 5
	},
	{
		formId: 54,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsConstruction = [
	{
		formId: 55,
		question: 'Track the origin and certification of all plywood purchases using the Production Lumber Material (PLUM) worksheet.',
		availablePoints: 0
	},
	{
		formId: 56,
		question: 'Did you use FSC Certified Lauan/meranti or a vetted sustainable alternative (e.g. RevolutionPly)? If yes, include supplier name.',
		availablePoints: 5
	},
	{
		formId: 57,
		question:
			'Create a plan with Production for strike, allowing for the maximum recovery of materials (e.g. build to allow for reuse after strike, engage recyclers/salvage companies, and identify incoming productions that could reuse).',
		availablePoints: 0
	},
	{
		formId: 58,
		question:
			'Were the majority of sets built with reused or repurposed set materials or modular blocks, or was minimal set construction done? If yes, please explain.',
		availablePoints: 5
	},
	{
		formId: 59,
		question: 'Did you use alternatives to wood for structural support, such as steel scaffolding or shipping containers?',
		availablePoints: 1
	},
	{
		formId: 60,
		question: 'Use building materials that are non-toxic and made from recycled content or renewable sources.',
		availablePoints: 0
	},
	{ formId: 61, question: 'Did you use paints, stains, and finishes that are low or no-VOC?', availablePoints: 1 },
	{ formId: 62, question: 'Use leftover paint as primer.', availablePoints: 0 },
	{
		formId: 63,
		question: 'Follow hazardous waste guidelines for handling paint, brushes, and rags (cannot be disposed of in regular waste bins and down drains).',
		availablePoints: 0
	},
	{
		formId: 64,
		question:
			'Were sets and set materials given to another production or vetted non-profits for reuse? If yes, include recipients and describe donated materials.',
		availablePoints: 5
	},
	{
		formId: 65,
		question: 'Was construction debris recycled? If yes, include waste vendor name.',
		availablePoints: 3
	},
	{
		formId: 66,
		question: 'Was specialized waste such as polystyrene blocks and carpet recycled? If yes, include vendor name.',
		availablePoints: 1
	},
	{
		formId: 67,
		question: 'Turn off power, HVAC, catwalk, and house lights when not in use and before leaving. Close elephant doors when the air conditioning is on.',
		availablePoints: 0
	},
	{
		formId: 68,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment).',
		availablePoints: 0
	},
	{
		formId: 69,
		question: 'Search for new green vendors at GreenProductionGuide.com',
		availablePoints: 0
	},
	{
		formId: 70,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsCostume = [
	{
		formId: 71,
		question: 'Did you purchase or rent wardrobe made with certified organic materials or waterless dyes or use second-hand clothing and accessories?',
		availablePoints: 1
	},
	{
		formId: 72,
		question: 'Were reusable shopping bags and garment bags used, and were clothing hangers returned for reuse?',
		availablePoints: 1
	},
	{
		formId: 73,
		question: 'Use a hybrid, electric, or fuel-efficient vehicle as much as possible.',
		availablePoints: 0
	},
	{
		formId: 74,
		question:
			'Did you avoid dry cleaning when possible and, when required, was a PERC-free environmentally preferable dry-cleaner used? If yes, provide the dry cleaner name.',
		availablePoints: 2
	},
	{
		formId: 75,
		question: 'Use non-toxic, environmentally friendly detergents.',
		availablePoints: 0
	},
	{
		formId: 76,
		question: 'When aging and dyeing, choose the least harmful/natural products. Reference the “Making of Making Powered by Nike” app for guidance.',
		availablePoints: 0
	},
	{ formId: 77, question: 'Recycle scrap fabrics through local textile recycling initiatives.', availablePoints: 0 },
	{
		formId: 78,
		question:
			'Were wardrobe articles and materials donated to local charities at the end of production? If yes, include recipient(s) and describe donated items.',
		availablePoints: 2
	},
	{
		formId: 79,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{ formId: 80, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 81,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsCraft = [
	{
		formId: 82,
		question: 'When providing water, did you choose aluminum cans or paper cups over single-use plastics?',
		availablePoints: 2
	},
	{
		formId: 83,
		question: 'Did you significantly reduce pre-packaged water by supplying water jugs?',
		availablePoints: 5
	},
	{
		formId: 84,
		question:
			'Provide adequate and convenient water stations using large dispensers. Place near craft tables, camera, work carts, background holding, and base camp. Tip: place dispensers on a cart or strap to dolly for easy mobility.',
		availablePoints: 0
	},
	{
		formId: 85,
		question: 'Use reusable dishes, cups, mugs, silverware, and tablecloths when possible.',
		availablePoints: 0
	},
	{
		formId: 86,
		question: 'Did you eliminate #6 polystyrene plastic products (aka Styrofoam)?',
		availablePoints: 1
	},
	{ formId: 87, question: 'Offer vegetarian or vegan options.', availablePoints: 0 },
	{ formId: 88, question: 'Did you eliminate red meat?', availablePoints: 3 },
	{
		formId: 89,
		question: 'Serve local, seasonal, organic and/or sustainably grown food.',
		availablePoints: 0
	},
	{
		formId: 90,
		question:
			'Do not purchase and “Red List” seafood. For guidance in North America, visit SeafoodWatch.org or in the United Kingdom visit www.GoodFishGuide.org.',
		availablePoints: 0
	},
	{
		formId: 91,
		question: 'Use coffee from environmentally preferable sources (fair trade/shade grown). Avoid “pod” single-use coffee and use reusable filters.',
		availablePoints: 1
	},
	{ formId: 92, question: 'Donate all edible leftover food to local banks and charities.', availablePoints: 0 },
	{
		formId: 93,
		question: 'Was food that cannot be donated composted? If yes, include the waste vendor name.',
		availablePoints: 2
	},
	{
		formId: 94,
		question: 'Were recycling bins with lids and clear signs provided?',
		availablePoints: 2
	},
	{ formId: 95, question: 'Flatten and recycle all cardboard.', availablePoints: 0 },
	{
		formId: 96,
		question: 'Use energy-efficient practices such as unplugging small appliances and choosing propane over charcoal/wood grills.',
		availablePoints: 0
	},
	{
		formId: 97,
		question: 'Prevent grease, oil, or other chemicals from entering drains and waterways.',
		availablePoints: 0
	},
	{
		formId: 98,
		question: 'Use environmentally friendly cleaning products (biodegradable, non-toxic, plant-based, Green Seal certified).',
		availablePoints: 0
	},
	{
		formId: 99,
		question: 'Use trash bags and liners made with recycled or biodegradable materials.',
		availablePoints: 0
	},
	{
		formId: 100,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{ formId: 101, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 102,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsElectric = [
	{
		formId: 103,
		question: 'Use energy-efficient work lights (e.g. CFL, LED, or induction lighting).',
		availablePoints: 0
	},
	{
		formId: 104,
		question: 'Was at least 30% of your lighting package energy-efficient set lighting such as LEDs? If yes, confirm who provided the percentage.',
		availablePoints: 5
	},
	{
		formId: 105,
		question: 'Save the lights by dimming down in between takes and shut off when not in use. When prelight is complete, power down.',
		availablePoints: 0
	},
	{ formId: 106, question: 'Recycle plastic lighting gels with mixed plastics.', availablePoints: 0 },
	{ formId: 107, question: 'Use low VOC adhesives.', availablePoints: 0 },
	{
		formId: 108,
		question: 'Recycle used batteries following safe handling procedures. Store temporarily in one-gallon plastic pails with a lid.',
		availablePoints: 0
	},
	{ formId: 109, question: 'Reuse and donate expendables.', availablePoints: 0 },
	{
		formId: 110,
		question: 'On location, did you utilize house power or tie into the grid instead of using generators? If yes, provide examples.',
		availablePoints: 5
	},
	{
		formId: 111,
		question: 'Did you use electric generators or battery packs? If yes, include vendor name.',
		availablePoints: 2
	},
	{
		formId: 112,
		question: 'Did you keep elephant doors closed when AC is turned on and turn off power, HVAC, catwalk, and house lights when not needed?',
		availablePoints: 1
	},
	{
		formId: 113,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{ formId: 114, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 115,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsGreens = [
	{
		formId: 116,
		question: 'Preserve the local environment - do not disturb existing flora or fauna or introduce a foreign plant species into a new area.',
		availablePoints: 0
	},
	{ formId: 117, question: 'Rent greens before purchasing live plants.', availablePoints: 0 },
	{
		formId: 118,
		question: 'If greens must be purchased, make a preference for live plants unless synthetics will be reused multiple times.',
		availablePoints: 0
	},
	{
		formId: 119,
		question: 'Obtain live material from existing tree trimming or removal projects.',
		availablePoints: 0
	},
	{
		formId: 120,
		question: 'If purchasing plants, utilize those grown organically and pesticide-free. Use organic mulch and soils.',
		availablePoints: 0
	},
	{
		formId: 121,
		question: 'Were live plants donated to local organizations? If yes, include recipient(s) and plants donated.',
		availablePoints: 1
	},
	{
		formId: 122,
		question: 'Was green waste separated and sent for composting? If yes, include compost vendor.',
		availablePoints: 1
	},
	{
		formId: 123,
		question:
			'Was a climate-compatible landscape design chosen for the scripted location (e.g., drought-resistant species in Southern California)? If yes, provide an example.',
		availablePoints: 3
	},
	{
		formId: 124,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{ formId: 125, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 126,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsGrip = [
	{
		formId: 127,
		question: 'Did you keep elephant doors closed when AC is on and turn off power, HVAC, catwalk, and house lights when not needed?',
		availablePoints: 1
	},
	{
		formId: 128,
		question: 'Use adhesives made from natural, nontoxic materials (no petroleum-based products).',
		availablePoints: 0
	},
	{
		formId: 129,
		question: 'Were expendables donated, repurposed, or returned to the supplier? If yes, please explain.',
		availablePoints: 1
	},
	{
		formId: 130,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{ formId: 131, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 132,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsHair = [
	{
		formId: 133,
		question: 'Were cruelty-free, non-toxic, and organic products selected? Reference EWG’s Skin Deep guide or Campaign for Safe Cosmetics.',
		availablePoints: 1
	},
	{
		formId: 134,
		question: 'Use refills to avoid disposing of non-recyclable makeup containers.',
		availablePoints: 0
	},
	{
		formId: 135,
		question: 'Did you avoid the use of ozone-depleting aerosols and petroleum-based synthetic chemicals (mineral oil, sulfates, etc)?',
		availablePoints: 1
	},
	{
		formId: 136,
		question: 'Purchase products that can be refilled and look for products with recyclable or biodegradable packaging.',
		availablePoints: 0
	},
	{
		formId: 137,
		question: 'Take aerosol cans to the hazardous waste disposal area (usually at Paint station in Construction).',
		availablePoints: 0
	},
	{
		formId: 138,
		question: 'Place clean, recyclable packaging and product containers in plastic/bottles recycling bin.',
		availablePoints: 0
	},
	{
		formId: 139,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{ formId: 140, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 141,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsLocations = [
	{
		formId: 142,
		question: 'Protect and preserve all locations. If shooting in a park or sensitive location, see Guidelines for Sensitive Locations document.',
		availablePoints: 0
	},
	{
		formId: 143,
		question: 'Use locations protection material made from recycled material and/or reusable mats and protectors.',
		availablePoints: 0
	},
	{
		formId: 144,
		question: 'Did you ensure recyclables were collected at each location? If yes, include waste vendor name.',
		availablePoints: 4
	},
	{
		formId: 145,
		question: 'Was compost collected on location? If yes, include compost vendor name.',
		availablePoints: 5
	},
	{
		formId: 146,
		question: 'Ask for a diversion report for all recycled, composted, and landfill material.',
		availablePoints: 0
	},
	{
		formId: 147,
		question: 'Did you use electric, hybrid, or fuel-efficient vehicles for scouting (at least 36mpg or higher)?',
		availablePoints: 1
	},
	{ formId: 148, question: 'Rent solar-powered portable restrooms.', availablePoints: 0 },
	{ formId: 149, question: 'Include public transit information on location maps.', availablePoints: 0 },
	{
		formId: 150,
		question: 'Did you use non-toxic cleaning supplies for all location clean-up?',
		availablePoints: 1
	},
	{ formId: 151, question: 'Use energy-efficient HVAC units.', availablePoints: 0 },
	{
		formId: 152,
		question: 'Turn off light towers and their generators once the sun has come up or you no longer need them.',
		availablePoints: 0
	},
	{
		formId: 153,
		question: 'Were clean or alternate fuels used for heaters (e.g., propane/biodiesel)? If yes, include vendor and product name.',
		availablePoints: 1
	},
	{
		formId: 154,
		question: 'Logistically support environmental efforts (e.g., movement or set up of recycling or compost bins/water refilling stations).',
		availablePoints: 0
	},
	{
		formId: 155,
		question:
			'Make a clean sweep of the area to ensure that nothing is left behind, including equipment, garbage, resident letters, parking signs, and directional signs.',
		availablePoints: 0
	},
	{
		formId: 156,
		question:
			'Ensure that sufficient waste and recycling bins are placed in the staging area close to camera, craft service tables, base camp, catering area, shop and construction spaces, and extras holding areas.',
		availablePoints: 0
	},
	{
		formId: 157,
		question:
			'Evaluate options for base camp and crew parking. Select those locations that are closest in order to reduce fuel use from shuttles and those that may have adequate power so generators are not needed.',
		availablePoints: 0
	},
	{
		formId: 158,
		question: 'Turn off power, HVAC, catwalk, and house lights when not in use and before leaving. Close elephant doors when the air conditioning is on.',
		availablePoints: 0
	},
	{
		formId: 159,
		question: 'Use only elastic bands or string to hang signs on trees - never staples, thumbtacks, or tape, all of which can severely damage trees.',
		availablePoints: 0
	},
	{ formId: 160, question: 'Make all signage out of recycled or recyclable materials.', availablePoints: 0 },
	{
		formId: 161,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{ formId: 162, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 163,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsMakeup = [
	{
		formId: 164,
		question: 'Were cruelty-free, non-toxic, and organic products selected? Reference EWG’s Skin Deep guide or Campaign for Safe Cosmetics.',
		availablePoints: 1
	},
	{
		formId: 165,
		question: 'Use refills to avoid disposing of non-recyclable makeup containers.',
		availablePoints: 0
	},
	{
		formId: 166,
		question: 'Did you avoid the use of ozone-depleting aerosols and petroleum-based synthetic chemicals (mineral oil, sulfates, etc)?',
		availablePoints: 0
	},
	{
		formId: 167,
		question: 'Purchase products that can be refilled and look for products with recyclable or biodegradable packaging.',
		availablePoints: 0
	},
	{
		formId: 168,
		question: 'Take aerosol cans to the hazardous waste disposal area (usually at Paint station in Construction).',
		availablePoints: 0
	},
	{ formId: 169, question: 'Did you recycle cosmetic packaging and product containers?', availablePoints: 1 },
	{
		formId: 170,
		question: 'For prosthetics, did you use cruelty-free, non-toxic products and adhesives?',
		availablePoints: 1
	},
	{
		formId: 171,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{ formId: 172, question: 'Search for new green vendors at GreenProductionGuide.com', availablePoints: 0 },
	{
		formId: 173,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];

const questionsProductionSustainablePractices = [
	{
		formId: 175,
		question: 'Did you set goals and sustainability memos that communicate your sustainability objectives to cast and crew?',
		availablePoints: 2
	},
	{
		formId: 176,
		question:
			'Identify the senior person accountable for sustainability (Line Producer, UPM, or Production Supervisor) and who is responsible for implementing agreed-upon sustainability goals (POC, APOC, or Environmental Representative).',
		availablePoints: 0
	},
	{
		formId: 177,
		question: 'Work with department heads to incorporate sustainability behaviors and messaging on screen.',
		availablePoints: 0
	},
	{
		formId: 178,
		question:
			'Have writers incorporated dialogue or action that portrays or advocates for environmental responsibility? If yes, briefly describe that storyline and include scene or episode number(s) if applicable.',
		availablePoints: 5
	},
	{ formId: 179, question: 'Purchase carbon offsets.', availablePoints: 0 },
	{
		formId: 180,
		question:
			'Did you participate in a community give-back project? (e.g., in-person or virtual volunteerism, tree planting, food drive). If yes, please explain. Does not include catering food donations or material donations.',
		availablePoints: 3
	},
	{
		formId: 181,
		question: 'Ask your production office facility contact if there are any environmental policies or practices in place.',
		availablePoints: 0
	},
	{
		formId: 182,
		question: 'Place visual reminders around offices and workspaces to remind staff and crew to reduce, reuse, and recycle.',
		availablePoints: 0
	},
	{ formId: 183, question: 'Use bicycle couriers.', availablePoints: 0 },
	{
		formId: 184,
		question: 'Ask cast, crew, and suppliers for feedback on sustainable production initiatives.',
		availablePoints: 0
	}
];
const questionsProductionProductsSuppliers = [
	{
		formId: 186,
		question: 'Contact your suppliers and ask if they have an environmental policy, and if they provide green products or services.',
		availablePoints: 0
	},
	{
		formId: 187,
		question: 'Incorporate catering best practices into catering contract.',
		availablePoints: 0
	},
	{
		formId: 188,
		question:
			'Use non-toxic cleaning products (e.g., Green Seal or EPA’s Design for the Environment certified and use paper products (including paper towels, toilet paper, and tissues) that are bleach-free and made with recycled content paper.',
		availablePoints: 0
	},
	{
		formId: 189,
		question: 'Did your colored paper contain at least 30% recycled content?',
		availablePoints: 1
	},
	{
		formId: 190,
		question: 'Did your white copy paper contain 100% recycled content? If yes, include vendor and product name.',
		availablePoints: 3
	},
	{
		formId: 191,
		question: 'Purchase office and food supplies in bulk to reduce packaging.',
		availablePoints: 0
	},
	{
		formId: 192,
		question: 'Did you eliminate coffee pods or return them through a take-back program?',
		availablePoints: 1
	},
	{
		formId: 193,
		question: 'Buy re-manufactured or soy-based ink toner cartridges.',
		availablePoints: 0
	},
	{
		formId: 194,
		question: 'If purchasing swag for crew, choose environmentally friendly gifts (e.g., certified organic apparel and water bottles to have on set.',
		availablePoints: 0
	},
	{
		formId: 195,
		question: 'Reuse lanyards and visitor badges, purchase materials made from recycled content.',
		availablePoints: 0
	},
	{
		formId: 196,
		question: 'Purchase from local businesses to support the community and cut down on emissions from delivery.',
		availablePoints: 0
	},
	{
		formId: 197,
		question:
			'Hire caterers who offer local and USDA organic produce, humanely raised meat and dairy, and vegan/ vegetarian options and incorporate catering best practices into catering contract.',
		availablePoints: 0
	},
	{
		formId: 198,
		question:
			'Use reusable crates for delivery and reusable bags for shopping and ask suppliers to take back packaging for larger items such as computers and furniture.',
		availablePoints: 0
	},
	{
		formId: 199,
		question: 'Order recycled-content office supplies whenever possible or obtain used office supplies,',
		availablePoints: 0
	},
	{
		formId: 200,
		question: 'Search for new green vendors at GreenProductionGuide.com',
		availablePoints: 0
	}
];
const questionsProductionEnergy = [
	{
		formId: 202,
		question: 'Did someone turn off lights and equipment at the end of each day?',
		availablePoints: 1
	},
	{ formId: 203, question: 'Do not use space heaters.', availablePoints: 0 },
	{
		formId: 204,
		question: 'Use Energy Star compliant (or equivalent) electronics, aim for at least 50%.',
		availablePoints: 0
	},
	{ formId: 205, question: 'Turn off power strips and unplug chargers when not in use.', availablePoints: 0 },
	{ formId: 206, question: 'Turn on power-saving settings for all electronics.', availablePoints: 0 },
	{
		formId: 207,
		question: 'Sign up for renewable energy program through your local utility,',
		availablePoints: 0
	},
	{
		formId: 208,
		question: 'Use fans and open windows to minimize air conditioner use. When the air conditioner is on be sure to keep doors and windows closed.',
		availablePoints: 0
	}
];
const questionsProductionWasteRecycling = [
	{
		formId: 210,
		question: 'Use paperless software solutions for digital distribution, start packets, and accounting documents.',
		availablePoints: 0
	},
	{
		formId: 211,
		question: 'Was paperwork defaulted to digital distribution, and did you implement an opt-in policy for hard copies?',
		availablePoints: 1
	},
	{
		formId: 212,
		question:
			'If printing is required, print only the updated sheets required and print double-sided. Set all printers to default to double-sided printing,',
		availablePoints: 0
	},
	{
		formId: 213,
		question: 'Choose a font that saves printer ink, such as Century Gothic, Garamond, Courier, and Times New Roman.',
		availablePoints: 0
	},
	{
		formId: 214,
		question: 'Have you implemented an office recycling program? If yes, list recycling vendor used.',
		availablePoints: 1
	},
	{
		formId: 215,
		question: 'Did you track waste and request diversion/recycling reports? If yes, include reports with this checklist.',
		availablePoints: 1
	},
	{
		formId: 216,
		question: 'Was office food waste collected for composting? If yes, list composting vendor used.',
		availablePoints: 3
	},
	{
		formId: 217,
		question: 'Did your office recycling program include harder to recycle items (e.g., media, batteries, and ink cartridges)?',
		availablePoints: 1
	},
	{
		formId: 218,
		question:
			'Coordinate with locations, craft service, and catering to create a responsible waste management plan that includes on-set recycling and, where applicable, composting.',
		availablePoints: 0
	},
	{
		formId: 219,
		question: 'Use common area recycling/compost and landfill bins. Reduce or eliminate individual trash cans at workstations to reduce plastic liner use.',
		availablePoints: 0
	},
	{
		formId: 220,
		question: 'Donate items that are no longer needed to a vetted local organization or another production,',
		availablePoints: 0
	},
	{
		formId: 221,
		question: 'Did you eliminate #6 polystyrene plastic products (aka styrofoam) and use reusable dishes and cutlery (personal or provided)?',
		availablePoints: 1
	},
	{
		formId: 222,
		question: 'Were single-use plastic water bottles eliminated in offices and replaced with a water dispenser?',
		availablePoints: 1
	},
	{
		formId: 223,
		question: 'Purchase reusable water bottles for all cast and crew? Or ask them to bring their own.',
		availablePoints: 0
	},
	{
		formId: 224,
		question: 'Order lunches family style to reduce individual packaging waste.',
		availablePoints: 0
	},
	{
		formId: 225,
		question: 'Donate all edible leftover food to local food banks and charities.',
		availablePoints: 0
	}
];
const questionsProductionTransportationTravel = [
	{
		formId: 227,
		question: 'Encourage staff and crew to use public transportation or carpool by providing information on options and offering incentives.',
		availablePoints: 0
	},
	{ formId: 228, question: 'Make bicycles available for short trips.', availablePoints: 0 },
	{
		formId: 229,
		question: 'Place office run routes efficiently to reduce emissions.',
		availablePoints: 0
	},
	{
		formId: 230,
		question: 'Enforce mandatory no idling rules for all vehicles and adhere to local ordinances regarding idling rules.',
		availablePoints: 0
	},
	{
		formId: 231,
		question: 'Reduce air travel by using alternative means such as trains, buses, and video conferencing.',
		availablePoints: 0
	},
	{ formId: 232, question: 'Hire local crew to avoid travel and accommodations.', availablePoints: 0 },
	{
		formId: 233,
		question: 'Work with your travel agent or airline to purchase carbon offsets for air travel.',
		availablePoints: 0
	},
	{
		formId: 234,
		question: 'Choose hotels with a clearly stated and implemented environmental program, and remind crew and cast to take advantage of programs.',
		availablePoints: 0
	},
	{
		formId: 235,
		question: 'For long-term hotel stays, ask that rooms be serviced less frequently.',
		availablePoints: 0
	},
	{
		formId: 236,
		question: 'Track accommodations and air/rail travel for carbon reporting.',
		availablePoints: 0
	},
	{
		formId: 237,
		question: 'Use stock or archive footage to limit location, establishing, or aerial shots.',
		availablePoints: 0
	},
	{
		formId: 238,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 3
	}
];
const questionsProduction = [
	{
		title: 'Sustainable Practices',
		formId: 174,
		sectionId: 'sustainablePractices',
		questions: questionsProductionSustainablePractices
	},
	{
		title: 'Products & Suppliers',
		formId: 185,
		sectionId: 'productsSuppliers',
		questions: questionsProductionProductsSuppliers
	},
	{
		title: 'Energy',
		formId: 201,
		sectionId: 'energy',
		questions: questionsProductionEnergy
	},
	{
		title: 'Waste & Recycling',
		formId: 209,
		sectionId: 'wasteRecycling',
		questions: questionsProductionWasteRecycling
	},
	{
		title: 'Transportation & Travel',
		formId: 226,
		sectionId: 'transportationTravel',
		questions: questionsProductionTransportationTravel
	}
];
const questionsProps = [
	{
		formId: 239,
		question: 'On screen, did you incorporate sustainable behaviors (e.g., reusable water bottles and shopping bags). If yes, provide examples.',
		availablePoints: 5
	},
	{
		formId: 240,
		question: 'Did you rent or purchase used items and, for new items, chose environmentally responsible brands or products?',
		availablePoints: 1
	},
	{
		formId: 241,
		question: 'Use low or no-VOC adhesives and sprays and dispose of hazardous and regulated materials properly.',
		availablePoints: 0
	},
	{
		formId: 242,
		question: 'Use non-toxic, biodegradable cleaners.',
		availablePoints: 0
	},
	{
		formId: 243,
		question:
			'Were props, materials, and properly stored food donated to local charities at the end of production? If yes, include recipient(s) and items donated.',
		availablePoints: 2
	},
	{
		formId: 244,
		question: 'Turn off power, HVAC, catwalk, and house lights when not in use and before leaving. Close elephant doors when the air conditioning is on.',
		availablePoints: 0
	},
	{
		formId: 245,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{
		formId: 246,
		question: 'Search for new green vendors at GreenProductionGuide.com',
		availablePoints: 0
	},
	{
		formId: 247,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsSetDecoration = [
	{
		formId: 248,
		question:
			'On screen, did you incorporate sustainable behaviors (e.g., recycling bins, environmental themed messaging in background). If yes, provide examples.',
		availablePoints: 5
	},
	{
		formId: 249,
		question: 'Did you rent or purchase used items and, for new items, chose environmentally responsible brands or products?',
		availablePoints: 1
	},
	{
		formId: 250,
		question: 'Use low or no-VOC adhesives and sprays and dispose of hazardous and regulated materials properly.',
		availablePoints: 0
	},
	{
		formId: 251,
		question: 'Use non-toxic, biodegradable cleaners.',
		availablePoints: 0
	},
	{
		formId: 252,
		question:
			'Were materials and properly stored food donated to local charities at the end of production? If yes, include recipient(s) and items donated.',
		availablePoints: 2
	},
	{
		formId: 253,
		question: 'Turn off power, HVAC, catwalk, and house lights when not in use and before leaving. Close elephant doors when the air conditioning is on.',
		availablePoints: 0
	},
	{
		formId: 254,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{
		formId: 255,
		question: 'Search for new green vendors at GreenProductionGuide.com',
		availablePoints: 0
	},
	{
		formId: 256,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsSound = [
	{
		formId: 257,
		question: 'Were rechargeable batteries used in headsets and comteks?',
		availablePoints: 1
	},
	{
		formId: 258,
		question: 'Were rechargeable batteries used in microphone transmitters? If yes, provide make and model.',
		availablePoints: 3
	},
	{
		formId: 259,
		question:
			'Use a battery voltage tester to reduce disposing of batteries that have not been fully drained. Use partial batteries for other non-critical uses before disposing.',
		availablePoints: 0
	},
	{
		formId: 260,
		question: 'Did you properly recycle batteries?',
		availablePoints: 1
	},
	{
		formId: 261,
		question: 'Turn off power, HVAC, catwalk, and house lights when not in use and before leaving. Close elephant doors when the air conditioning is on.',
		availablePoints: 0
	},
	{
		formId: 262,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{
		formId: 263,
		question: 'Search for new green vendors at GreenProductionGuide.com',
		availablePoints: 0
	},
	{
		formId: 264,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsSpecialEffects = [
	{
		formId: 265,
		question:
			'Always protect the environment before an effect and clean up afterwards. Firefighting equipment, clean up equipment, and a spill kit must be kept on hand.',
		availablePoints: 0
	},
	{
		formId: 266,
		question: 'Avoid burning toxic materials such as plastic, rubber, diesel fuel, etc.',
		availablePoints: 0
	},
	{
		formId: 267,
		question: 'Was propane used instead of liquid fuel for fire effects?',
		availablePoints: 1
	},
	{
		formId: 268,
		question: 'Did you use only water-based smoke fluids?',
		availablePoints: 1
	},
	{
		formId: 269,
		question: 'Did you use biodegradable snow products?',
		availablePoints: 1
	},
	{
		formId: 270,
		question: 'Avoid using dust effects that contain known carcinogens.',
		availablePoints: 0
	},
	{
		formId: 271,
		question: 'Care should be taken when utilizing wind and rain effects, so as not to damage any sensitive or pristine environment.',
		availablePoints: 0
	},
	{
		formId: 272,
		question: 'Limit the amount of hazardous materials purchased, used, and stored on location.',
		availablePoints: 0
	},
	{
		formId: 273,
		question: 'Did you use digital SFX and VFX when possible instead of practical?',
		availablePoints: 1
	},
	{
		formId: 274,
		question: 'Turn off power, HVAC, catwalk, and house lights when not in use and before leaving? Close elephant doors when the air conditioning is on.',
		availablePoints: 0
	},
	{
		formId: 275,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{
		formId: 276,
		question: 'Search for new green vendors at GreenProductionGuide.com',
		availablePoints: 0
	},
	{
		formId: 277,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];
const questionsTransportation = [
	{
		formId: 278,
		question: 'Did you enforce a mandatory no idling policy?',
		availablePoints: 1
	},
	{
		formId: 279,
		question: 'Identify a shaded or sheltered area for drivers to wait outside of their vehicles.',
		availablePoints: 0
	},
	{
		formId: 280,
		question:
			'Were low-carbon fuels such as biodiesel (B20 or greater) or renewable diesel used in generators and trucks? If yes, include vendor and/or product name.',
		availablePoints: 5
	},
	{
		formId: 281,
		question: 'Did you rent electric, hybrid, or alternative fuel vehicles and equipment? If yes, include supplier and amount rented.',
		availablePoints: 3
	},
	{
		formId: 282,
		question: 'For base camp, did you tie into the electric grid?',
		availablePoints: 4
	},
	{
		formId: 283,
		question: 'Turn off generators when not in use and power down trailers at wrap.',
		availablePoints: 0
	},
	{
		formId: 284,
		question: 'Were generators Tier 3 or have catalyzed particulate filters?',
		availablePoints: 1
	},
	{
		formId: 285,
		question: 'Practice responsible refueling; refuel in contained or bounded areas, use drip trays or ground sheets.',
		availablePoints: 0
	},
	{
		formId: 286,
		question: 'Did you combine trailers, and limit or right size equipment.',
		availablePoints: 2
	},
	{
		formId: 287,
		question: 'Turn off air conditioning in unoccupied trailers.',
		availablePoints: 0
	},
	{
		formId: 288,
		question: 'Use trailers that are optimized for energy efficiency and built with sustainable materials.',
		availablePoints: 0
	},
	{
		formId: 289,
		question: 'Did you use solar powered trailers that can run without tying in or using generators? If yes, include vendor, make, and model.',
		availablePoints: 5
	},
	{
		formId: 290,
		question: 'Use re-refined motor oil and dispose of properly.',
		availablePoints: 0
	},
	{
		formId: 291,
		question: 'Was shuttle service provided to condense drives or make public transportation feasible?',
		availablePoints: 1
	},
	{
		formId: 292,
		question: 'Were electric, hybrid or alternate fuel picture cars used? If yes, please explain and provide scene/ episode.',
		availablePoints: 3
	},
	{
		formId: 293,
		question: 'Logistically support environmental efforts (e.g., movement or recycling or compost bins/ water refilling stations.)',
		availablePoints: 0
	},
	{
		formId: 294,
		question: 'Ask other departments to coordinate orders with the goal of reducing truck trips.',
		availablePoints: 0
	},
	{
		formId: 295,
		question: 'Use non-toxic cleaning products in trailers (e.g., Green Seal or EPA’s Design for the Environment certified).',
		availablePoints: 0
	},
	{
		formId: 296,
		question: 'Follow green practices; reduce, reuse, recycle, and shut down (e.g., reduce paper, refill water bottles, shut down equipment and lights).',
		availablePoints: 0
	},
	{
		formId: 297,
		question: 'Search for new green vendors at GreenProductionGuide.com',
		availablePoints: 0
	},
	{
		formId: 298,
		question: 'Did you implement additional green practices? If yes, please explain.',
		availablePoints: 0
	}
];

export const categories = [
	{ formId: 299, id: 0, title: 'Accounting', name: 'accounting', questions: questionsAccounting },
	{ formId: 300, id: 1, title: 'Art', name: 'art', questions: questionsArt },
	{ formId: 301, id: 2, title: 'Assistant Directors', name: 'assistant-directors', questions: questionsAssistantDirectors },
	{ formId: 302, id: 3, title: 'Camera', name: 'camera', questions: questionsCamera },
	{ formId: 303, id: 4, title: 'Catering', name: 'catering', questions: questionsCatering },
	{ formId: 304, id: 5, title: 'Construction', name: 'construction', questions: questionsConstruction },
	{ formId: 305, id: 6, title: 'Costume/Wardrobe', name: 'costume-wardrobe', questions: questionsCostume },
	{ formId: 306, id: 7, title: 'Craft Service', name: 'craft-service', questions: questionsCraft },
	{ formId: 307, id: 8, title: 'Electric', name: 'electric', questions: questionsElectric },
	{ formId: 308, id: 9, title: 'Greens', name: 'greens', questions: questionsGreens },
	{ formId: 309, id: 10, title: 'Grip', name: 'grip', questions: questionsGrip },
	{ formId: 310, id: 11, title: 'Hair', name: 'hair', questions: questionsHair },
	{ formId: 311, id: 12, title: 'Locations', name: 'locations', questions: questionsLocations },
	{ formId: 312, id: 13, title: 'Make-up', name: 'make-up', questions: questionsMakeup },
	{ formId: 313, id: 14, title: 'Production', name: 'production', questions: questionsProduction },
	{ formId: 314, id: 15, title: 'Props', name: 'props', questions: questionsProps },
	{ formId: 315, id: 16, title: 'Set Decoration', name: 'set-decoration', questions: questionsSetDecoration },
	{ formId: 316, id: 17, title: 'Special Effects', name: 'special-effects', questions: questionsSpecialEffects },
	{ formId: 317, id: 18, title: 'Sound', name: 'sound', questions: questionsSound },
	{ formId: 318, id: 19, title: 'Transportation', name: 'transportation', questions: questionsTransportation }
];

export const allQuestions = [
	...questionsAccounting,
	...questionsArt,
	...questionsAssistantDirectors,
	...questionsCamera,
	...questionsCatering,
	...questionsConstruction,
	...questionsCostume,
	...questionsCraft,
	...questionsElectric,
	...questionsGreens,
	...questionsGrip,
	...questionsHair,
	...questionsHair,
	...questionsLocations,
	...questionsMakeup,
	...questionsProductionEnergy,
	...questionsProductionProductsSuppliers,
	...questionsProductionSustainablePractices,
	...questionsProductionTransportationTravel,
	...questionsProductionWasteRecycling,
	...questionsProps,
	...questionsSetDecoration,
	...questionsSound,
	...questionsSpecialEffects,
	...questionsTransportation
];

export const peachStoreStatusMap = {
	0: { value: 'No', id: 11 },
	1: { value: 'Yes', id: 12 },
	2: { value: 'N/A', id: 13 }
};
