import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersist from 'vuex-persist';
import VuexPersistence from 'vuex-persist';
import localforage from 'localforage';

// --------------------------------
// init store
// --------------------------------
let stores = {};
let storeModules = {};

const modules = require.context('./modules', false, /\.module\.js$/);
const moduleName = (string) => {
	return string.replace('.module.js', '').replace('./', '');
};

modules.keys().forEach((key) => (stores[key] = modules(key)));

for (let store in stores) {
	for (let j in stores[store]) {
		storeModules[moduleName(store)] = stores[store][j];
	}
}

Vue.use(Vuex);

// --------------------------------
// managing errors
// --------------------------------
const state = {
	error: {},
	auth: null
};

const getters = {
	error: (state) => state.error,
	auth: (state) => state.auth
};

const mutations = {
	SET_ERROR(state, error) {
		state.error = error;
	},
	RESET_ERROR(state, resetError) {
		state.error = resetError;
	},

	SET_AUTH(state, user) {
		state.auth = Object.assign({}, state.auth, user);
	}
};

const actions = {
	//Set thes errors in state
	throwError({ commit }, error) {
		commit('SET_ERROR', error);
	},
	//Empty the state error
	resetError({ commit }) {
		const resetError = {};
		commit('RESET_ERROR', resetError);
	},

	setAuthUser({ commit }, profile) {
		commit('SET_AUTH', profile);
	}
};

// const vuexLocalStorage = new VuexPersist({
// 	key: 'vuex',
// 	storage: window.localStorage
// });

// implementation of a module that persists the store.
const vuexPersist = new VuexPersistence({
	key: 'vuex',
	storage: localforage,
	asyncStorage: true
});

export default new Vuex.Store({
	state,
	getters,
	actions,
	mutations,
	modules: {
		...storeModules
	},
	plugins: [vuexPersist.plugin]
});
