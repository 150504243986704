import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'SuggestChromeMixin',

	mixins: [GlobalMixin],

	computed: {
		showSuggestChrome() {
			if (!this.isPwa() && !this.isChrome()) {
				if (this.$cookies.isKey('ck_suggest_chrome')) {
					return false;
				} else {
					this.$cookies.set('ck_suggest_chrome', true, '2d');
					return true;
				}
			}
			return false;
		}
	},

	methods: {
		isChrome(opts) {
			opts = opts || {};

			if ((!opts.userAgent && typeof navigator === 'undefined') || /(Chromium|OPR|Opera)/.test(opts.userAgent || navigator.userAgent)) {
				return false;
			}

			const reg = new RegExp(/(Chrome)\/((\d+)?[\w\.]+)/);
			const arr = reg.exec(opts.userAgent || navigator.userAgent);

			if (!arr || (opts.version && !this.arrayContains(arr, opts.version))) {
				return false;
			}

			return true;
		},
		arrayContains(arrays, array) {
			var hash = {};

			for (var key in arrays) {
				hash[arrays[key]] = key;
			}

			return hash.hasOwnProperty(array);
		}
	}
};
