import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

const fieldResult = `
    id
    createdAt
    updatedAt
    projectId
    userId
    filterName
    filterType
    isActive
    box
    zip
    state
    city
    country
    category
    userDepartment
    department
    userFunction
    startDate
    endDate
    personId
    supplierId
    status
    type
    recordLimit
    recordOffSet
    perPage
	flagIds {
		flagId
	}
`;

export const addUpdAdvancedGlobalFilter = async (inputFilter, filterId = 0) => {
	try {
		const MUTATION_ADD_UPD_ADVANCED_GLOBAL_FILTER = gql`
            mutation ($filterId: ID!, $inputFilter: AdvancedGlobalFilterInput!) {
                AddUpdAdvancedGlobalFilter(FilterId: $filterId, Filter: $inputFilter) {
                    ${fieldResult}
                }
            }
        `;

		const {
			data: { AddUpdAdvancedGlobalFilter }
		} = await apollo.mutate({
			mutation: MUTATION_ADD_UPD_ADVANCED_GLOBAL_FILTER,
			variables: {
				filterId: parseInt(filterId),
				inputFilter
			}
		});

		return AddUpdAdvancedGlobalFilter;
	} catch (error) {
		console.log(error);
	}
};

export const getAdvancedGlobalFilter = async (mainFilter = true, filterType = 0) => {
	try {
		const QUERY_GET_ADVANCED_GLOBAL_FILTER = gql`
            query ($mainFilter: Boolean, $filterType: ID!) {
                GetAdvancedGlobalFilter(MainFilter: $mainFilter, FilterType: $filterType) {
                    ${fieldResult}
                }
            }
        `;

		const {
			data: { GetAdvancedGlobalFilter }
		} = await apollo.mutate({
			mutation: QUERY_GET_ADVANCED_GLOBAL_FILTER,
			variables: {
				mainFilter,
				filterType
			},
			fetchPolicy: 'no-cache'
		});

		return GetAdvancedGlobalFilter;
	} catch (error) {
		console.log(error);
	}
};

export const deleteAdvancedGlobalFilter = async (filterId) => {
	try {
		const MUTATION_DELETE_ADVANCED_GLOBAL_FILTER = gql`
			mutation ($filterId: ID!) {
				DeleteAdvancedGlobalFilter(FilterId: $filterId)
			}
		`;

		const {
			data: { DeleteAdvancedGlobalFilter }
		} = await apollo.mutate({
			mutation: MUTATION_DELETE_ADVANCED_GLOBAL_FILTER,
			variables: {
				filterId: parseInt(filterId)
			}
		});

		return DeleteAdvancedGlobalFilter;
	} catch (error) {
		console.log(error);
	}
};
