import _ from 'lodash';
import { addUpdPayCode, getPayCode, getPayCodes, delPayCode } from '@/cruds/payroll-code.crud';

const state = () => ({
	payrollCodes: [],
	currentPayroolCode: null
});

const getters = {
	payrollCodes: (state) => state.payrollCodes,
	currentPayroolCode: (state) => state.currentPayroolCode
};

const mutations = {
	SET_PAYROLL_CODES(state, listPayroll) {
		let pCodesSorted = sortPCodes(listPayroll);
		state.payrollCodes = pCodesSorted;
	},
	ADD_PAYROLL_CODE(state, payroll) {
		let pCodes = _.cloneDeep(state.payrollCodes);
		pCodes.push(payroll);
		state.payrollCodes = pCodes;
	},
	UPDATE_PAYROLL_CODE(state, payroll) {
		let pCodes = _.cloneDeep(state.payrollCodes);
		const findIndex = pCodes.findIndex((pC) => pC.id === payroll.id);
		pCodes[findIndex] = payroll;
		let pCodesSorted = sortPCodes(pCodes);
		state.payrollCodes = pCodesSorted;
	},
	DELETE_PAYROLL_CODE(state, payrollId) {
		let pCodes = _.cloneDeep(state.payrollCodes);
		const findIndex = pCodes.findIndex((pC) => pC.id === payrollId);
		pCodes.splice(findIndex, 1);
		let pCodesSorted = sortPCodes(pCodes);
		state.payrollCodes = pCodesSorted;
	},
	SET_CURRENT_PAYROLL_CODE(state, pCode) {
		state.currentPayroolCode = pCode;
	}
};

const actions = {
	async getPayrollCodes({ commit }) {
		try {
			const result = await getPayCodes();

			commit('SET_PAYROLL_CODES', result);
		} catch (e) {
			console.log('STORE GET PAYROLL CODES ERROR', e);
		}
	},
	async addUpdPayrollCode({ commit }, { id, code, description }) {
		try {
			const result = await addUpdPayCode(id, { code, description });

			if (id === 0) {
				commit('ADD_PAYROLL_CODE', result);
				commit('SET_CURRENT_PAYROLL_CODE', result);
			} else {
				commit('UPDATE_PAYROLL_CODE', result);
			}
		} catch (e) {
			console.log('STORE ADD PAYROLL CODE', e);
		}
	},
	async deletePayrollCode({ commit, state }, id) {
		try {
			await delPayCode(id);
			commit('DELETE_PAYROLL_CODE', id);
		} catch (e) {
			console.log('STORE DELETE PAYROLL CODE', e);
		}
	}
};

const sortPCodes = (pCodes) => {
	const result = pCodes.sort(function compare(a, b) {
		if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
		if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
		return 0;
	});
	return result;
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
