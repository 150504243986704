import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

const RES_FIELD = `
	id
	createdAt
	updatedAt
	licenseId
	code
	description
`;

export const addUpdPayCode = async (payrollCodeId = 0, payrollCodeInput) => {
	try {
		const MUTATION_ADD_UPD_PAY_CODE = gql`
			mutation ($payrollCodeId: ID!, $payrollCodeInput: PayCodeInput!) {
				AddUpdPayCode(PayCodeId: $payrollCodeId, PayCode: $payrollCodeInput) {
					${RES_FIELD}
				}
			}
		`;

		const {
			data: { AddUpdPayCode }
		} = await apollo.mutate({
			mutation: MUTATION_ADD_UPD_PAY_CODE,
			variables: {
				payrollCodeId: +payrollCodeId,
				payrollCodeInput
			}
		});

		return AddUpdPayCode;
	} catch (e) {
		console.log(e.message);
	}
};

export const getPayCode = async (payrollCodeId) => {
	try {
		const QUERY_GET_PAY_CODE = gql`
			query ($payrollCodeId: ID!) {
				GetPayCode(PayCodeId: $payrollCodeId) {
					${RES_FIELD}
				}
			}
		`;

		const {
			data: { GetPayCode }
		} = await apollo.query({
			query: QUERY_GET_PAY_CODE,
			variables: {
				payrollCodeId: +payrollCodeId
			}
		});

		return GetPayCode;
	} catch (e) {
		console.log(e.message);
	}
};

export const getPayCodes = async () => {
	try {
		const QUERY_GET_PAY_CODES = gql`
		query {
			GetPayCodes {
				${RES_FIELD}
			}
		}
    `;

		const {
			data: { GetPayCodes }
		} = await apollo.query({
			query: QUERY_GET_PAY_CODES,
			fetchPolicy: 'network-only',
			nextFetchPolicy: 'cache-first'
		});

		return GetPayCodes;
	} catch (e) {
		console.log(e.message);
	}
};

export const delPayCode = async (payrollCodeId) => {
	try {
		const MUTATION_DEL_PAY_CODE = gql`
			mutation ($payrollCodeId: ID!) {
				DelPayCode(PayCodeId: $payrollCodeId)
			}
		`;

		const {
			data: { DelPayCode }
		} = await apollo.mutate({
			mutation: MUTATION_DEL_PAY_CODE,
			variables: {
				payrollCodeId: +payrollCodeId
			}
		});

		return DelPayCode;
	} catch (e) {
		console.log(e.message);
	}
};
