import jwtDataFunction from '@/middleware/jwtDataFunction';
import { isNil } from '@/shared/utils';

export default function canAccessOnboardingMiddleware({ next, router, localStore }) {
	let token = localStorage.getItem('jwt-token');
	const canAccessOnboarding = () => {
		if (isNil(jwtDataFunction(token).TokenData)) return false;
		const ret = jwtDataFunction(token).TokenData.CanAccessOnboarding;
		if (ret) {
			return ret;
		}
		return false;
	};

	if (!canAccessOnboarding()) {
		router.replace({ name: 'Page404' });
	}

	next();
}
