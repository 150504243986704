export const carbonRemovalRoutes = [
	{
		path: 'carbon-removal',
		meta: { label: 'Transports' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: '',
				meta: { label: 'Carbon removal' },
				component: () => import('@/modules/carbon-removal/views/index.vue'),
				name: 'carbonRemovalList'
			},
			{
				path: ':id',
				meta: { label: 'Carbon removal details' },
				name: 'carbonRemovalDetails',
				component: () => import('@/modules/carbon-removal/views/_id.vue')
			}
		]
	}
];
