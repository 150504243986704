import jwtDataFunction from './jwtDataFunction';
import { isNil } from '@/shared/utils';

export default function manageBudgetMiddleware({ next, router }) {
	let token = localStorage.getItem('jwt-token');
	const canAccessBudget = () => {
		if (isNil(jwtDataFunction(token).TokenData)) return false;
		const ret = jwtDataFunction(token).TokenData.CanViewGlobalBudget;
		if (ret) {
			return ret;
		}
		return false;
	};

	// const canAccessMyBudget = () => {
	// 	if (isNil(jwtDataFunction(token).TokenData)) return false;
	// 	const ret = jwtDataFunction(token).TokenData.CanAccessMyBudget;
	// 	if (ret) {
	// 		return ret;
	// 	}
	// 	return false;
	// };

	if (!canAccessBudget()) {
		router.replace({ name: 'Page404' });
	}

	next();
}
