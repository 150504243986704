import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';
import _ from 'lodash';

/**
 * get all projects
 * @param {String, Number} licenseId
 */

export const getAllProjects = async () => {
	const {
		data: { Projects }
	} = await apollo.query({
		query: gql`
			query {
				Projects {
					id
					licenseID
					titre
					production
					realisateur
					license {
						companyName
					}
				}
			}
		`,
		fetchPolicy: 'no-cache'
	});

	return Projects;
};
export const getProjects = async (licenseId = 0) => {
	try {
		let variables = {};

		if (!_.isNil(licenseId) && +licenseId) {
			variables = {
				...variables,
				licenseId: +licenseId
			};
		}
		const {
			data: { Projects }
		} = await apollo.query({
			query: gql`
				query ($licenseId: ID) {
					Projects(licenseID: $licenseId) {
						id
						licenseID
						titre
						production
						realisateur
					}
				}
			`,
			variables,
			fetchPolicy: 'no-cache'
		});

		return Projects;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * get one project
 * @param {String, Number} supplierID
 */
export const getProject = async (projectId) => {
	//console.log("getProject projectId:",projectId);
	if (isNil(projectId)) return;
	try {
		const { data } = await apollo.query({
			query: gql`
				query ($projectID: ID!) {
					Project(projectID: $projectID) {
						${fields}
					}
				}
			`,
			variables: {
				projectID: parseInt(projectId)
			},
			fetchPolicy: 'no-cache'
		});
		//console.log("end of Project:",data.Project);
		return data.Project;
	} catch (e) {
		console.log({ e });
	}
};

export const myProject = async () => {
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					myProject {
						${fields}
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});

		return data.myProject;
	} catch (e) {
		console.log({ e });
	}
};

export const getBudgetLock = async () => {
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					myProject {
						budgetLocked
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});

		return data.myProject;
	} catch (e) {
		console.log({ e });
	}
};

export const myProjects = async () => {
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					myProjects {
						${fields}
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});

		return data.myProjects;
	} catch (e) {
		console.log({ e });
	}
};

export const updMyProject = async (id, project, supplierIds = NonNullable) => {
	try {
		const mutation = gql`
			mutation ($projectId: ID!, $newProject: ProjectInput!, $supplierId: [ID]) {
				updMyProject(projectId: $projectId, newProject: $newProject, SupplierId: $supplierId)
			}
		`;

		let variables = {
			projectId: +id,
			newProject: project,
			supplierIds
		};

		const { data } = await apollo.mutate({
			mutation,
			variables
		});

		return data.updMyProject;
	} catch (e) {
		console.log({ e });
	}
};

const mutation_newPme = gql`
	mutation ($ProjectId: ID!, $PmeName: String!, $Filter: CopyProjectFilterInput) {
		NewPmeProject(ProjectId: $ProjectId, PmeName: $PmeName, Filter: $Filter) {
			id
		}
	}
`;
export const newSmeProject = async (id, smeName, filter) => {
	try {
		const mutation = gql`
			mutation ($ProjectId: ID!, $PmeName: String!, $Filter: CopyProjectFilterInput) {
				NewPmeProject(ProjectId: $ProjectId, PmeName: $PmeName, Filter: $Filter) {
					id
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				ProjectId: parseInt(id),
				PmeName: smeName,
				Filter: filter
			}
		});

		return data.NewPmeProject;
	} catch (e) {
		console.log({ e });
	}
};

export const addProject = async (project, originProjectId = 0, supplierIds = null) => {
	// console.log('in addProject:', project);
	try {
		const mutation = gql`
			mutation ($newProject: ProjectInput!, $originProjectId: ID, $supplierIds: [ID]) {
				addProject(newProject: $newProject, OriginProjectId: $originProjectId, SupplierId: $supplierIds) {
					${fields}
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				newProject: project,
				originProjectId,
				supplierIds
			}
		});

		return data.addProject;
	} catch (e) {
		console.log({ e });
	}
};

export const setSlaveProjectStatus = async (stCustomerId, active) => {
	const mutation = gql`
		mutation SET_SLAVE_PROJECT_STATUS($stCustomerId: String!, $active: Boolean!) {
			SetSlaveProjectStatus(StCustomerId: $stCustomerId, Active: $active)
		}
	`;

	try {
		const { data: SetSlaveProjectStatus } = await apollo.mutate({
			mutation,
			variables: {
				stCustomerId,
				active
			}
		});

		return SetSlaveProjectStatus;
	} catch (e) {
		console.log(e);
	}
};

export const getUserLastProject = async (userId) => {
	try {
		const query = gql`
			query GET_USER_LAST_PROJECT($userId: ID!) {
				GetUserLastProject(UserId: $userId) {
					${fields}
				}
			}
		`;
		const {
			data: { GetUserLastProject }
		} = await apollo.query({
			query,
			variables: {
				userId
			},
			fetchPolicy: 'no-cache'
		});

		return GetUserLastProject;
	} catch (error) {
		console.log(error.message);
	}
};

export const updProjectForAndroidSubscription = async (id, project) => {
	try {
		const mutation = gql`
			mutation ($projectId: ID!, $newProject: ProjectInput!) {
				UpdProjectForAndroidSubscription(projectId: $projectId, newProject: $newProject)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				projectId: parseInt(id),
				newProject: project
			}
		});

		return data.UpdProjectForAndroidSubscription;
	} catch (e) {
		console.log({ e });
	}
};
export const getProjectFieldOption = async () => {
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					GetProjectFieldOption {
						${userInfoField}
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});

		return data.GetProjectFieldOption;
	} catch (e) {
		console.log({ e });
	}
};

export const updProjectFieldOption = async (UpdProjectFieldOption) => {
	try {
		const mutation = gql`
			mutation ($UpdProjectFieldOption: ProjectFieldOptionInput!) {
				UpdProjectFieldOption(UpdProjectFieldOption: $UpdProjectFieldOption) {
					${userInfoField}
				}
			}
		`;

		let variables = {
			UpdProjectFieldOption: UpdProjectFieldOption
		};

		const { data } = await apollo.mutate({
			mutation,
			variables
		});

		return data.UpdProjectFieldOption;
	} catch (e) {
		console.log({ e });
	}
};

export const changeProject = async (id) => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($id: ID!) {
					changeProject(projectID: $id)
				}
			`,
			variables: {
				id: id
			}
		});

		return data.changeProject;
	} catch (e) {
		console.log({ e });
	}
};

// Commented out by : Lanja
// reason: https://app.clickup.com/t/86bx3k0x2

/**
 * get one of my project
 * @returns
 */
// export const getMyProjectSummary = async () => {
// 	const {
// 		data: { myProject }
// 	} = await apollo.query({
// 		query: gql`
// 			query {
// 				myProject {
// 					${columnProjectSummary}
// 					realisateur
// 				}
// 			}
// 		`,
// 		fetchPolicy: 'no-cache'
// 	});

// 	return myProject;
// };

// /**
//  * get all my projects
//  * @returns
//  */
// export const getAllMyProjectsSummary = async () => {
// 	const {
// 		data: { myProjects }
// 	} = await apollo.query({
// 		query: gql`
// 			query {
// 				myProjects {
// 					${columnProjectSummary}
// 				}
// 			}
// 		`,
// 		fetchPolicy: 'no-cache'
// 	});

// 	return myProjects;
// };

export const changeMyProject = async (id) => {
	const mutation = gql`
		mutation ($id: ID!) {
			changeProject(projectID: $id)
		}
	`;

	const {
		data: { changeProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			id
		}
	});

	return changeProject;
};

export const copyProject = async (ProjectId, Filter) => {
	const mutation = gql`
		mutation ($ProjectId: ID!, $Filter: CopyProjectFilterInput) {
			CopyProject(ProjectId: $ProjectId, Filter: $Filter) {
				id
			}
		}
	`;

	const {
		data: { CopyProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			ProjectId,
			Filter
		}
	});

	return CopyProject;
};

export const delProject = async (ProjectId) => {
	const mutation = gql`
		mutation ($ProjectId: ID!) {
			DelProject(ProjectId: $ProjectId) {
				id
			}
		}
	`;

	const {
		data: { DelProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			ProjectId
		}
	});

	return DelProject;
};

const columnProjectSummary = `
	id
	titre
	production
`;

const fields = `
	${columnProjectSummary}
	ccPersonalAccessToken
	projectType
	realisateur
	timeZone
	currency
	distanceUnit
	language
	licenseID
	picture
	songMusic
	coProducers
	invoiceDetails
	addressId
	carbonModel
	greenReporting
	useOvertime
	useNightHours
	useTooEarlyHours
	useSixthDay
	useLunchTime
	useStageDay
	numEpisode
	filmLength
	shootingFormat
	productionType
	productionMethod
	mainBroadcastMode
	useOvt1
	useOvt2
	useHomeToWorkTime
	useTransport
	useLunchPerDiem
	useHotelPerDiem
	useDinnerPerDiem
	useAbroadPerDiem
	useTimesheet
	useExpense
	useEntryForCrewExpenses
	useEntryForCrewPo
	useEntryForCrewTimesheet
	useEntryForCrew
	usePurchaseOrder
	useDocument
	useCallSheet
	useAccommodation
	useTipOfDay
	useGreenSupplier
	useTask
	usePlanning
	useClient
	useBudget
	useOcr
	useDpae
	ocrProvider
	startDate
	endDate
	customerId
	numberOfUser
	supportLevel
	masterProjectId
	budgetLocked
	useForCrewInvoice
	reference
	endTrialTime
	largeAccount
	largeAccountPaid
	numberOfActiveUsers
	lastInvoiceNumber
	digitalCertifiedExpense
	address {
		id
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	legalEntities {
		id
		info
		contactName
		contactFunction
		nationalRegistryNumber
		vat
		phone
		email
		name
		type
		addressId
		companyType
		urssafCode
		nafCode
		healthService
		address {
			zip
			state
			street
			number
			box
			lat
			lng
			city
			country
			countryCode
			countryKey
		}
		supplierBankAccounts {
			supplierId
			bankName
			currency
			exchangeRate
			iban
			bic
		}
	}
	lastOrderNumber
	lastOrderFormat
	lastOfferNumber
	lastOfferFormat
	lastInvoiceNumber
	lastInvoiceFormat
	userName
	hodName
	managerName
	shootingStartDate
	wrapStartDate
	useBeforeCallTime
	useWeeklyOvt
	useWeeklyOvt1
	useWeeklyOvt2
	useSeventhDay
	useSundayRate
	useExpenseTravel
	useExpenseTicket
	useExpenseInvoice
	reference
	payrollInterface
	allowTSEntryOutsideContract
	allowExpenseEntryOutsideContract
	allowPOEntryOutsideContract
	analyticPeriodType
	ccShareReporting
	lastPoFormat
	lastPoNumber
	maxExpenseItemsPerSheet
	regionalSetting
	useProduction
	useContacts
	useCrewSetup
	useLocations
	useExport
	useImport
	useFinance
	useTimesheetPlanning
	useGreen
	useCarbonTemplate
	useGreenTable
	useReporting
	useCarbonRemoval
	useWaste
	hideSalaryInfo
	hideDealMemoInfo
	mandatoryExpenseReceipt
	mandatoryInvoiceReceipt
	mandatoryCategoryExpense
	productionMethod
	mainBroadcastMode
	cardboardUnit
	paperUnit
	plasticUnit
	generalWasteUnit
	foodUnit
	glassUnit
	paintSpecialWasteUnit
	plasticDestructionUnit
	woodUnit
	steelUnit
`;

const userInfoField = `
	projectId
	usePersonalNumber
	mandatoryPersonalNumber
	useEmail
	mandatoryEmail
	usePhone
	mandatoryPhone
	useFirstName
	mandatoryFirstName
	useLastName
	mandatoryLastName
	useNationality
	mandatoryNationality
	useLanguage
	mandatoryLanguage
	useBirthdate
	mandatoryBirthdate
	usePlaceOfBirth
	mandatoryPlaceOfBirth
	useCityOfBirthZip
	mandatoryCityOfBirthZip
	useGender
	mandatoryGender
	useProfilePicture
	mandatoryProfilePicture
	useSocialSecurityNumber
	mandatorySocialSecurityNumber
	useNationalRegistrationNumber
	mandatoryNationalRegistrationNumber
	useCompanyNumber
	mandatoryCompanyNumber
	useIdName
	mandatoryIdName
	useIdNumber
	mandatoryIdNumber
	useIdPictureFront
	mandatoryIdPictureFront
	useIdPictureBack
	mandatoryIdPictureBack
	useDriverLicenseNumber
	mandatoryDriverLicenseNumber
	useDriverLicensePictureFront
	mandatoryDriverLicensePictureFront
	useDriverLicensePictureBack
	mandatoryDriverLicensePictureBack
	useSignaturePicture
	mandatorySignaturePicture
	useIban
	mandatoryIban
	useBic
	mandatoryBic
	useCountry
	mandatoryCountry
	useInternetResearch
	mandatoryInternetResearch
	useStreet
	mandatoryStreet
	useNumber
	mandatoryNumber
	useBox
	mandatoryBox
	useZipCode
	mandatoryZipCode
	useCity
	mandatoryCity
	useStateRegion
	mandatoryStateRegion
	useCivilStatus
	mandatoryCivilStatus
	useSpouseFirstName
	mandatorySpouseFirstName
	useSpouseLastName
	mandatorySpouseLastName
	useSpouseBirthdate
	mandatorySpouseBirthdate
	useSpouseHasSalary
	mandatorySpouseHasSalary
	useSpouseProfession
	mandatorySpouseProfession
	useNumberOfDependentChildren
	mandatoryNumberOfDependentChildren
	useNumberOfDisabledChildren
	mandatoryNumberOfDisabledChildren
	useNumberOfDependentPerson
	mandatoryNumberOfDependentPerson
	useNumberOfDisabledPerson
	mandatoryNumberOfDisabledPerson
	useContactName
	mandatoryContactName
	useContactPhoneNumber
	mandatoryContactPhoneNumber
	useTaxes
	mandatoryTaxes
	useTaxDeclarationCountry
	mandatoryTaxDeclarationCountry
	showMyEmailInCrewList
	showMyPhoneInCrewList
	useWorkerWithADisability
	mandatoryWorkerWithADisability
	useHaveAllergies
	mandatoryHaveAllergies
	useAllergiesDetails
	mandatoryAllergiesDetails
	useColor
	mandatoryColor
	useStudyLevel
	mandatoryStudyLevel
	useSize
	mandatorySize
	usePantSize
	mandatoryPantSize
	useJacketSize
	mandatoryJacketSize
	useShoeSize
	mandatoryShoeSize
	useTshirtSize
	mandatoryTshirtSize
	useMaidenName
	mandatoryMaidenName
	useTitle
	mandatoryTitle
	useCountryOfBirth
	mandatoryCountryOfBirth
	useCongesSpectacleId
	mandatoryCongesSpectacleId
	useDriverLicense
	mandatoryDriverLicense
	useBankAccountOwnerName
	mandatoryBankAccountOwnerName
	useBankDetailDocument
	mandatoryBankDetailDocument
	useSocialSecurityCard
	mandatorySocialSecurityCard
	useInsuranceCertificate
	mandatoryInsuranceCertificate
	useSocialSecurityCenter
	mandatorySocialSecurityCenter
	useSocialSecurityCenterAddress
	mandatorySocialSecurityCenterAddress
	useLastMedicalVisitDate
	mandatoryLastMedicalVisitDate
	useMedicalVisitValidity
	mandatoryMedicalVisitValidity
	useMedicalVisitDocument
	mandatoryMedicalVisitDocument
	useVehicleName
	mandatoryVehicleName
	useVehicleBrand
	mandatoryVehicleBrand
	useVehicleModel
	mandatoryVehicleModel
	useVehiclePlateNumber
	mandatoryVehiclePlateNumber
	useVehicleTransportationMean
	mandatoryVehicleTransportationMean
	useVehicleTransportationMeanDetail
	mandatoryVehicleTransportationMeanDetail
	useVehicleKgCoTwo
	mandatoryVehicleKgCoTwo
	useSncfLoyaltyCard
	mandatorySncfLoyaltyCard
	useSncfDiscountCard
	mandatorySncfDiscountCard
	useFlyingBlueCard
	mandatoryFlyingBlueCard
	useOtherAirlineCard
	mandatoryOtherAirlineCard
	useResidencePermitNumber
	mandatoryResidencePermitNumber
	useResidenceValidityEnd
	mandatoryResidenceValidityEnd
	useResidencePermit
	mandatoryResidencePermit
	useWorkCard
	mandatoryWorkCard
	usePseudonym
	mandatoryPseudonym
	useGenericMentionProfessionalAssociation
	mandatoryGenericMentionProfessionalAssociation
	useRetired
	mandatoryRetired
	usePower
	mandatoryPower
	useGrayCardFrontPicture
	mandatoryGrayCardFrontPicture
	useGrayCardBackPicture
	mandatoryGrayCardBackPicture
	useDisabilityNotificationDate
	mandatoryDisabilityNotificationDate
	useType
	mandatoryType
	useStatus
	mandatoryStatus
	useInvalidity
	mandatoryInvalidity
	useRqth
	mandatoryRqth
	useInvalidityPercentage
	mandatoryInvalidityPercentage
	useSecondIban
	mandatorySecondIban
	useSecondBic
	mandatorySecondBic
	useSecondBankAccountOwnerName
	mandatorySecondBankAccountOwnerName
	useSecondBankDetailDocument
	mandatorySecondBankDetailDocument
`;

//############### Needed for project changes #############
const commonProjectChangeFields = `
	id
	titre
	production
	invoiceDetails
`;

export const query_myProjects = gql`
	query {
		myProjects {
			${commonProjectChangeFields}
		}
	}
`;

export const query_myProject = gql`
	query {
		myProject {
			${commonProjectChangeFields}
			realisateur
		}
	}
`;
