import Vue from 'vue';
import VueApollo from 'vue-apollo';
// import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'

import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

// import { createApolloFetch } from 'apollo-fetch'
// https://github.com/apollographql/apollo-fetch

import { store } from '@/store';

import pkg from './../package.json';
const version = pkg.version;

// Install the vue plugin
Vue.use(VueApollo);

export const BASE_URL = process.env.BASE_URL;
const uri = `${process.env.VUE_APP_GQL}/graphql`;
const httpLink = new HttpLink({ uri });
let defaultRouter = null;
let defaultStore = null;

const acceptJsonMiddleware = new ApolloLink((operation, forward) => {
	// get the authentication token from local storage if it exists
	const { token } = store.state || {};

	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
			'X-Client-Version': version,
			accept: 'application/json'
		}
	}));

	return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (networkError && networkError.statusCode === 505) {
		defaultStore.dispatch('header/setErrorAppVersion', networkError.bodyText.trim());
		store.onLogout();
		defaultRouter.push('/pages/login').catch(() => {});
	}
});

// Create apollo client
export const apolloClient = new ApolloClient({
	link: from([acceptJsonMiddleware, errorLink, httpLink]),
	cache: new InMemoryCache()
});

/**
 * Call this in the Vue app file
 *
 * @param {Object} [options={}]
 * @return {Function}
 */
export function createProvider(options = {}) {
	defaultRouter = options.router;
	defaultStore = options.store;

	// Create vue apollo provider
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient
	});

	return apolloProvider;
}
