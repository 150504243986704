/**
 * @doc         https://github.com/rlemaigre/Easy-DnD
 */
import Vue from 'vue';
import { Drag, Drop, DropMask } from 'vue-easy-dnd';
import { toKebabCase } from '@/shared/utils';

const components = { Drag, Drop, DropMask };

Object.keys(components).forEach((key) => {
	const name = toKebabCase(key);
	Vue.component(name, components[key]);
});
