/**
 * @doc     https://medium.com/@andrejsabrickis/https-medium-com-andrejsabrickis-create-simple-eventbus-to-communicate-between-vue-js-components-cdc11cd59860
 */
import Vue from 'vue';

const eventBus = {};

eventBus.install = (Vue) => {
	Vue.prototype.$bus = new Vue();
};

Vue.use(eventBus);
