const state = () => ({
	isShowRoomPictureOpen: false
});

const getters = {
	isShowRoomPictureOpen: (state) => state.isShowRoomPictureOpen
};

const mutations = {
	/**
	 * @param {Object} state
	 * @param {Boolean} status
	 */
	SET_SHOW_ROOM_PICTURE_OPEN_STATE(state, status) {
		state.isShowRoomPictureOpen = status;
	}
};

const actions = {
	/**
	 * @param {Object} storeContext
	 * @param {Boolean} status
	 */
	setOpenShowRoomPictureOpenState({ commit }, status) {
		commit('SET_SHOW_ROOM_PICTURE_OPEN_STATE', status);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
