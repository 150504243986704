/**
 * Expenses Store
 *
 * @doc     https://bootstrap-vue.js.org/docs/reference/color-variants
 */
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '@/shared/utils';
import { getTextFromMenuNumberAndMenuValue, getMenuArrayFromDb } from '@/cruds/language.crud';
import { getExpenseItems } from '@/cruds/expense.crud';
import { PROJECT_CARBON_FIELD } from '@/shared/constants';
// import { resolve } from 'path';

const columnExpense = `
	id
	date
	type
	description
	kgCoTwo
	amount
	amountVat
	amountTotal
	comment
	validated
	departmentName
	functionName
	encoderId
	encoder {
		id
		name
		firstName
		fullName
	}
	user {
		id
		name
		firstName
	}				
`;

const columnExpenseItem = `
	createdAt
	updatedAt
	costCenter
	category
	paiementType
	supplierName
	supplierId
	type
	department
	departmentName
	description
	subCategory
	date
	id
	images
	amount
	amountTotal
	amountVat
	comment
	km
	kgCoTwo
	fromLocation
	coTwoCategory
	toLocation
	validated
	categoryName
	paiementTypeName
	isInContract
	subType
	additionalSubType
	user {
		id
		name
		firstName
		fullName
	}
	encoder {
		id
		name
		firstName
	}
	projectFlagItems {
		id
		taxeFlags {
			id
			flagId
			value
			name
			short
			factor
			amount
			payCode
			color
			allowanceRate
			bookmark
			category
			digitalSubmission
			disabled
			costCenter
			forScreen
			fieldType
			defaultValue
			jsFormula
		}
		customFlags {
			id
			flagId
			value
			name
			short
			factor
			amount
			payCode
			color
			allowanceRate
			bookmark
			category
			digitalSubmission
			disabled
			costCenter
			forScreen
			fieldType
			defaultValue
			jsFormula
		}
	}
	supplier {
	  name
	}
	carbon {
	  ${PROJECT_CARBON_FIELD}
	}
`;

const state = () => ({
	expenses: [],
	expensesCount: 0,
	currentExpense: [],
	focusedExpenseItem: {
		id: 0,
		date: '',
		amountTotal: 0,
		kgCoTwo: 0,
		validatedStatus: 'Not Submited'
	},
	departments: [],
	departmentsForSalary: [],
	getDepartmentsForExpense: [],
	notSubmitedExpenses: [],
	currTicketRef: null,
	activeTab: 0,
	collectionId: [],
	currentNewExpense: null,
	currentExpenseMobile: null
});

const getters = {
	expenses: (state) => state.expenses,
	expensesCount: (state) => state.expensesCount,
	currentExpense: (state) => state.currentExpense,
	notSubmitedExpenses: (state) => state.notSubmitedExpenses,
	departments: (state) => state.departments,
	departmentsForSalary: (state) => state.departmentsForSalary,
	getDepartmentsForExpense: (state) => state.getDepartmentsForExpense,
	currTicketRef: (state) => state.currTicketRef,
	focusedExpenseItem: (state) => state.focusedExpenseItem,
	activeTab: (state) => state.activeTab,
	collectionId: (state) => state.collectionId,
	currentNewExpense: (state) => state.currentNewExpense,
	currentExpenseId: (state) => state.currentExpenseId,
	getCurrentExpenseMobile: (state) => state.currentExpenseMobile
};

const mutations = {
	SET_EXPENSES(state, expenses) {
		//console.log("expenses", expenses);
		const { newExp, submited, approved } = expensensSplitter(expenses);
		state.expenses = {
			newExp: cleanExpense(newExp),
			submited: cleanExpense(submited),
			approved: cleanExpense(approved)
		};
	},
	SET_EXPENSES_COUNT(state, { length }) {
		state.expensesCount = length;
	},
	SET_FOCUS_EXPENSE_ITEM(state, expense) {
		state.focusedExpenseItem = expense;
	},
	SET_CURRENT_EXPENSE(state, expense) {
		state.currentExpense = cleanExpense(expense);
	},
	SET_DEPARTMENTS(state, departments) {
		state.departments = departments;
	},
	SET_DEPARTMENTS_FOR_SALARY(state, departments) {
		state.departmentsForSalary = departments;
	},
	SET_DEPARTMENTS_FOR_EXPENSE(state, departments) {
		state.getDepartmentsForExpense = departments;
	},
	SET_CURR_REF_TICKET(state, id) {
		state.currTicketRef = id;
	},
	SET_NOT_SUBMITTED_EXENSES(state, expenses) {
		state.notSubmitedExpenses = cleanExpense(expenses);
	},
	/**
	 *
	 * @param {Object} storeContext
	 * @param {String} tabName
	 */
	SET_ACTIVE_TAB(state, tabName) {
		state.activeTab = tabName;
	},
	SET_COLLECTION_ID(state, ids) {
		state.collectionId = ids;
	},
	SET_CURRENT_NEW_EXPENSE(state, expense) {
		state.currentNewExpense = expense;
	},
	SET_CURRENT_EXPENSE_ID(state, expenseId) {
		state.currentExpenseId = expenseId;
	},
	SET_CURRENT_EXPENSE_MOBILE(state, expense) {
		state.currentExpenseMobile = expense;
	}
};

const actions = {
	async getExpenses({ commit }) {
		const query_expenses = gql`
			query {
				MyExpenses {
					${columnExpense}
				}
			}
		`;
		const { data } = await apollo.query({
			query: query_expenses,
			fetchPolicy: 'no-cache'
		});

		commit('SET_EXPENSES_COUNT', data.MyExpenses.length);
		commit('SET_EXPENSES', data.MyExpenses);
	},
	// Show Expense Item
	setFocusExpenseItem({ commit }, expense) {
		commit('SET_FOCUS_EXPENSE_ITEM', expense);
	},
	async getCurExpense({ commit }, expenseId = 0) {
		try {
			commit('SET_CURRENT_EXPENSE', await getExpenseItems(expenseId));
		} catch (e) {
			throw new Error(e);
		}
	},
	async initNewExpense({ dispatch }, expense) {
		const mutation = gql`
			mutation ($AddedExpenseItem: ExpenseItemInput!) {
				NewExpenseItem(AddedExpenseItem: $AddedExpenseItem) {
					department
					description
					paiementType
					paiementTypeName
					coTwoCategory
					subCategory
					subType
					additionalSubType
					type
					date
					id
					amountTotal
					amountVat
					fromLocation
					toLocation
					amount
					category
					allItemsTotal
					supplierName
					categoryName
					km
					kgCoTwo
					supplierId
					carbon {
						${PROJECT_CARBON_FIELD}
					}
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: { AddedExpenseItem: expense }
		});
		dispatch('setExpenseRef', data.NewExpenseItem.id);
		// console.log('New ticket from gqql: ', data.NewExpenseItem);
		dispatch('setCurrentNewExpense', data.NewExpenseItem);
	},
	async setNewExpense({ dispatch }, { id, expense, userId = 0, projectFlagItemId = 0 }) {
		// try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation($ExpenseItemId: ID!, $UpdatedExpenseItem: ExpenseItemInput!, $UserId: ID, $projectFlagItemId: ID) {
					UpdExpenseItem(
						ExpenseItemId: $ExpenseItemId
						UpdatedExpenseItem: $UpdatedExpenseItem,
						UserId: $UserId,
						ProjectFlagItemId: $projectFlagItemId
					) {
						${getExpenseType(expense.type)}
					}
				}
			`,
			variables: {
				ExpenseItemId: +id,
				UpdatedExpenseItem: expense,
				UserId: +userId,
				projectFlagItemId: +projectFlagItemId
			}
		});
		await dispatch('setExpenseRef', data.UpdExpenseItem.id);
		dispatch('getNotSubmittedExpense');
		dispatch('getExpenses');
		// } catch (e) {
		//   console.error(e);
		// }
	},
	async deleteExpense({ dispatch }, id) {
		if (isNil(id)) return;
		const mutation = gql`
			mutation ($ExpenseItemId: ID!) {
				DelExpenseItem(ExpenseItemId: $ExpenseItemId) {
					id
				}
			}
		`;
		await apollo
			.mutate({
				mutation,
				variables: { ExpenseItemId: parseInt(id) }
			})
			.then(() => {
				dispatch('setExpenseRef', null);
				dispatch('setCurrentNewExpense', null);
				dispatch('setFocusExpenseItem', null);
				dispatch('getNotSubmittedExpense');
				dispatch('getExpenses');
			})
			.catch((err) => console.log({ err }));
	},
	async setNewInvoiceFromPO({ dispatch }, PoID) {
		if (isNil(PoID)) return;
		const mutation = gql`
			mutation ($PurchaseOrderId: ID!) {
				NewInvoiceFromPO(PurchaseOrderId: $PurchaseOrderId) {
					id
				}
			}
		`;
		await apollo.mutate({
			mutation,
			variables: { PurchaseOrderId: parseInt(PoID) }
		});
		dispatch('getNotSubmittedExpense');
	},
	/**
	 * @param {Object} storeContext
	 * @param {Object} options
	 *
	 * poItems {Array}
	 * if the poItems is [-1] it selects all po_items left in PO
	 */
	async setNewInvoiceFromPoForSelectedPoItems({ dispatch }, { id, poItems }) {
		if (isNil(id)) return;
		try {
			const mutation = gql`
				mutation ($PurchaseOrderId: ID!, $PoItemsIds: [ID]!) {
					NewInvoiceFromPoForSelectedPoItems(PurchaseOrderId: $PurchaseOrderId, PoItemsIds: $PoItemsIds) {
						id
					}
				}
			`;
			await apollo.mutate({
				mutation,
				variables: {
					PurchaseOrderId: parseInt(id),
					PoItemsIds: poItems || -1
				}
			});
			dispatch('getNotSubmittedExpense');
		} catch (e) {
			console.log({ e });
		}
	},
	async getNotSubmittedExpense({ commit }) {
		try {
			const expenseItems = await getExpenseItems(-1);

			commit('SET_NOT_SUBMITTED_EXENSES', expenseItems);
			commit('SET_COLLECTION_ID', getCollectionId(expenseItems));
		} catch (e) {
			throw new Error(e);
		}
	},
	async setExpenseRef({ commit }, id) {
		commit('SET_CURR_REF_TICKET', id);
	},
	setCurrentNewExpense({ commit }, expense) {
		commit('SET_CURRENT_NEW_EXPENSE', expense);
	},
	setCurrentExpenseId({ commit }, expenseId) {
		commit('SET_CURRENT_EXPENSE_ID', expenseId);
	},
	async getAllDepartments({ commit }) {
		const query = gql`
			query {
				GetDepartments {
					value
					id
					message
				}
			}
		`;
		const { data } = await apollo.query({
			query,
			fetchPolicy: 'no-cache'
		});
		commit('SET_DEPARTMENTS', data.GetDepartments);
	},
	async getDepartments({ commit }) {
		const query = gql`
			query ($hasFunction: Boolean) {
				GetDepartments(HasFunction: $hasFunction) {
					value
					id
					message
					forExpense
					forSalary
				}
			}
		`;
		const { data } = await apollo.query({
			query,
			variables: {
				hasFunction: true
			},
			fetchPolicy: 'no-cache'
		});
		commit('SET_DEPARTMENTS', data.GetDepartments);
	},
	async getDepartmentsForExpense({ commit }) {
		const query = gql`
			query ($ForExpense: Boolean, $hasFunction: Boolean) {
				GetDepartments(ForExpense: $ForExpense, HasFunction: $hasFunction) {
					value
					id
					message
					forExpense
					forSalary
				}
			}
		`;
		const { data } = await apollo.query({
			query,
			variables: {
				ForExpense: true,
				hasFunction: true
			},
			fetchPolicy: 'no-cache'
		});
		commit('SET_DEPARTMENTS_FOR_EXPENSE', data.GetDepartments);
	},
	async getDepartmentsForSalaryJobRequest({ commit }, targetProjectId) {
		const query = gql`
			query ($ForSalary: Boolean, $hasFunction: Boolean, $projectId: ID) {
				GetDepartments(ForSalary: $ForSalary, HasFunction: $hasFunction, ProjectId: $projectId) {
					value
					id
					message
					forExpense
					forSalary
				}
			}
		`;
		const {
			data: { GetDepartments }
		} = await apollo.query({
			query,
			variables: {
				ForSalary: null,
				hasFunction: false,
				projectId: +targetProjectId
			},
			fetchPolicy: 'no-cache'
		});
		commit('SET_DEPARTMENTS_FOR_SALARY', GetDepartments);
	},
	async getDepartmentsForSalary({ commit }) {
		const query = gql`
			query ($ForSalary: Boolean, $hasFunction: Boolean) {
				GetDepartments(ForSalary: $ForSalary, HasFunction: $hasFunction) {
					value
					id
					message
					forExpense
					forSalary
				}
			}
		`;
		const { data } = await apollo.query({
			query,
			variables: {
				ForSalary: null,
				hasFunction: true
			},
			fetchPolicy: 'no-cache'
		});
		commit('SET_DEPARTMENTS_FOR_SALARY', data.GetDepartments);
	},
	async sendToValidation({ dispatch }, title) {
		try {
			await apollo.mutate({
				mutation: gql`
					mutation ($title: String) {
						SendAllExpenses(ExpenseTitle: $title)
					}
				`,
				variables: {
					title
				},
				fetchPolicy: 'no-cache'
			});
			dispatch('getNotSubmittedExpense');
			dispatch('getExpenses');
		} catch (e) {
			console.log(e);
		}
	},
	/**
	 * @param {Object}  storeContext
	 * @param {String}  tabName
	 */
	setActiveTab({ commit }, tabName) {
		// if (["not-submitted", "submitted"].includes(tabName)) return false;
		commit('SET_ACTIVE_TAB', tabName);
	},
	setCollectionId({ commit }, ids) {
		commit('SET_COLLECTION_ID', ids);
	},
	// set Current Expense on Mobile
	setCurrentExpenseMobile({ commit }, expense) {
		commit('SET_CURRENT_EXPENSE_MOBILE', expense);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};

const expensensSplitter = (expenses) => {
	const newExp = [];
	const submited = [];
	const approved = [];
	Object.keys(expenses).forEach((key) => {
		if (expenses[key].validated !== 0 && expenses[key].validated !== 8 && expenses[key].validated !== 16 && expenses[key].validated !== 4) {
			submited.push(expenses[key]);
		} else if (expenses[key].validated === 8) {
			approved.push(expenses[key]);
		} else {
			newExp.push(expenses[key]);
		}
	});
	return { newExp, submited, approved };
};

const cleanExpense = (expenses) => {
	expenses = expenses.map(async (expense) => {
		return {
			...expense,
			validatedStatus: await validatedText(expense.validated),
			typeString: await getTypeText(expense.type),
			_rowVariant: '',
			validatedClass: validatedColor(expense.validated),
			statusClass: statusClass(expense.validated),
			subCategoryName: await getSubCategoryText(expense)
		};
	});

	let newExpenses = [];
	for (const expense of expenses) {
		expense.then((data) => {
			newExpenses.push(data);
		});
	}

	return newExpenses;
};

const getTypeText = async (type) => {
	let value = null;
	await new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(getTextFromMenuNumberAndMenuValue(1003, type));
			reject(false);
		}, 150);
	}).then((data) => {
		value = data;
	});

	return value;
};

const getSubCategoryText = async (expense) => {
	const subId = expense.subCategory;
	const subType = expense.subType;
	let value = null;
	const transportationMeanMenu = await Promise.all([getMenuArrayFromDb(1405), getMenuArrayFromDb(1406), getMenuArrayFromDb(1407)]);
	const subTypeMenu = await Promise.all([getMenuArrayFromDb(1408), getMenuArrayFromDb(1420)]);

	let newSubTypeText = subTypeMenu.flat().find((element) => element.value === subType);
	newSubTypeText = newSubTypeText === undefined ? '' : newSubTypeText.text;

	let newSubCategoryText = transportationMeanMenu.flat().find((element) => element.value === subId);
	newSubCategoryText = newSubCategoryText === undefined ? '' : newSubCategoryText.text;

	value = newSubCategoryText + `${newSubTypeText === '' ? '' : ' - ' + newSubTypeText}`;

	// await new Promise((resolve, reject) => {
	// 	setTimeout(() => {
	// 		resolve(getTextFromMenuNumberAndMenuValue(1406, subId));
	// 		reject(false);
	// 	}, 150);
	// }).then((data) => {
	// 	value = data;
	// 	value = `${value} ${newSubType.toLowerCase() === 'not found' ? '' : ' - ' + newSubType}`;
	// });

	return value;
};

const validatedText = async (validated) => {
	let value = null;
	await new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
			reject(false);
		}, 150);
	}).then((data) => {
		value = data;
	});

	return value;
};

const validatedColor = (validated) => {
	let retval = 'info';
	if (validated == 1) {
		retval = 'primary';
	} else if (validated == 2) {
		retval = 'warning';
	} else if (validated == 8) {
		retval = 'success';
	} else if (validated == 4 || validated == 16) {
		retval = 'danger';
	}
	return retval;
};

const statusClass = (validated) => {
	let value = 'not-submitted';
	if (validated === 1) {
		value = 'info';
	} else if (validated === 2) {
		value = 'pending';
	} else if (validated === 8) {
		value = 'validated';
	} else if (validated === 4 || validated === 16) {
		value = 'refused';
	}
	return value;
};

/**
 * @param {Number} type - 2 / 1 / 0
 */
const getExpenseType = (type) => {
	return type === 2
		? `
      description
      paiementType
      paiementTypeName
      type
      date
      id
      amountTotal
      amountVat
      fromLocation
      toLocation
      category
      amount
      images
      imageName
      allItemsTotal
      km
      kgCoTwo
      supplierId
      validated
      subType
      additionalSubType
      subCategory
    `
		: `
      department
      description
      paiementType
      paiementTypeName
      type
      date
      id
      amountTotal
      amountVat
      fromLocation
      toLocation
      amount
      images
      imageName
      category
      allItemsTotal
      supplierName
      categoryName
      km
      kgCoTwo
      supplierId
      validated
    `;
};

const getCollectionId = (expenses) => {
	let ids = [];

	for (const expense of expenses) {
		if (parseInt(expense.validated, 10) === 0) {
			ids.push(+expense.id);
		}
	}

	return ids;
};
