/**
 * @doc     https://medium.com/@andrejsabrickis/https-medium-com-andrejsabrickis-create-simple-eventbus-to-communicate-between-vue-js-components-cdc11cd59860
 */
import Vue from 'vue';
import { isNil } from '~utils';

const contextApp = {};

contextApp.install = (Vue) => {
	/**
	 * @param {*} any
	 * @return {Boolean}
	 */
	Vue.prototype.$nil = (any) => isNil(any);

	/**
	 * @param {*} any
	 * @return {Boolean}
	 */
	Vue.prototype.$has = (any) => !isNil(any);

	/**
	 * @param {*} any
	 * @return {ConsoleLog}
	 */
	Vue.prototype.$log = (any) => console.log(any);

	/**
	 * wait delay
	 * @param {Number} delay
	 * @return {Promise}
	 */
	Vue.prototype.$wait = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
};

Vue.use(contextApp);
