import Vue from 'vue';

let max2ndPos = 0;
Vue.directive('hour-minute', {
	inserted(el, binding) {
		//workType => 0: 'hourly', 1: 'daily', 2: 'weekly', 3: 'monthly', 4: 'yearly'
		const workType = binding.value;

		el.addEventListener('keydown', (event) => {
			const value = el.value;
			const caretPosition = getCaretPosition(el);
			if (
				((event.key >= 0 && event.key <= 9) ||
					event.code === 'ArrowLeft' ||
					event.code === 'ArrowRight' ||
					event.code === 'Tab' ||
					event.code === 'Delete' ||
					event.code === 'Backspace') &&
				event.code !== 'Space' &&
				value.length === 5
			) {
				if (event.key >= 0 && event.key <= 9) {
					if (workType === 0 || workType === 1) {
						if (caretPosition === 0) {
							if (event.key <= 2) {
								const firstValue = parseInt(event.key, 10);
								if (firstValue === 2) {
									max2ndPos = 3;
								} else {
									max2ndPos = 9;
								}
								el.setRangeText(event.key, caretPosition, caretPosition + 1, 'end');
							}
						} else if (caretPosition === 1) {
							if (event.key <= max2ndPos) {
								el.setRangeText(event.key + ':', caretPosition, caretPosition + 2, 'end');
							}
						} else if (caretPosition === 3) {
							if (event.key <= 5) {
								el.setRangeText(event.key, caretPosition, caretPosition + 1, 'end');
							}
						} else {
							el.setRangeText(event.key, caretPosition, caretPosition + 1, 'end');
						}
					} else if (workType === 2) {
						if (caretPosition === 1) {
							el.setRangeText(event.key + ':', caretPosition, caretPosition + 2, 'end');
						} else if (caretPosition === 3) {
							if (event.key <= 5) {
								el.setRangeText(event.key, caretPosition, caretPosition + 1, 'end');
							}
						} else {
							el.setRangeText(event.key, caretPosition, caretPosition + 1, 'end');
						}
					}
					event.preventDefault();
					triggerInputUpdate(el);
				} else if (event.code === 'Backspace') {
					if (caretPosition > 0) {
						el.setRangeText(caretPosition === 3 ? ':' : '0', caretPosition - 1, caretPosition, 'start');
						event.preventDefault();
						triggerInputUpdate(el);
					}
				} else if (event.code === 'Delete') {
					el.setRangeText(caretPosition === 2 ? ':' : '0', caretPosition, caretPosition + 1, 'start');
					event.preventDefault();
					triggerInputUpdate(el);
				}
			} else {
				el.value = value.substring(0, 5);
				event.preventDefault();
				triggerInputUpdate(el);
			}
		});
	},
	componentUpdated(el) {
		if (el.value === '') {
			el.value = '00:00';
			triggerInputUpdate(el);
		} else if (el.value.length > 5) {
			el.value = el.value.substring(0, 5);
			triggerInputUpdate(el);
		}
	}
});

const trigger = (el, type) => {
	const e = document.createEvent('HTMLEvents');
	e.initEvent(type, true, true);
	el.dispatchEvent(e);
};

const getCaretPosition = (el) => {
	return el.value.slice(0, el.selectionStart).length;
};

function triggerInputUpdate(el) {
	trigger(el, 'input');
}
