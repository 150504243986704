// https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/
export default function nextFactory(context, middleware, index) {
	const subsequentMiddleware = middleware[index];

	if (!subsequentMiddleware) return context.next;

	return (...parameters) => {
		context.next(...parameters);

		const nextMiddleware = nextFactory(context, middleware, index + 1);
		subsequentMiddleware({ ...context, next: nextMiddleware });
	};
}
