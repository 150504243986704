import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const addDepartment = async (projectId = null) => {
	try {
		const mutation_newDep = gql`
			mutation ($projectId: ID) {
				NewDepartment(ProjectId: $projectId) {
					id
					value
					message
					costCenter
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation: mutation_newDep,
			variables: {
				projectId
			}
		});
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getDepartment = async (departmentId) => {
	try {
		const QUERY_GET_DEPARTMENT = gql`
			query ($departmentId: Int!) {
				GetDepartment(Number: $departmentId) {
					${departmentFields}
				}
			}
		`;

		const {
			data: { GetDepartment }
		} = await apollo.query({
			query: QUERY_GET_DEPARTMENT,
			variables: {
				departmentId: parseInt(departmentId)
			}
		});

		return GetDepartment;
	} catch (error) {
		console.error(error);
	}
};
export const updateDepartment = async (DepartmentId, DepartmentName, NewCostCenter, Prefix, CategoryId) => {
	try {
		const mutation = gql`
			mutation ($DepartmentId: ID!, $DepartmentName: String!, $NewCostCenter: String, $Prefix: String, $CategoryId: ID) {
				UpdDepartment(
					DepartmentId: $DepartmentId
					DepartmentName: $DepartmentName
					NewCostCenter: $NewCostCenter
					Prefix: $Prefix
					CategoryId: $CategoryId
				) {
					id
					value
					prefix
					message
					costCenter
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				DepartmentId,
				DepartmentName,
				NewCostCenter,
				Prefix,
				CategoryId
			}
		});
		return data;
	} catch (e) {
		console.log({ e });
	}
};

export const deleteDepartment = async (DepartmentId, DeleteCategory) => {
	try {
		const mutation = gql`
			mutation ($DepartmentId: ID!, $DeleteCategory: Boolean!) {
				DelDepartment(DepartmentId: $DepartmentId, DeleteCategory: $DeleteCategory) {
					id
					value
					prefix
					message
					costCenter
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				DepartmentId,
				DeleteCategory
			}
		});
		return data;
	} catch (e) {
		console.log({ e });
	}
};
export const getDepartments = async (ForExpense = null, AppChoice = null, ForTimesheetEFC = null) => {
	try {
		const QUERY_GET_DEPARTMENTS = gql`
			query ($ForExpense: Boolean, $AppChoice: Boolean, $ForTimesheetEFC: Boolean) {
				GetDepartments(ForExpense: $ForExpense, AppChoice: $AppChoice, ForTimesheetEFC:$ForTimesheetEFC) {
					${departmentFields}
				}
			}
		`;

		const {
			data: { GetDepartments }
		} = await apollo.query({
			query: QUERY_GET_DEPARTMENTS,
			variables: {
				ForExpense,
				AppChoice,
				ForTimesheetEFC
			},
			fetchPolicy: 'no-cache'
		});

		return GetDepartments;
	} catch (error) {
		console.error(error);
	}
};

export const getDepartmentsAndFunctions = async () => {
	const query_departments_functions = gql`
		query {
			GetDepartmentsAndFunctions {
				id
				message
				value
				functions {
					id
					value
					message
					users {
						id
						name
						firstName
						email
						userProjectID
					}
				}
			}
		}
	`;

	const { data } = await apollo.query({
		query: query_departments_functions,
		fetchPolicy: 'no-cache'
	});
	// console.log(data);
	return data;
};

export const getFunctions = async (departmentId = null, projectId = null) => {
	try {
		const query = gql`
			query ($departmentId: Int, $projectId: ID) {
				GetFunctions(DepartmentNumber: $departmentId, ProjectId: $projectId) {
					${functionFields}
				}
			}
		`;

		const {
			data: { GetFunctions }
		} = await apollo.query({
			query,
			variables: {
				departmentId,
				projectId
			},
			fetchPolicy: 'no-cache'
		});

		return GetFunctions;
	} catch (error) {
		console.error(error);
	}
};

export const updateFunction = async (functionId, functionName, newCostCenter, shortCode = '', prefix = '', CategoryId) => {
	try {
		const mutation = gql`
			mutation ($functionId: ID!, $functionName: String!, $newCostCenter: String, $shortCode: String, $CategoryId: ID, $prefix: String) {
				UpdFunction(FunctionId: $functionId, FunctionName: $functionName, NewCostCenter: $newCostCenter, ShortCode: $shortCode, CategoryId: $CategoryId, Prefix: $prefix) {
					${functionFields}
				}
			}
		`;

		let variables = {
			functionId,
			functionName,
			newCostCenter,
			shortCode,
			CategoryId,
			prefix
		};

		const {
			data: { UpdFunction }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return UpdFunction;
	} catch (error) {
		console.error(error);
	}
};

export const deleteFunction = async (functionId, deleteCategory) => {
	try {
		const mutation = gql`
			mutation ($functionId: ID!, $deleteCategory: Boolean!) {
				DelFunction(FunctionId: $functionId, DeleteCategory: $deleteCategory) {
					${functionFields}
				}
			}
		`;

		let variables = {
			functionId,
			deleteCategory
		};

		const {
			data: { DelFunction }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return DelFunction;
	} catch (error) {
		console.error(error);
	}
};

export const addFunction = async (departmentId, projectId = null) => {
	try {
		const mutation = gql`
			mutation ($departmentId: Int!, $projectId: ID) {
				NewFunction(DepartmentNumber: $departmentId, ProjectId: $projectId) {
					${functionFields}
				}
			}
		`;

		let variables = {
			departmentId,
			projectId
		};

		const {
			data: { NewFunction }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return NewFunction;
	} catch (error) {
		console.error(error);
	}
};

const functionFields = `
	id
	value
	departmentNumber
	prefix
	message
	costCenter
	category
	shortCode
	categoryLinkedToFun
`;

const departmentFields = `
	id
	createdAt
	updatedAt
	projectId
	importId
	value
	message
	category
	radioChannel
	forSalary
	forExpense
	prefix
	costCenter
	categoryLinkedToDep
`;
