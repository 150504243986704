import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { store } from '@/store';

const REFRESH_TOKEN_QUERY = gql`
	query RefreshToken {
		refreshToken
	}
`;

const state = {
	token: ''
};

const getters = {
	token: (state) => state.token
	// isAuthenticated: (state) => !!state.token //Check Authentification if needed
};

const mutations = {
	SET_TOKEN(state, token) {
		state.token = token;
	}
};

const actions = {
	async refreshToken({ commit }) {
		try {
			const response = await apollo.query({
				query: REFRESH_TOKEN_QUERY,
				fetchPolicy: 'no-cache'
			});
			const newToken = response.data.refreshToken;
			commit('SET_TOKEN', newToken);
			store.setTokenAction(newToken);
		} catch (err) {
			console.error(err);
		}
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
