export const EXPORTNAME = {
	EXPENSE: 'expense',
	PO: 'po',
	PURCHASE_ORDER: 'purchaseorder',
	TIMESHEET: 'timesheet',
	TS: 'ts',
	USER: 'user',
	EXPENSE_ITEM: 'expenseitem',
	PO_JOB_REQUEST: 'jobrequest',
	BUDGET_PER_CATEGORY: 'budgetpercategory'
};
