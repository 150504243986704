<template>
	<div class="card card-collapse" :class="{ 'is-openned': isOpen }" :ref="componentRef" :id="componentRef">
		<div class="card-header" @click="handleOpenCollapse">
			<h4 v-html="title" />
			<i class="icon-arrow-right" />
		</div>
		<b-card-body v-show="isOpen" :class="{ 'is-openned': isOpen }" class="collapse-card-body">
			<slot />
		</b-card-body>
	</div>
</template>

<script>
import { makeID } from '@/shared/utils';
export default {
	name: 'CollapseCardComponent',
	props: {
		open: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: false,
			default: 'title'
		}
	},
	data() {
		return {
			isOpen: this.open
		};
	},
	watch: {
		/**
		 * @param {Boolean} status
		 */
		open(status) {
			this.isOpen = status;
		}
	},
	computed: {
		/**
		 * @return {String}
		 */
		componentRef() {
			return `collapse_card${makeID(10)}`;
		}
	},
	methods: {
		handleOpenCollapse() {
			this.isOpen = !this.isOpen;
		}
	}
};
</script>

<style lang="scss" scoped>
.card.card-collapse {
	& > .card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > h4 {
			text-transform: capitalize;
		}
		& > i {
			cursor: pointer;
			transition: transform ease-in-out 200ms;
		}
	}
	&.is-openned > .card-header > i {
		transform: rotate(90deg);
	}
}
</style>
