<template>
	<div>
		<b-row v-if="showSuggestChrome">
			<b-col sm="12">
				<b-alert variant="warning" show dismissible>
					{{ FormMSG(4001, 'Use') }} <strong>{{ FormMSG(4002, 'Chrome browser') }}</strong> {{ FormMSG(4003, 'for better use') }}.
				</b-alert>
			</b-col>
		</b-row>
		<b-row v-if="objectIsNotNull(error)">
			<b-col sm="12">
				<b-alert variant="danger" show dismissible v-html="errorMessage" />
			</b-col>
		</b-row>
		<b-row v-if="warning.length > 0">
			<b-col sm="12">
				<b-alert dismissible variant="danger" show v-html="warning" />
			</b-col>
		</b-row>
		<slot />
	</div>
</template>

<script>
import SuggestChromeMixin from '@/mixins/suggestChrome.mixin';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
export default {
	name: 'ErrorComponent',
	mixins: [SuggestChromeMixin, languageMessages, GlobalMixin],
	props: {
		error: {
			type: Object,
			required: true,
			default: () => {}
		},
		warning: {
			type: String,
			default: ''
		}
	},
	computed: {
		errorMessage: function () {
			//  console.log("in errors.vue error errorMessage:",this.error);
			if (this.objectIsNotNull(this.error)) {
				if (this.error.graphQLErrors.length > 0) {
					var status = this.error.graphQLErrors[0].status;
					var message = this.error.graphQLErrors[0].message;
					return status + ' : ' + message;
				} else if (this.error.networkError) {
					return 'network error';
				} else {
					//    console.log("in errors.vue error errorMessage unknown error:",this.error);
					return 'unknown error: ' + JSON.stringify(this.error);
				}
			} else {
				return '';
			}
		}
	},
	watch: {
		error: function (err) {
			if (this.objectIsNotNull(err)) {
				window.scrollTo(0, 0);
				this.createToastForMobile(this.FormMSG(987, 'Error'), this.errorMessage, '', 'danger');
			}
			console.log({ err });
		},
		warning: function (warning) {
			if (warning.length > 0) {
				window.scrollTo(0, 0);
			}
			console.log({ warning });
		}
	},

	methods: {
		objectIsNotNull: function (object) {
			//    console.log("in errors.vue error objectIsNotNull:",Object.keys(object));
			return Object.keys(object).length > 0;
		}
	}
};
</script>
