/**
 * @package   arkdevel/front-auth-service
 * @author    Maxime Bartier <m.bartier@arkdevel.be>
 * @copyright ArkDevel
 */
import cookies from 'vue-cookies';
import { Capacitor } from '@capacitor/core';
import { isNil } from '@/shared/utils';

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 */

/**
 * @return {Boolean}
 */
const isPwa = () => Capacitor.getPlatform() !== 'web';

const StorageService = {
	/**
	 * @param {String} ref
	 * @return {Boolean}
	 */
	isKey(ref) {
		const res = StorageService.get(ref);
		return !isNil(res);
	},

	/**
	 * @param {String} ref
	 * @return {*}
	 */
	get(ref) {
		const _get = (r) => (isPwa() ? localStorage.getItem(r) : cookies.get(r));
		return _get(ref);
	},

	/**
	 * @param {String} ref
	 * @param {*} payload
	 * @return {*}
	 */
	set(ref, payload, duration = null) {
		const _set = (r, p, d) => (isPwa() ? localStorage.setItem(r, p) : cookies.set(r, p, d));
		return _set(ref, payload, duration);
	},

	/**
	 * @param {String} ref
	 * @return {*}
	 */
	remove(ref) {
		const _remove = (r) => (isPwa() ? localStorage.removeItem(r) : cookies.remove(r));
		return _remove(ref);
	}
};

export default StorageService;
