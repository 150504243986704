import canAccessOnboardingMiddleware from './middleware/canAccessOnboardingMiddleware';

export const onboardingRoutes = [
	{
		path: 'onboarding',
		meta: { label: 'Onboarding' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: '',
				meta: {
					label: 'Onboarding',
					middleware: canAccessOnboardingMiddleware
				},
				component: () => import('@/modules/onboarding/views/index.vue'),
				name: 'onboardingList'
			}
		]
	},
	{
		path: 'validate-document',
		meta: { label: 'Validate Documents' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: '',
				meta: { label: 'Validate Documents' },
				component: () => import('@/modules/onboarding/views/ValidateDocuments.vue'),
				name: 'validateDocuments'
			}
		]
	}
];
