import { isNil } from '@/shared/utils';

export default function jwtDataFunction(token) {
	if (!isNil(token) && token.length > 0) {
		let str = token.split('.')[1];
		let strToStringBase64 = Buffer.from(str, 'base64').toString('binary');
		let tok = JSON.parse(strToStringBase64);

		return tok;
	}

	return {};
}
