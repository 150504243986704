// import { USERS_MENU_LIST_EXPENDED } from '@/shared/constants';

const state = {
	navs: []
};

const getters = {
	getNavItems: (state) => state.navs
};

const mutations = {
	newStateOfNavItems(state, payload) {
		if (payload.isUpdate) {
			state.navs.forEach((value, index) => {
				// if (index !== +payload.index && !USERS_MENU_LIST_EXPENDED.includes(value.msgId) && value.open !== false) {
				// 	value.open = false;
				// }
				if (index === +payload.index) {
					value.open = !value.open;
				}
			});
		} else {
			state.navs = payload.navs;
		}
	}
};

const actions = {
	mutateNavItems({ commit }, payload) {
		commit('newStateOfNavItems', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
