<template>
	<div class="cursor-pointer footer-item" :class="`${toggleDetail ? 'collapsed' : 'expanded'}`" @click="handleToggleDetail">
		<span class="mr-1">{{ !toggleDetail ? FormMSG(1, 'Show details') : FormMSG(2, 'Hide details') }}</span>
		<span v-if="!toggleDetail"><chevron-down :size="18" /></span>
		<span v-else><chevron-up :size="18" /></span>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { ChevronDown, ChevronUp } from 'lucide-vue';
import { isNil } from '~utils';

export default {
	name: 'CardFooter',
	mixins: [languageMessages],
	components: {
		ChevronDown,
		ChevronUp
	},
	props: {
		toggleMode: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data() {
		return {
			toggleDetail: true
		};
	},
	methods: {
		handleToggleDetail() {
			// this.toggleDetail = !this.toggleDetail;
			this.$emit('card-list-builder:toggle', !this.toggleDetail);
		}
	},
	watch: {
		toggleMode: {
			handler(toggle) {
				if (!isNil(toggle)) {
					this.toggleDetail = toggle;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
