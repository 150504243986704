import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

const flagItemColumns = `{
    id
    flagId
    value
    name
    short
    factor
	amount
	payCode
	color
	allowanceRate
	bookmark
    category
    digitalSubmission
    disabled
    costCenter
	forScreen
	fieldType
	defaultValue
	jsFormula
}`;

const flagTypeFields = `{
	id
    flagId
    name
    short
    description
    greenFlag
	mandatoryFlag
    type
	customExportName
    projectFlagItem ${flagItemColumns}
}`;

const flagColumns = `{
    id
	taxeFlags ${flagTypeFields}
	customFlags ${flagTypeFields}
	taxeFlags ${flagTypeFields}
	customFieldsFlags ${flagTypeFields}
	customAllowancesFlags ${flagTypeFields}
	customPerDiemFlags ${flagTypeFields}
}`;

const flagEntityColumns = `{
	id
	flagId
	value
	entityId
	type
	factor
}`;

/**
 * add update flag
 * @param {Number} flagId
 * @param {Object} projectFlagInput
 */
export const addUpdateProjectFlag = async (flagId, projectFlagInput) => {
	const mutation = gql`
        mutation ($flagId: ID!, $projectFlagInput: ProjectFlagInput!) {
            AddUpdProjectFlag(
                FlagId: $flagId,
                ProjectFlagInput: $projectFlagInput
            ) ${flagTypeFields}
        }
    `;
	const {
		data: { AddUpdProjectFlag }
	} = await apollo.mutate({
		mutation,
		variables: {
			flagId: +flagId,
			projectFlagInput
		},
		fetchPolicy: 'no-cache'
	});

	return AddUpdProjectFlag;
};

export const deleteProjectFlag = async (flagId) => {
	const MUTATION_DEL_FLAG = gql`
		mutation ($flagId: ID!) {
			DelProjectFlag(FlagId: $flagId)
		}
	`;

	const {
		data: { DelProjectFlag }
	} = await apollo.mutate({
		mutation: MUTATION_DEL_FLAG,
		variables: {
			flagId: +flagId
		}
	});

	return DelProjectFlag;
};

export const addUpdateProjectFlagItem = async (flagItemId, projectFlagItemInput) => {
	const mutation = gql`
        mutation ($flagItemId: ID!, $projectFlagItemInput: ProjectFlagItemInput!) {
            AddUpdProjectFlagItem(
                FlagItemId: $flagItemId,
                ProjectFlagItemInput: $projectFlagItemInput
            ) ${flagItemColumns}
        }
    `;
	const {
		data: { AddUpdProjectFlagItem }
	} = await apollo.mutate({
		mutation,
		variables: {
			flagItemId: +flagItemId,
			projectFlagItemInput
		},
		fetchPolicy: 'no-cache'
	});

	return AddUpdProjectFlagItem;
};

export const deleteProjectFlagItem = async (flagItemId) => {
	const MUTATION_DEL_FLAG_ITEM = gql`
		mutation ($flagItemId: ID!) {
			DelProjectFlagItem(FlagItemId: $flagItemId)
		}
	`;

	const {
		data: { DelProjectFlagItem }
	} = await apollo.mutate({
		mutation: MUTATION_DEL_FLAG_ITEM,
		variables: {
			flagItemId: +flagItemId
		}
	});

	return DelProjectFlagItem;
};

export const getProjectFlags = async (type) => {
	const QUERY_GET_FLAGS = gql`
            query ($type: ID) {
                GetProjectFlags (Type: $type) ${flagColumns}
            }
        `;

	const {
		data: { GetProjectFlags }
	} = await apollo.query({
		query: QUERY_GET_FLAGS,
		variables: {
			type
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectFlags;
};

export const getProjectFlag = async (flagId) => {
	const QUERY_GET_FLAG = gql`
            query ($flagId: ID!) {
                GetProjectFlag (FlagId: $flagId) ${flagTypeFields}
            }
        `;

	const {
		data: { GetProjectFlag }
	} = await apollo.query({
		query: QUERY_GET_FLAG,
		variables: {
			flagId: +flagId
		}
	});

	return GetProjectFlag;
};

export const getProjectFlagItems = async (flagId) => {
	const QUERY_GET_FLAG_ITEMS = gql`
            query ($flagId: ID!) {
                GetProjectFlagItems (FlagId: $flagId) ${flagItemColumns}
            }
        `;

	const {
		data: { GetProjectFlagItems }
	} = await apollo.query({
		query: QUERY_GET_FLAG_ITEMS,
		variables: {
			flagId: +flagId
		}
	});

	return GetProjectFlagItems;
};

export const getProjectFlagItem = async (flagItemId) => {
	const QUERY_GET_FLAG_ITEM = gql`
            query ($flagItemId: ID!) {
                GetProjectFlagItem (FlagItemId: $flagItemId)  ${flagItemColumns}
            }
        `;

	const {
		data: { GetProjectFlagItem }
	} = await apollo.query({
		query: QUERY_GET_FLAG_ITEM,
		variables: {
			flagItemId: +flagItemId
		}
	});

	return GetProjectFlagItem;
};

export const getUsersAndFlagsValue = async (userId, flagsId) => {
	try {
		const query = gql`
			query ($userId: [ID!]!, $flagsId: [ID!]!) {
				GetUsersAndFlagsValue(UserID: $userId, FlagsId: $flagsId) {
					id
					projectFlagItem {
						id
						amount
						flagId
						value
					}
				}
			}
		`;

		const {
			data: { GetUsersAndFlagsValue }
		} = await apollo.query({
			query,
			variables: {
				userId,
				flagsId
			},
			fetchPolicy: 'no-cache'
		});

		return GetUsersAndFlagsValue;
	} catch (e) {
		console.log(e);
	}
};

export const addUpdateProjectFlagEntity = async (entityId, entityType, projectFlagEntityInputs, allRemovedBefore = true) => {
	const mutation = gql`
        mutation ($entityId: ID!, $entityType: ID!, $projectFlagEntityInputs: [ProjectFlagEntityInput]!, $allRemovedBefore: Boolean!) {
            AddUpdProjectFlagEntity(
                EntityId: $entityId,
                EntityType: $entityType,
                ProjectFlagEntityInput: $projectFlagEntityInputs,
				AllRemovedBefore: $allRemovedBefore
            ) ${flagEntityColumns}
        }
    `;
	const {
		data: { AddUpdProjectFlagEntity }
	} = await apollo.mutate({
		mutation,
		variables: {
			entityId: +entityId,
			entityType: +entityType,
			projectFlagEntityInputs,
			allRemovedBefore
		},
		fetchPolicy: 'no-cache'
	});

	return AddUpdProjectFlagEntity;
};
