import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

if (Capacitor.getPlatform() !== 'web') {
	// iOS only
	window.addEventListener('statusTap', function () {
		console.log('statusbar tapped');
	});

	// Display content under transparent status bar (Android only)
	StatusBar.setOverlaysWebView({ overlay: true });
	StatusBar.setBackgroundColor('#000000');

	// StatusBar.setBarStyle('light-content', false);
	// Check if the platform is iOS before calling setBarStyle
	//   if (Capacitor.getPlatform() === 'ios') {
	//     StatusBar.setBarStyle('light-content', false);
	//   }
	/** "StatusBar.setBarStyle" does not work anymore with IOS in capacitor 6. */

	const setStatusBarStyleLight = async () => {
		await StatusBar.setStyle({ style: Style.Light });
	};
	setStatusBarStyleLight();
}
