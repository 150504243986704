/**
 * this.$device for use it
 */
import Vue from 'vue';
import Device from 'mobile-device-detect';

const device = {};

device.install = (Vue) => {
	Vue.prototype.$device = Device;
};

Vue.use(device);
