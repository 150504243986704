import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

const state = () => ({
	documents: []
});

const getters = {
	getAll: (state) => state.documents
};

const mutations = {
	SET_ALL_DOCUMENTS(state, records) {
		state.documents = records;
	}
};

const actions = {
	async getDocuments({ commit }, filter) {
		let variables = {
			filter
		};

		let query = gql`
            query ($filter: DocumentFilterInput) {
                GetDocumentList(Filter: $filter) {
                    ${fields}
                }
            }
        `;

		const {
			data: { GetDocumentList }
		} = await apollo.query({
			query,
			variables,
			fetchPolicy: 'no-cache'
		});

		commit('SET_ALL_DOCUMENTS', GetDocumentList);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};

const fields = `
    id
    type
    name
    fileName
    body
    userPassword
    ownerPassword
    xid
    xidGenerated
    documentParentId
    ext
    useWatermark
    watermarkPictureXid
    watermarkPictureFileName
    watermarkText
    watermarkWithUser
    publishedOn
    publisherId
    toSign
    toEmail
    toSms
    toWhatsApp
    availableOnline
    createdAt
    status
    showSignatureViewerWarning
    senderStatus
    userId
    isOnBoardingDocument
    version
    archived
    documentNextVersionId
    deliveryCurrentStep
    deliveryContractStrDate
    deliveryContractEndDate
    publisher {
        id
        name
        firstName
        fullName
    }
    childDocList {
        id
        type
        name
        fileName
        body
        userPassword
        ownerPassword
        xid
        xidGenerated
        ext
        useWatermark
        watermarkPictureXid
        watermarkPictureFileName
        watermarkText
        watermarkWithUser
        publishedOn
        publisherId
        toSign
        toEmail
        toSms
        toWhatsApp
        availableOnline
        status
        senderStatus
        userId
        publisher {
            id
            name
            firstName
            fullName
        }
        requireManagerApproval
        deliveryContractId
        firstDeliveryId
    }
    docValConfigStep1 {
        id
        createdAt
        updatedAt
        docId
        step
        toSign
        validationLevel
    }
    docValConfigStep2 {
        id
        createdAt
        updatedAt
        docId
        step
        toSign
        validationLevel
    }
    docValConfigStep3 {
        id
        createdAt
        updatedAt
        docId
        step
        toSign
        validationLevel
    }
    requireManagerApproval
    deliveryContractId
    firstDeliveryId
`;
