import axios from 'axios';
import { store as _store } from '@/store';
import $store from '@/store/store';
import router from '@/router';

const defaultOptions = {
	credentials: false,
	proxyHeaders: false
};

/**
 * Fetching setting for axios
 * @param {String} baseURL
 * @param {Object} options
 */
const ApiService = {
	_401interceptor: null,

	/**
	 * @param {String}
	 */
	init(baseURL, options = {}) {
		axios.defaults.baseURL = baseURL.charAt(0) === '/' ? location.origin + baseURL : baseURL;

		axios.defaults.headers.common['Content-Type'] = 'application/vnd.api+json';

		const _O = Object.assign({}, defaultOptions, options);
		axios.options = _O;
	},

	setTokenHeader() {
		const { token } = _store.state;
		if (!token) return;
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	},

	removeHeaders() {
		axios.defaults.headers.common = {};
	},

	/**
	 * @async
	 * @param {!String} resource
	 * @param {?Object} [options={}]
	 * @return {Promise}
	 */
	async $get(resource, options = {}) {
		try {
			const { data } = await axios.get(resource, options);
			return data;
		} catch (e) {
			console.error({ $get: e });
		}
	},

	/**
	 * @async
	 * @param {!String} resource
	 * @param {!Object} payload
	 * @param {?Object} [options={}]
	 * @return {Promise}
	 */
	async $post(resource, payload, options = {}) {
		// return axios.post(resource, payload);
		try {
			const { data } = await axios.post(resource, payload, options);
			return data;
		} catch (e) {
			console.error({ $post: e });
      throw new Error(e);
		}
	},

	async $put(resource, payload) {
		try {
			const { data } = await axios.put(resource, payload);
			return data;
		} catch (e) {
			console.error({ $put: e });
		}
	},

	async $patch(resource, payload) {
		try {
			const { data } = await axios.patch(resource, payload);
			return data;
		} catch (e) {
			console.error({ $patch: e });
		}
	},

	async $delete(resource) {
		try {
			const { data } = await axios.delete(resource);
			return data;
		} catch (e) {
			console.error({ $delete: e });
		}
	},

	/**
	 * Perform a custom Axios request.
	 *
	 * data is an object containing the following properties:
	 *  - method
	 *  - url
	 *  - data ... request payload
	 *  - auth (optional)
	 *    - username
	 *    - password
	 **/
	customRequest(data) {
		return axios(data);
	},

	mount401Interceptor() {
		this._401interceptor = axios.interceptors.response.use(
			(response) => {
				if (response.config.method !== 'get') {
					$store.dispatch('resetError');
				}
				return response;
			},
			(error) => {
				if (error.request.status === 401) {
					// Refresh token has failed. Logout the user
					router.replace({ name: 'login' });
					$store.dispatch('auth/logout');
				} else if (
          error.request.status === 422 ||
          error.request.status === 413
        ) {
					$store.dispatch('throwError', error.response.data);
					return Promise.reject(error.response);
				} else {
					return Promise.reject(error.response);
				}
			}
		);
	},

	unmount401Interceptor() {
		// Eject the interceptor
		axios.interceptors.response.eject(this._401interceptor);
	}
};

export default ApiService;
