import _ from 'lodash';
import { getProjectFlags } from '@/cruds/flag.crud';
import { FLAG_TYPE } from '@/shared/constants';

const state = () => ({
	taxOptions: [],
	taxes: []
});

const getters = {
	taxOptions: (state) => state.taxOptions,
	taxes: (state) => state.taxes
};

const mutations = {
	SET_TAXES(state, taxes) {
		state.taxes = taxes;
	},
	SET_TAX_OPTIONS(state, options) {
		state.taxOptions = options;
	}
};

const actions = {
	async getTaxes({ commit }) {
		try {
			const record = await getProjectFlags();
			let taxes = [];

			for (const tax of record.taxeFlags) {
				if (tax.projectFlagItem.length > 0 && tax.flagId === 0) {
					let i = 0;
					let isValideFlag = i;

					taxes.push({
						..._.omit(tax, ['type'])
					});

					let taxItems = [];
					for (const taxItem of tax.projectFlagItem) {
						if (taxItem.disabled === false) {
							taxItems.push({
								..._.omit(taxItem, ['disabled', 'digitalSubmission'])
							});

							isValideFlag = null;
						}
					}

					if (!_.isNil(isValideFlag)) {
						taxes.splice(isValideFlag, 1);
					} else {
						taxes[i].projectFlagItem = taxItems;
					}
				}
			}

			commit('SET_TAXES', taxes);
		} catch (e) {
			console.log('STORE GET TAXES ERROR', e);
		}
	},
	async getTaxOptions({ commit }) {
		try {
			const record = await getProjectFlags();
			let options = [];

			for (const tax of record.taxeFlags) {
				if (tax.projectFlagItem.length > 0 && tax.flagId === 0) {
					let i = 0;
					let isValideFlag = i;

					options.push({
						value: new Date().getTime(),
						text: tax.name,
						disabled: true,
						item: null
					});

					for (const taxItem of tax.projectFlagItem) {
						if (taxItem.disabled === false) {
							options.push({
								value: taxItem.id,
								text: taxItem.short,
								disabled: false,
								item: _.omit(taxItem, ['disabled', 'digitalSubmission'])
							});

							isValideFlag = null;
						}
					}

					if (!_.isNil(isValideFlag)) {
						options.splice(isValideFlag, 1);
					}

					i++;
				}
			}

			commit('SET_TAX_OPTIONS', options);
		} catch (e) {
			console.log('STORE GET OPTIONS ERROR', e);
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
