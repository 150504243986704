import gql from 'graphql-tag';
import { isNil } from '@/shared/utils';
import { apolloClient as apollo } from '@/vue-apollo';

const state = () => ({
	focusAddress: null
});

const getters = {
	focusAddress: (state) => state.focusAddress
};

const mutations = {
	SET_FOCUS_ADDRESS(state, address) {
		state.focusAddress = address;
	}
};

const actions = {
	async setNewAddress({ commit }, address) {
		if (isNil(address)) return;
		console.log({ setNewAddress: address });
		try {
			const mutation = gql`
				mutation {
					NewAddress {
						id
					}
				}
			`;
			const {
				data: { NewAddress }
			} = await apollo.mutate({
				mutation
			});
			commit('SET_FOCUS_ADDRESS', NewAddress);
		} catch (e) {
			console.log({ e });
		}
	},

	async getAddress({ commit }, id) {
		console.log('getAddress');
		if (isNil(id)) return;
		try {
			const query = gql`
				query ($AddressId: ID!) {
					GetAddress(AddressId: $AddressId) {
						street
						number
						box
						zip
						state
						city
						country
						other
						lat
						lng
					}
				}
			`;

			const {
				data: { GetAddress }
			} = await apollo.query({
				query,
				variables: {
					AddressId: parseInt(id)
				},
				fetchPolicy: 'network-only'
			});
			commit('SET_FOCUS_ADDRESS', {
				id: parseInt(id),
				...GetAddress
			});
		} catch (e) {
			console.log({ e });
		}
	},

	async updateAddress({ commit }, { id, address }) {
		try {
			const mutation = gql`
				mutation ($AddressId: ID!, $UpdatedAddress: AddressInput!) {
					UpdAddress(AddressId: $AddressId, UpdatedAddress: $UpdatedAddress) {
						street
						number
						box
						zip
						state
						city
						country
						countryCode
						countryKey
						other
						lat
						lng
					}
				}
			`;
			const {
				data: { UpdAddress }
			} = await apollo.mutate({
				mutation,
				variables: {
					AddressId: parseInt(id),
					UpdatedAddress: address
				}
			});
			commit('SET_FOCUS_ADDRESS', UpdAddress);
		} catch (e) {
			console.log({ e });
		}
	},

	destroyFocusAddress({ commit }) {
		commit('SET_FOCUS_ADDRESS', null);
	},

	async deleteAddress({ commit }, id) {
		if (isNil(id)) return;
		try {
			const mutation = gql`
				mutation ($AddressId: ID!) {
					DelAddress(AddressId: $AddressId)
				}
			`;
			await apollo.mutate({
				mutation,
				variables: {
					AddressId: parseInt(id)
				}
			});
			commit('SET_FOCUS_ADDRESS', null);
		} catch (e) {
			console.log({ e });
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
