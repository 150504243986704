import Vue from 'vue';
import moment from 'moment';
// import moment from 'moment-timezone'
import { isNil } from '~utils';
import { rendCurrency, rendKgCo2 } from '~helpers';

const today = new Date();

const filters = {
	formatCurrency(value) {
		return rendCurrency(parseFloat(value));
	},
	formatRendKg(value) {
		return rendKgCo2(parseFloat(value));
	},
	/**
	 * @param {String} value
	 * @param {String} format
	 * @return {String}
	 */
	formatDate(value, format = 'DD-MM-YYYY', useUtcDate = false) {
		if (isNil(value)) return '...';
		if (!useUtcDate) {
			return moment(new Date(value)).format(format);
		}
		return moment(new Date(value)).utc().format(format);
	},

	formatDayMonth(value, format = 'DD-MM') {
		if (isNil(value)) return '...';
		return moment(new Date(value)).format(format);
	},

	/**
	 * @param {String} value
	 * @return {String}
	 */
	formatDateTime(value) {
		if (isNil(value)) return '...';
		return moment(new Date(value)).format('DD-MM-YYYY HH:mm:ss');
	},

	/**
	 * @param {String} value
	 * @return {String}
	 */
	formatTime(value) {
		if (isNil(value)) return '...';
		return moment(new Date(value)).format('HH:mm:ss');
	},

	/**
	 * @param {String} date
	 * @return {String} - // June 16, 2020
	 */
	basicDate(date) {
		if (isNil(date)) return '...';
		return moment(new Date(date)).format('LL');
	},

	/**
	 * @param {String} start
	 * @param {String} end
	 * @return {String}
	 */
	datesDiff(start, end) {
		if (isNil(start) || isNil(end)) return;

		const m1 = moment(new Date(start), 'DD-MM-YYYY HH:mm');
		const m2 = moment(new Date(end), 'DD-MM-YYYY HH:mm');
		const m3 = m2.diff(m1, 'minutes');
		// const m4 = m2.diff(m1, 'h')

		const numDays = Math.floor(m3 / 1440);
		const numHours = Math.floor((m3 % 1440) / 60);
		const numMinutes = Math.floor((m3 % 1440) % 60);

		const arr = [
			{ val: numDays, text: `${numDays} day(s)` },
			{ val: numHours, text: `${numHours}h` }
			// { val: numMinutes, text: `${numMinutes}m` }
		];
		const res = arr.map((d) => {
			if (d.val > 0) return d.text;
		});

		return res.join(' ') + ` ${numMinutes}m`;
	},

	/**
	 * @param {String} value
	 * @return {String} - // June 16, 2020 10:38 AM
	 */
	fromNowToHuman(value) {
		if (isNil(value)) return '...';
		return moment(new Date(value)).utc().format('lll');
	},

	/**
	 * @param {String} date
	 * @return {String} - // 9 days ago
	 */
	fromNow(date) {
		if (isNil(date)) return '...';
		const _d = isValidDate(date) ? date.replace(/-/g, '/') : date;
		// return _d
		// const d = new Date(_d)
		return moment(new Date(_d)).format('DD-MMM-YYYY').fromNow();
	},

	/**
	 * @param {String} value
	 * @param {Number} limite
	 * @return {String}
	 */
	lastSeen(date, limite = 14) {
		if (isNil(date)) return '...';
		const _t = moment(today);
		const twoWeeksAgo = _t.clone().subtract(limite, 'days').startOf('day').format('YYYYMMDD') * 1;
		const lastSeen = moment(new Date(date)).format('YYYYMMDD') * 1;
		const renderedDate = filters.fromNowToHuman(date);
		const formated = `En ligne ${renderedDate}`;
		const result = lastSeen > twoWeeksAgo ? formated : null;
		return result;
	},

	/**
	 * @param {String} date
	 * @return {String}
	 */
	frDate(date) {
		if (isNil(date)) return '...';
		return moment(new Date(date)).format('DD/MM/YYYY');
	},

	/**
	 * @param {String} date
	 * @return {String}
	 */
	secToHuman(seconds) {
		if (isNil(date)) return '...';
		return moment(new Date(seconds)).format('h[h]m[m]');
	},

	/**
	 * check if date is more than x days from today
	 * @param {String} date
	 * @param {Number} limite - @default 14
	 */
	moreThanDays(date, limite = 14) {
		if (isNil(date)) return '...';
		const _t = moment(today);
		const twoWeeksAgo = _t.clone().subtract(limite, 'days').startOf('day').format('YYYYMMDD') * 1;
		const lastSeen = moment(date).format('YYYYMMDD') * 1;
		return lastSeen > twoWeeksAgo;
	},

	/**
	 * @param {String} start
	 * @param {String} end
	 * @return {String}
	 */
	diffInMins(start, end) {
		const _f = 'DD-MM-YYYY HH:mm';
		const _s = moment(new Date(start), _f);
		const _e = moment(new Date(end), _f);
		return _e.diff(_s, 'minutes');
	}
};

/**
 * Injecting all filters inside the propject
 */
Object.keys(filters).forEach((key) => {
	Vue.filter(key, filters[key]);
});
