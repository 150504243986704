import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '@/shared/utils';
import { PROJECT_CARBON_FIELD, EXPENSE_GQL } from '@/shared/constants';

export const sendSelectedExpensesForValidation = async (expenseTitle, expenseData) => {
	try {
		const mutation = gql`
			mutation ($expenseTitle: String, $expenseData: [SendExpenseItemInput]!) {
				SendExpenseSlice(ExpenseTitle: $expenseTitle, ExpenseData: $expenseData)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				expenseTitle,
				expenseData
			},
			fetchPolicy: 'no-cache'
		});

		return data.SendExpenseSlice;
	} catch (e) {
		console.log({ e });
	}
};

export const ChangeStatusExpensesProcess = async (expenseArrayId, valueOfProcessToChange) => {
	try {
		const mutation = gql`
			mutation ($ExpenseArrayId: [ID!]!, $ValueOfProcessToChange: Boolean!) {
				ChangeStatusExpensesProcess(ExpenseArrayId: $ExpenseArrayId, ValueOfProcessToChange: $ValueOfProcessToChange) {
					id
					date
					type
					description
					kgCoTwo
					amount
					amountVat
					amountTotal
					comment
					validated
					departmentName
					functionName
					encoderId
					encoder {
						id
						name
						firstName
						fullName
					}
					user {
						id
						name
						firstName
					}
					expenseItem {
						id
						category
						costCenter
						categoryName
						missingProjectFlag {
							id
							name
							short
							mandatoryFlag
						}
					}
				}
			}
		`;

		let variables = {
			ExpenseArrayId: expenseArrayId,
			ValueOfProcessToChange: valueOfProcessToChange
		};

		const {
			data: { ChangeStatusExpensesProcess }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return ChangeStatusExpensesProcess;
	} catch (e) {
		console.error({ e });
	}
};

export const sendAllExpensesForValidation = async (ExpenseTitle) => {
	try {
		const mutation = gql`
			mutation ($ExpenseTitle: String) {
				SendAllExpenses(ExpenseTitle: $ExpenseTitle)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				ExpenseTitle: ExpenseTitle
			},
			fetchPolicy: 'no-cache'
		});

		return data.SendAllExpenses;
	} catch (e) {
		console.log({ e });
	}
};

export const getExpenseItems = async (expenseId = -1) => {
	const query = gql`
		query ($expenseId: ID!) {
			GetExpenseItemList(ExpenseId: $expenseId) {
				${columnExpenseItem}
			}
		}
	`;

	const {
		data: { GetExpenseItemList }
	} = await apollo.query({
		query,
		variables: {
			expenseId
		},
		fetchPolicy: 'no-cache'
	});

	return GetExpenseItemList;
};

export const getExpenseItemsForDepartmentAndCategoryList = async (DepartmentNumber, CategoryNumber) => {
	const query = gql`
		query ($DepartmentNumber: ID!, $CategoryNumber: ID!) {
			GetExpenseItemsForDepartmentAndCategoryList(DepartmentNumber: $DepartmentNumber, CategoryNumber: $CategoryNumber) {
				${columnExpenseItem}\n${EXPENSE_GQL}
			}
		}
	`;

	const {
		data: { GetExpenseItemsForDepartmentAndCategoryList }
	} = await apollo.query({
		query,
		variables: {
			DepartmentNumber,
			CategoryNumber
		},
		fetchPolicy: 'no-cache'
	});

	return GetExpenseItemsForDepartmentAndCategoryList;
};

export const validateExpense = async (expenseId, comment) => {
	const mutation = gql`
		mutation ($expenseId: ID!, $comment: String) {
			ValidateExpense(ExpenseId: $expenseId, Comment: $comment) {
				id
				amountTotal
				comment
				validated
			}
		}
	`;

	const {
		data: { ValidateExpense }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseId,
			comment
		},
		fetchPolicy: 'no-cache'
	});

	return ValidateExpense;
};

export const validateExpenseItem = async (expenseItemId) => {
	const mutation = gql`
		mutation ($expenseItemId: ID!) {
			ValidateExpenseItem(ExpenseItemId: $expenseItemId) {
				id
				allItemsTotal
			}
		}
	`;

	const {
		data: { ValidateExpenseItem }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseItemId
		},
		fetchPolicy: 'no-cache'
	});

	return ValidateExpenseItem;
};

export const undoRejectExpenseItem = async (expenseItemId) => {
	const mutation = gql`
		mutation ($expenseItemId: ID!) {
			UndoRejectExpenseItem(ExpenseItemId: $expenseItemId) {
				id
				allItemsTotal
			}
		}
	`;

	const {
		data: { UndoRejectExpenseItem }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseItemId
		},
		fetchPolicy: 'no-cache'
	});

	return UndoRejectExpenseItem;
};

export const rejectExpense = async (expenseId, comment) => {
	const mutation = gql`
		mutation ($expenseId: ID!, $comment: String) {
			RejectExpense(ExpenseId: $expenseId, Comment: $comment) {
				id
				amountTotal
				comment
				validated
			}
		}
	`;

	const {
		data: { RejectExpense }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseId,
			comment
		},
		fetchPolicy: 'no-cache'
	});

	return RejectExpense;
};

export const rejectExpenseItem = async (expenseItemId, comment) => {
	const mutation = gql`
		mutation ($expenseItemId: ID!, $comment: String) {
			RejectExpenseItem(ExpenseItemId: $expenseItemId, Comment: $comment) {
				${columnExpenseItem}
			}
		}
	`;

	const {
		data: { RejectExpenseItem }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseItemId,
			comment
		},
		fetchPolicy: 'no-cache'
	});

	return RejectExpenseItem;
};

export const getExpenseToValidate = async () => {
	const query = gql`
		query {
			GetExpenseToValidate {
				id
				type
				description
				date
				amount
				amountVat
				amountTotal
				comment
				validated
				kgCoTwo
				department
				departmentName
				functionName
				allExpenseItemIsInContract
				user {
					id
					name
					firstName
					email
				}
				encoderId
				encoder {
					id
					name
					fullName
					firstName
				}
				function
				functionName
			}
		}
	`;

	const {
		data: { GetExpenseToValidate }
	} = await apollo.query({
		query,
		fetchPolicy: 'no-cache'
	});

	return GetExpenseToValidate;
};

export const getExpenseItem = async (expenseItemId = 0) => {
	const query = gql`
			query ($expenseItemId: ID!) {
				GetExpenseItem(ExpenseItemId: $expenseItemId) {
					${columnExpenseItem}
				}
			}
    `;

	const {
		data: { GetExpenseItem }
	} = await apollo.query({
		query,
		variables: {
			expenseItemId: +expenseItemId
		},
		fetchPolicy: 'no-cache'
	});

	return GetExpenseItem;
};

/**
 * Sends a GraphQL mutation to get expense items from an image or PDF document.
 *
 * @param {Array<String>|String} payload - Document identifier(s), without file extension. If multiple, provide an array.
 * @param {Object} [options={}]
 *
 * @returns {Promise<Array<{amountTotal: number, amountVat: number, description: string, date: string}>|null>}
 * Returns a promise that resolves to an array of expense items, or null if an error occurs.
 * @returns {Promise<Array<{amountTotal: number, amountVat: number, description: string, date: string}>|null>}
 * Returns a promise that resolves to an array of expense items, or null if an error occurs.
 * Each expense item is an object with properties field1, field2, and field3.
 *
 * @throws {ApolloError} If the GraphQL mutation fails, this function will log the error and rethrow it.
 *
 * @async
 */
export const expenseItemsFromImageOrPdf = async (payload, options = {}) => {
	/**
	 * @deftype {?Boolean} [OptionsState=false]
	 *
	 * @type {OptionsState} multipleExpenseItem
	 * @type {OptionsState} splitTVA
	 */
	const multipleExpenseItem = options.multipleExpenseItem || false;
	const splitTVA = options.splitTVA || false;
	const userId = options.userId || null;

	// try {
	const mutation = gql`
		mutation ($DocXid: [String!]!, $MultipleExpenseItem: Boolean!, $SplitTVA: Boolean) {
			ExpenseItemsFromImageOrPdf(DocXid: $DocXid, MultipleExpenseItem: $MultipleExpenseItem, SplitTVA: $SplitTVA) {
				id
				amountTotal
				amountVat
				description
				supplierId
				supplierName
				department
				category
				paiementType
				paiementTypeName
				date
			}
		}
	`;
	const { data } = await apollo.mutate({
		mutation,
		variables: {
			DocXid: payload,
			MultipleExpenseItem: multipleExpenseItem,
			SplitTVA: splitTVA
			// UserId: userId,
		}
	});

	return data.ExpenseItemsFromImageOrPdf;
	// } catch (e) {
	// 	console.error({ e });
	// }
};

/**
 * @param {Object} payload
 */
export const generateExpenseRef = async (payload) => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($AddedExpenseItem: ExpenseItemInput!) {
					NewExpenseItem(AddedExpenseItem: $AddedExpenseItem) {
						id
					}
				}
			`,
			variables: { AddedExpenseItem: payload },
			fetchPolicy: 'no-cache'
		});
		return data.NewExpenseItem;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {!String|Number} expenseId
 */
export const deleteExpense = async (expenseId) => {
	if (isNil(expenseId)) return;

	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($ExpenseItemId: ID!) {
					DelExpenseItem(ExpenseItemId: $ExpenseItemId) {
						id
					}
				}
			`,
			variables: { ExpenseItemId: parseInt(expenseId) },
			fetchPolicy: 'no-cache'
		});

		return data;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {!String|Number} originalExpenseItemId
 * @param {!Array} splitDetails
 */
export const splitExpenseItem = async ({ originalExpenseItemId, splitDetails }) => {
	const {
		data: { SplitExpenseItem }
	} = await apollo.mutate({
		mutation: gql`
			mutation ($originalExpenseItemId: ID!, $splitDetails: [ExpenseItemInput!]!) {
				SplitExpenseItem(OriginalExpenseItemId: $originalExpenseItemId, SplitDetails: $splitDetails) {
					${columnExpenseItem}
				}
			}
		`,
		variables: {
			originalExpenseItemId,
			splitDetails
		},
		fetchPolicy: 'no-cache'
	});

	return SplitExpenseItem;
};

/**
 * Service to check if expenses image passing in the ocr is already duplicated.
 * @param {Number} entityId
 * @param {Number} entityType
 */
export const checkIfCarbonRowDuplicate = async (entityId, entityType) => {
	const query = gql`
		query ($entityId: Int!, $entityType: Int!) {
			CheckIfCarbonRowDuplicate(EntityId: $entityId, EntityType: $entityType)
		}
	`;

	const {
		data: { CheckIfCarbonRowDuplicate }
	} = await apollo.query({
		query,
		variables: {
			entityId: +entityId,
			entityType: +entityType
		},
		fetchPolicy: 'no-cache'
	});

	return CheckIfCarbonRowDuplicate;
};

export const expenseDematerializationReport = async (expenseId, sendMail = false) => {
	const query = gql`
		query ($expenseId: ID!, $sendMail: Boolean) {
			ExpenseDematerializationReport(ExpenseId: $expenseId, SendMail: $sendMail)
		}
	`;

	const {
		data: { ExpenseDematerializationReport }
	} = await apollo.query({
		query,
		variables: {
			expenseId: +expenseId,
			sendMail
		},
		fetchPolicy: 'no-cache'
	});

	return ExpenseDematerializationReport;
};

export const cleanWasteExpenseItem = async () => {
	const mutation = gql`
		mutation {
			RemoveUserEmptyExpenseItem
		}
	`;
	const {
		data: { RemoveUserEmptyExpenseItem }
	} = await apollo.mutate({
		mutation,
		fetchPolicy: 'no-cache'
	});

	return RemoveUserEmptyExpenseItem;
};

const columnExpenseItem = `
	expenseId
	createdAt
	updatedAt
	costCenter
	category
	paiementType
	supplierName
	supplierId
	type
	department
	departmentName
	description
	subCategory
	date
	id
	images
	amount
	amountTotal
	amountVat
	comment
	km
	kgCoTwo
	fromLocation
	coTwoCategory
	toLocation
	validated
	categoryName
	paiementTypeName
	isInContract
	subType
	additionalSubType
	roundTrip
	kmRate
	user {
		id
		name
		firstName
		departmentName
		functionName
	}
	encoder {
		id
		name
		firstName
		departmentName
		functionName
	}
	projectFlagItems {
		id
		taxeFlags {
			id
			flagId
			value
			name
			short
			factor
			amount
			payCode
			color
			allowanceRate
			bookmark
			category
			digitalSubmission
			disabled
			costCenter
			forScreen
			fieldType
			defaultValue
			jsFormula
		}
		customFlags {
			id
			flagId
			value
			name
			short
			factor
			amount
			payCode
			color
			allowanceRate
			bookmark
			category
			digitalSubmission
			disabled
			costCenter
			forScreen
			fieldType
			defaultValue
			jsFormula
		}
	}
	supplier {
	  name
	}
	carbon {
	  ${PROJECT_CARBON_FIELD}
	}
`;
