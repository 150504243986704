const useWorktype = {
	hourly: 0,
	daily: 1,
	weekly: 2,
	monthly: 3,
	yearly: 4
};
const useValueType = {
	hourly: 24,
	daily: 24,
	weekly: 100,
	monthly: 13,
	yearly: 2
};
const useRangeHour = {};
for (let key in useWorktype) {
	useRangeHour[useWorktype[key]] = useValueType[key];
}
const useRangeMinute = {};
for (let key in useWorktype) {
	useRangeMinute[useWorktype[key]] = 60;
}
const ACCESS_LEVEL = {
	isAdmin: 'isAdmin',
	isProd: 'isProd',
	isVip: 'isVip',
	canAssign: 'canAssign',
	canAccessUsers: 'canAccessUsers',
	canAccessBudget: 'canAccessBudget',
	canAccessMyBudget: 'canAccessMyBudget',
	canAccessGreenReporting: 'canAccessGreenReporting',
	canAccessConfig: 'canAccessConfig',
	canAccessOnboarding: 'canAccessOnboarding',
	canAccessCrewList: 'canAccessCrewList',
	canValidateI9: 'canValidateI9',
	gateKeeper: 'gateKeeper',
	productionAccountant: 'productionAccountant',
	upm: 'upm',
	lineProducer: 'lineProducer',
	studio: 'studio',
	canEditExpense: 'canEditExpense',
	canLockBudget: 'canLockBudget',
	canModifyEstimatedFinalCost: 'canModifyEstimatedFinalCost',
	canEditAndAddCode: 'canEditAndAddCode',
	canEditPo: 'canEditPo',
	canDeleteAfterValidation: 'canDeleteAfterValidation',
	screenAccess: 'screenAccess',
	isRole: 'isRole',
	delValidationRole: 'delValidationRole',
	addUpdValidationRole: 'addUpdValidationRole'
};
const DEPARTEMENT_VALIDATION = {
	isAdd: 'isAdd',
	isUpdate: 'isUpdate'
};
const ENTRY_FOR_CREW = {
	ADD_EFC: 'ADD_EFC',
	UPDATE_EFC: 'UPDATE_EFC'
};
const FILTER_STRING_TIME_SHEET = {
	groupByPerson: 'groupByPerson',
	groupByDate: 'groupByDate',
	groupByDateOnly: 'groupByDateOnly',
	groupByStatus: 'groupByStatus',
	planningView: 'planningView'
};
const ACTION_EFC_TIMESHEET = {
	addData: 'addData',
	updateData: 'updateData',
	deleteData: 'deleteData'
};
const NAME_CARDS_TIMESHEET_EFC = {
	timecards_One: 'timecards',
	timesheets_Two: 'timesheets'
};

const setSubType = (payload) => {
	if (payload.id === 1) {
		return 'receipt';
	}
	if (payload.id === 2) {
		return 'invoice';
	}
};
export {
	useWorktype,
	useValueType,
	useRangeHour,
	useRangeMinute,
	ACCESS_LEVEL,
	DEPARTEMENT_VALIDATION,
	ENTRY_FOR_CREW,
	FILTER_STRING_TIME_SHEET,
	NAME_CARDS_TIMESHEET_EFC,
	ACTION_EFC_TIMESHEET,
	setSubType
};
