export const transportRoutes = [
	{
		path: 'mytransport',
		meta: { label: 'Transports' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: '',
				component: () => import('@/modules/transports/views/my/index.vue'),
				name: 'My Transports'
			},
			{
				path: ':id',
				meta: { label: 'My transport details Details' },
				name: 'My transport details',
				component: () => import('@/modules/transports/views/my/_id.vue')
			},
			{
				path: 'mobile/add',
				meta: { label: 'Add Transport' },
				name: 'add_transport',
				component: () => import('@/modules/transports/views/mobiles/my/TransportMobile.vue')
			},
			{
				path: 'mobile/update/:id',
				meta: { label: 'Update Transport' },
				name: 'update_transport',
				component: () => import('@/modules/transports/views/mobiles/my/TransportMobile.vue')
			}
		]
	},
	{
		path: 'TransportsList',
		meta: { label: 'Transports' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: '',
				component: () => import('@/modules/transports/views/index.vue'),
				name: 'Transports list'
			},
			{
				path: ':id',
				component: () => import('@/modules/transports/views/_id.vue'),
				name: 'Transport'
			},
			{
				path: 'mobile/add',
				meta: { label: 'Add Transport' },
				name: 'add_transport_production',
				component: () => import('@/modules/transports/views/mobiles/my/TransportMobile.vue')
			},
			{
				path: 'mobile/update/:id',
				meta: { label: 'Update Transport' },
				name: 'update_transport_production',
				component: () => import('@/modules/transports/views/mobiles/my/TransportMobile.vue')
			}
		]
	}
];
