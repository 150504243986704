<template>
	<router-view />
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

export default {
	name: 'App',
	data() {
		return {
			isKeyboardVisible: false
		};
	},
	create() {
		this.initStatusBarPwa();
		this.initKeyboardListener();
	},
	methods: {
		initStatusBarPwa() {
			if (!this.$isPwa()) return;
			StatusBar.setStyle({ style: Style.Light });
			// StatusBar.setBarStyle('light-content', true);
			// Check if the platform is iOS before calling setBarStyle
			// if (Capacitor.getPlatform() === 'ios') {
			// 	StatusBar.setBarStyle('light-content', true);
			// }
			/** "StatusBar.setBarStyle" does not work anymore with IOS in capacitor 6. */

			StatusBar.setBackgroundColor('#000000');
		},
		// initKeyboardListener() {
		// 	window.addEventListener('keyboardWillShow', () => {
		// 		this.isKeyboardVisible = true;
		// 	});
		//
		// 	window.addEventListener('keyboardWillHide', () => {
		// 		this.isKeyboardVisible = false;
		// 	});
		//
		// 	document.addEventListener('focusin', (event) => {
		// 		if (this.isKeyboardVisible) {
		// 			event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
		// 		}
		// 	});
		// }
		initKeyboardListener() {
			if (!this.$isPwa()) return;
			if (this.$isIOSDevice()) return;

			window.addEventListener('resize', () => {
				const vh = window.innerHeight * 0.01;
				const previousVh = window.getComputedStyle(document.documentElement).getPropertyValue('--vh') || 0;

				if (Math.abs(vh - previousVh) > 5) {
					this.isKeyboardVisible = vh < previousVh;
					window.getComputedStyle(document.documentElement).setProperty('--vh', `${vh}px`);
				}
			});

			document.addEventListener('focusin', (event) => {
				if (!this.isKeyboardVisible) return;
				event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
			});

			document.addEventListener('DOMContentLoaded', function () {
				document
					.querySelector('.vs__open-indicator path')
					.setAttribute('d', 'M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z');
			});
		}
	}
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.min.css';
/* Import Font Awesome Icons Set */
$fa-font-path: 'font-awesome/fonts/';
@import 'font-awesome/scss/font-awesome.scss';
/* Import Simple Line Icons Set */
$simple-line-font-path: 'simple-line-icons/fonts/';
@import 'simple-line-icons/scss/simple-line-icons.scss';
//  @import '~@coreui/icons/css/coreui-icons.css';
/* Import Flag Icons Set */
@import 'flag-icon-css/css/flag-icon.min.css';
/* Import Bootstrap Vue Styles */
// @import '~bootstrap-vue/dist/bootstrap-vue.css';
// Import Main styles for this application
@import 'assets/scss/style';

pwa-camera-modal-instance,
pwa-action-sheet {
	z-index: 999999999999999999 !important;
}
</style>
