const state = () => ({
	currentXidToDelete: null
});

const getters = {
	getCurrentXidToDelete: (state) => state.currentXidToDelete
};

const mutations = {
	setCurrentXidToDelete(state, xid) {
		state.currentXidToDelete = xid;
	}
};

const actions = {};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
