import canAccessConfigMiddleware from '@/middleware/canAccessConfigMiddleware';

export const documentPackageRoutes = [
	{
		path: 'document-package',
		meta: { label: 'Document package', middleware: canAccessConfigMiddleware },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: '',
				component: () => import('@/modules/document-package/views/index.vue'),
				meta: { label: 'Document package' },
				name: 'documentPackageList'
			},
			{
				path: ':id',
				component: () => import('@/modules/document-package/views/_details.vue'),
				meta: { label: 'Document package details' },
				name: 'documentPackageDetails'
			}
		]
	}
];
